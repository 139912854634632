import { Box, Typography } from '@mui/material'
import React from 'react'
import packageJson from '../../../../package.json'

function VersionNumber() {
  return (
    <Box
      sx={{
        borderRadius: 1,
        position: 'absolute',
        bottom: (theme) => theme.spacing(0.5),
        right: (theme) => theme.spacing(2.5),
        backgroundColor: 'common.white',
        color: 'common.black',
        px: 1,
        height: (theme) => theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="caption"
        sx={{ fontWeight: 'bold' }}
      >{`v${packageJson.version}`}</Typography>
    </Box>
  )
}

export default VersionNumber
