import { yupResolver } from '@hookform/resolvers/yup'
import { AVAILABILITES } from 'domains/of/enums'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useAvailabilityForm() {
  const { t } = useTranslation()
  const defaultValues = {
    availability: {
      ...AVAILABILITES.READY,
      label: t(AVAILABILITES.READY.label),
    },
  }

  const validationSchema = Yup.object().shape({
    availability: Yup.object().required(t('required-field')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useAvailabilityForm
