export const HOME_PATH = '/'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const RESET_PASSWORD_PATH = '/reset-password/:key'
export const FORGOT_PASSWORD_PATH = '/forgot-password'
export const FORGOT_PASSWORD_SUCCESS_PATH = '/forgot-password/success'
export const TRAINING_ORGANIZATION_PATH = '/training-organization/:id'

export const ADMIN_PATH = '/admin'
export const EMAIL_MANAGEMENT_PATH = '/admin/email-management'
export const EMAIL_MANAGEMENT_TEMPLATES_PATH =
  '/admin/email-management/templates'
export const EMAIL_MANAGEMENT_TEMPLATES_CREATE_PATH =
  '/admin/email-management/templates/create'
export const EMAIL_MANAGEMENT_TEMPLATES_EDIT_PATH =
  '/admin/email-management/templates/:id/edit'
export const EMAIL_MANAGEMENT_LAYOUT_PATH = '/admin/email-management/layout'
export const EMAIL_MANAGEMENT_FUSION_FIELDS_PATH =
  '/admin/email-management/fusion-fields'
export const EMAIL_MANAGEMENT_FUSION_FIELDS_CREATE_PATH =
  '/admin/email-management/fusion-fields/create'
export const EMAIL_MANAGEMENT_TEST_PATH = '/admin/email-management/test'
export const INTERNATIONALIZATION_PATH = '/admin/internationalization'
export const USERS_PATH = '/admin/users'
export const USERS_CREATE_PATH = '/admin/users/create'
export const USERS_EDIT_PATH = '/admin/users/:id/edit'
export const APP_PARAMS_PATH = '/admin/app-params'
export const APP_PARAMS_EDIT_PATH = '/admin/app-params/:id/edit'
export const OFS_PATH = '/admin/ofs'
export const OFS_CREATE_PATH = '/admin/ofs/create'
export const OFS_EDIT_PATH = '/admin/ofs/:id/edit'
export const APPLICATIONS_PATH = '/admin/applications'
export const APPLICATIONS_CREATE_PATH = '/admin/applications/create'
export const APPLICATIONS_EDIT_PATH = '/admin/applications/:id/edit'
export const FILES_PATH = '/admin/files'
export const FILES_CREATE_PATH = '/admin/files/create'
export const FILES_EDIT_PATH = '/admin/files/:id/edit'
export const PUBLIC_TYPES_PATH = '/admin/public-types'
export const PUBLIC_TYPES_CREATE_PATH = '/admin/public-types/create'
export const PUBLIC_TYPES_EDIT_PATH = '/admin/public-types/:id/edit'
export const AUTHORIZED_TOKENS_PATH = '/admin/authorized-tokens'
export const AUTHORIZED_TOKENS_CREATE_PATH = '/admin/authorized-tokens/create'
export const AUTHORIZED_TOKENS_EDIT_PATH = '/admin/authorized-tokens/:id/edit'
export const CATEGORIES_PATH = '/admin/categories'
export const CATEGORIES_CREATE_PATH = '/admin/categories/create'
export const CATEGORIES_EDIT_PATH = '/admin/categories/:id/edit'
