import { Box, Button, Paper, Typography } from '@mui/material'
import { ReactComponent as LoginImage } from 'assets/images/login.svg'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useResetPasswordMutation } from 'domains/users/mutations'
import { useUserByPasswordKeyQuery } from 'domains/users/queries'
import { LOGIN_PATH } from 'enums/paths'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import useLoginForm from './form'

function ResetPasswordForm() {
  const { t } = useTranslation()
  const form = useLoginForm()
  const params = useParams()
  const { key } = params
  const navigate = useNavigate()
  const resetPassword = useResetPasswordMutation()
  const { data: user } = useUserByPasswordKeyQuery({ key })

  const onSubmit = async (values) => {
    const data = { key, ...values }
    resetPassword.mutateAsync(
      {
        data,
      },
      {
        onSuccess: () => {
          navigate(LOGIN_PATH, { replace: true })
        },
      },
    )
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Paper
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 4,
          maxWidth: (theme) => theme.spacing(50),
        }}
      >
        <Box
          sx={{
            '& svg': {
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
            },
            textAlign: 'center',
          }}
        >
          <LoginImage />
        </Box>
        {user && (
          <Typography variant="body1" sx={{ mb: 1, textAlign: 'center' }}>
            {t('change-password-instructions', {
              firstname: user?.firstname,
              lastname: user?.lastname,
            })}
          </Typography>
        )}
        <ControlledTextField
          name="password"
          type="password"
          label={t('password')}
        />
        <ControlledTextField
          name="passwordCopy"
          type="password"
          autoComplete="new-password"
          label={t('confirm-password')}
        />
        <Button color="primary" type="submit">
          {t('change-password')}
        </Button>
      </Paper>
    </FormProvider>
  )
}

export default ResetPasswordForm
