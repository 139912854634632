import { Box } from '@mui/material'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdateFileMutation } from 'domains/files/mutations'
import { useFileQuery } from 'domains/files/queries'
import { FILE, FILES } from 'domains/files/templates'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreateFileForm from '../../form'
import FilesForm from '../FileForm'

function EditFileForm({ id, handleClose }) {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const { data: file } = useFileQuery({ id })
  const form = useCreateFileForm()

  const updateFile = useUpdateFileMutation()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  useEffect(
    function loadFileData() {
      if (!isEmpty(file)) {
        if (file.url)
          form.reset({
            ...file,
            file: { name: file.url, url: file.url },
          })
        else form.reset(file)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file],
  )

  const onSubmit = async (data) => {
    await updateFile.mutateAsync({
      id,
      data: {
        id,
        name: data.name,
        key: data.key,
        url: data.file?.url ? data.file.url : data.url,
        applications: data.applications,
        oFs: data.oFs,
      },
    })
    popSnackbar(t('file-edit-success'), SUCCESS)
    setIsDirty(false)
    queryClient.invalidateQueries(FILE)
    queryClient.invalidateQueries(FILES)
    handleClose()
  }

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <FilesForm onSubmit={form.handleSubmit(onSubmit)} />
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default EditFileForm
