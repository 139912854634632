import { Add, FilterList, GetApp } from '@mui/icons-material'
import { Box, Button, Menu } from '@mui/material'
import LinkButton from 'components/atoms/LinkButton'
import LoadingScreen from 'components/atoms/LoadingScreen'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { useExportExcel } from 'domains/of/mutations'
import { OFS_CREATE_PATH } from 'enums/paths'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterModalForm from '../ToolbarFilter/filter'
import { downloadFile } from './helpers'

function Toolbar() {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [dlLoading, setDlLoading] = useState(false)
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const exportExcel = useExportExcel()

  const dlFile = async () => {
    setDlLoading(true)
    try {
      const response = await exportExcel.mutateAsync({})
      if (!isEmpty(response)) {
        downloadFile(response)
      }
    } catch {
      popSnackbar(t('export-error'), ERROR)
    }
    setDlLoading(false)
  }

  if (dlLoading) {
    return <LoadingScreen />
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <Button
        sx={{
          minWidth: (theme) => theme.spacing(5.625),
          height: (theme) => theme.spacing(5.625),
          width: (theme) => theme.spacing(5.625),
          bgcolor: 'secondary.main',
          borderRadius: 1,
          color: 'common.black',
          '&:hover': {
            bgcolor: 'secondary.main',
          },
          ml: 1.625,
        }}
        variant="contained"
        onClick={() => dlFile()}
      >
        <GetApp
          sx={{
            height: (theme) => theme.spacing(3.4),
            width: (theme) => theme.spacing(3.4),
          }}
        />
      </Button>
      <LinkButton
        sx={{
          minWidth: (theme) => theme.spacing(5.625),
          height: (theme) => theme.spacing(5.625),
          width: (theme) => theme.spacing(5.625),
          ml: 1.625,
          bgcolor: 'secondary.main',
          borderRadius: 1,
          color: 'common.black',
          '&:hover': {
            bgcolor: 'secondary.main',
          },
        }}
        to={OFS_CREATE_PATH}
        variant="contained"
      >
        <Add
          sx={{
            height: (theme) => theme.spacing(3.4),
            width: (theme) => theme.spacing(3.4),
          }}
        />
      </LinkButton>
      <Button
        sx={{
          minWidth: (theme) => theme.spacing(5.625),
          height: (theme) => theme.spacing(5.625),
          width: (theme) => theme.spacing(5.625),
          bgcolor: 'secondary.main',
          borderRadius: 1,
          color: 'common.black',
          '&:hover': {
            bgcolor: 'secondary.main',
          },
          ml: 1.625,
        }}
        variant="contained"
        onClick={handleClick}
      >
        <FilterList />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-list': {
            padding: 0,
            bgcolor: 'primary.main',
          },
        }}
      >
        <FilterModalForm />
      </Menu>
    </Box>
  )
}

export default Toolbar
