import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Box, IconButton, Typography } from '@mui/material'
import { useSyncMethods } from 'components/organisms/TrainingOrganization/contexts/SyncMethodsContext'
import { MethodToSync, SyncMethod } from 'domains/sasMethods/types'
import { find } from 'lodash'
import React from 'react'
import useStartSingleSynchronization from '../../hooks/startSingleSynchronization'
import {
  btnCommonsx,
  globalSyncWrapperSx,
  singleSyncWrapperSx,
} from '../../styles'
import SyncStateIcon from '../SyncStateIcon'

function SingleMethodWithState({
  syncMethod,
  index,
  singleSyncStates,
  isButtonDisabled,
  setIsButtonDisabled,
}: {
  syncMethod: SyncMethod
  index: number
  singleSyncStates: MethodToSync[]
  isButtonDisabled: boolean
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { trainingOrganization, setAutoRefetch } = useSyncMethods()

  const startSingleSynchronization = useStartSingleSynchronization({
    baseUrl: trainingOrganization?.url,
  })

  const syncMethodWithState = {
    syncMethod,
    syncStateWithName: find(
      singleSyncStates,
      (m) => m.description === syncMethod.description,
    ),
  }

  const handleClick = () => {
    setIsButtonDisabled(true)
    setAutoRefetch(true)
    startSingleSynchronization(index)
  }

  return (
    <Box sx={globalSyncWrapperSx}>
      <IconButton
        size="small"
        sx={btnCommonsx}
        onClick={handleClick}
        disabled={isButtonDisabled}
      >
        <AutorenewIcon />
      </IconButton>
      <Box sx={singleSyncWrapperSx}>
        <Typography variant="body1" sx={{ color: 'primary.light' }}>
          {syncMethodWithState.syncMethod.description}
        </Typography>
        <SyncStateIcon
          syncState={syncMethodWithState.syncStateWithName?.state}
        />
      </Box>
    </Box>
  )
}

export default SingleMethodWithState
