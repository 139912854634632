/* eslint-disable react/jsx-props-no-spreading */
import { SaveAsRounded } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import React from 'react'
import { useTranslation } from 'react-i18next'

type CategoriesFormProps = {
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>
}

function CategoriesForm({ onSubmit }: CategoriesFormProps) {
  const { t } = useTranslation()

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Box mb={2}>
        <ControlledTextField
          name="name"
          label={t('category-name')}
          fullWidth
          size="small"
        />
      </Box>
      <Box mb={2}>
        <ControlledTextField
          name="number"
          label={t('number')}
          fullWidth
          size="small"
          type="number"
        />
      </Box>
      <Button
        variant="contained"
        type="submit"
        color="secondary"
        fullWidth
        startIcon={<SaveAsRounded />}
      >
        {t('save')}
      </Button>
    </Box>
  )
}

export default CategoriesForm
