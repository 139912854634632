import { useQueryWrapper } from 'domains/helpers'
import { AUTHORIZED_TOKEN, AUTHORIZED_TOKENS } from './templates'
import { AuthorizedToken } from './types'

export function useGetAuthorizedTokensQuery(params?, options?) {
  return useQueryWrapper<AuthorizedToken[]>(
    'GET',
    AUTHORIZED_TOKENS,
    params,
    options,
  )
}

export function useGetAuthorizedTokenQuery(params?, options?) {
  return useQueryWrapper<AuthorizedToken>(
    'GET',
    AUTHORIZED_TOKEN,
    params,
    options,
  )
}
