import { Box } from '@mui/material'
import UsersManager from 'components/organisms/UsersManager'
import Header from 'components/templates/AdminLayout/components/Header'
import { USERS_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Users() {
  const { t } = useTranslation()
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Header
          defaultTitles={[
            { label: t('admin') },
            { label: t('users'), to: USERS_PATH },
          ]}
        />
      </Box>
      <UsersManager />
    </>
  )
}

export default Users
