import { TableCell, TableRow } from '@mui/material'
import ControlledCheckbox from 'components/molecules/ControlledCheckbox'
import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function PublicTypesComponent({ indexAppPublicType, appPublicType }) {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext()
  const isAppPublicVisibleInPortal = useWatch({
    control,
    name: `applicationPublicTypes[${indexAppPublicType}].isVisibleInPortal`,
  })

  const isVisibleInPortal = useWatch({
    control,
    name: `applicationPublicTypes[${indexAppPublicType}].isVisibleInPortal`,
  })

  useEffect(() => {
    if (!isAppPublicVisibleInPortal) {
      setValue(
        `applicationPublicTypes[${indexAppPublicType}].isEditableOnOfs`,
        false,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppPublicVisibleInPortal, indexAppPublicType])

  return (
    <TableRow key={appPublicType.id}>
      <TableCell size="small">{appPublicType.publicType.name}</TableCell>
      <TableCell size="small" align="right">
        <ControlledCheckbox
          label={t('yes')}
          name={`applicationPublicTypes[${indexAppPublicType}].isVisibleInPortal`}
        />
      </TableCell>
      <TableCell size="small" align="right">
        <ControlledCheckbox
          disabled={!isVisibleInPortal}
          label={t('yes')}
          name={`applicationPublicTypes[${indexAppPublicType}].isEditableOnOfs`}
        />
      </TableCell>
    </TableRow>
  )
}

export default PublicTypesComponent
