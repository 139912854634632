import React from 'react'
import AlertsList from '.'
import { AlertFiltersProvider } from '../../contexts/AlertFiltersContext'

function WrappedAlertsList({ listLength }) {
  return (
    <AlertFiltersProvider>
      <AlertsList listLength={listLength} />
    </AlertFiltersProvider>
  )
}

export default WrappedAlertsList
