import { Edit } from '@mui/icons-material'
import { Box } from '@mui/material'
import {
  DataGrid,
  GridActionsCellItem,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import { formatToShortDate } from 'components/organisms/TrainingOrganization/components/helpers'
import { useAuth } from 'contexts/AuthContext'
import { useGetAppParametersQuery } from 'domains/appParameters/queries'
import { APP_PARAMS_EDIT_PATH } from 'enums/paths'
import { isSupervisor } from 'helpers/role'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'

function AppParamsManager() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: appParameters = [], isLoading } = useGetAppParametersQuery()
  const navigate = useNavigate()

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'key',
      sort: 'asc',
    },
  ])

  const baseDataColumns = [
    {
      field: 'key',
      headerName: t('key'),
      width: 240,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'value',
      headerName: t('value'),
      width: 150,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'description',
      headerName: t('description'),
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
  ]

  const supervisorOnlyColumn = [
    {
      field: 'isOnlyForSupervisor',
      headerName: t('SUPERVISOR'),
      type: 'boolean',
      width: 110,
    },
  ]

  const updateDateColumn = [
    {
      field: 'updateDate',
      headerName: t('updateDate.small'),
      width: 110,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatToShortDate(params.value)
      },
    },
  ]

  const editAction = (params) => (
    <GridActionsCellItem
      icon={<Edit />}
      onClick={() =>
        navigate(urlcat(APP_PARAMS_EDIT_PATH, { id: params.id, params }))
      }
      label={t('edit')}
    />
  )

  const actionColumn = [
    {
      field: 'actions',
      type: 'actions',
      width: 55,
      getActions: (params) => [editAction(params)],
    },
  ]

  const columns =
    user.role && isSupervisor(user.role.key)
      ? [
          ...baseDataColumns,
          ...supervisorOnlyColumn,
          ...updateDateColumn,
          ...actionColumn,
        ]
      : [...baseDataColumns, ...updateDateColumn, ...actionColumn]

  return (
    <Box
      sx={{
        mt: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        sx={{
          flex: 1,
          borderRadius: 1,
          p: 2,
        }}
        loading={isLoading}
        columns={columns}
        rows={appParameters}
        checkboxSelection={false}
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  )
}

export default AppParamsManager
