import { Box, CircularProgress, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '@mui/icons-material/Download'
import { useOfFileQuery } from 'domains/of/queries'
import { CircularProgressSizes } from 'enums/circularProgressSizes'

function Files({ id }) {
  const { t } = useTranslation()
  const { data: files, isLoading } = useOfFileQuery(
    { id },
    { enabled: !isEmpty(id) },
  )

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h3" sx={{ color: 'secondary.main' }}>
        {t('cca-btp-intern-data')}
      </Typography>
      {isLoading ? (
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <CircularProgress
            sx={{ color: (theme) => theme.palette.secondary.main }}
            size={CircularProgressSizes.MEDIUM}
          />
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>
          {files &&
            files.map((file, index) => (
              <Box
                key={file?.id || index}
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  width: '100%',
                  mb: 1,
                }}
              >
                <a href={file?.url} target="_blank" rel="noreferrer">
                  <Box
                    sx={{
                      bgcolor: 'common.black',
                      color: 'primary.light',
                      borderRadius: '100%',
                      width: (theme) => theme.spacing(3.5),
                      height: (theme) => theme.spacing(3.5),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <DownloadIcon />
                  </Box>
                </a>
                <Typography variant="body1" sx={{ color: 'primary.light' }}>
                  {file?.name}
                </Typography>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  )
}

export default Files
