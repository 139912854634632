import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

function useOFSFilerModalForm() {
  const defaultValues = {
    search: '',
    source: null,
    application: null,
  }

  const validationSchema = Yup.object().shape({
    search: Yup.string(),
    source: Yup.object().nullable(),
    application: Yup.object().nullable(),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useOFSFilerModalForm
