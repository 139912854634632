import { useSasMutationWrapper } from 'domains/helpers'
import {
  METHODS_INVOKE,
  METHODS_INVOKE_SINGLE,
  METHODS_RELATED,
} from './templates'

export function useSyncMethodsMutation(baseUrl: string, options?) {
  return useSasMutationWrapper('POST', METHODS_RELATED, options, baseUrl, {})
}

export function useInvokeSyncMethodsMutation(baseUrl: string, options?) {
  return useSasMutationWrapper('POST', METHODS_INVOKE, options, baseUrl, {})
}

export function useInvokeSingleSyncMethodsMutation(baseUrl: string, options?) {
  return useSasMutationWrapper(
    'POST',
    METHODS_INVOKE_SINGLE,
    options,
    baseUrl,
    {},
  )
}
