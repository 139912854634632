import {
  LOG_APPLICATION,
  LOG_OF,
  LOG_OF_GENERAL,
  LOG_OF_GENERAL_REFERENTIEL,
} from 'domains/logs/templates'
import { LogsBlockVariant } from '../enums'

export function getLogsTemplate(variant: string) {
  switch (variant) {
    case LogsBlockVariant.VENDOR:
      return LOG_OF
    case LogsBlockVariant.APP:
      return LOG_APPLICATION
    case LogsBlockVariant.IS_REFERENTIEL:
      return LOG_OF_GENERAL_REFERENTIEL
    default:
      return LOG_OF_GENERAL
  }
}
