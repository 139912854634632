import { LOGOUT_PATH } from 'enums/paths'
import { Logout } from '@mui/icons-material'

export const userMenu = [
  {
    key: 'logout',
    title: 'logout',
    icon: Logout,
    to: LOGOUT_PATH,
  },
]
