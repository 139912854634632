import { Availability } from 'domains/sasMethods/types'

export const SOURCE = {
  YPAREO: { label: 'ypareo-info', value: 10 },
  EXCEL: { label: 'excel-info', value: 20 },
  ACTIVE_DIRECTORY: { label: 'active-directory-info', value: 30 },
  EXCEL_DATA_DIRECTORY: { label: 'excel-data-directory-info', value: 40 },
}

export const STATUS = {
  DRAFT: { label: 'draft', value: 10 },
  BEING_DEPLOYED: { label: 'being_deployed', value: 15 },
  ONLINE: { label: 'online', value: 20 },
  DISABLED: { label: 'disabled', value: 30 },
  BEING_DELETED: { label: 'being_deleted', value: 35 },
  DELETED: { label: 'deleted', value: 40 },
}

export const AVAILABILITES = {
  READY: { label: 'ready', value: Availability.Ready },
  BUSY: { label: 'busy', value: Availability.Busy },
  IN_WARNING: { label: 'in_warning', value: Availability.InWarning },
  BLOCKED: { label: 'blocked', value: Availability.Blocked },
}
