import { useQueryWrapper } from 'domains/helpers'
import { CATEGORIES, CATEGORY } from './templates'
import { Category } from './types'

export function useCategoriesQuery(params?, options?) {
  return useQueryWrapper<Category[]>('GET', CATEGORIES, params, options)
}

export function useCategoryQuery(params?, options?) {
  return useQueryWrapper<Category>('GET', CATEGORY, params, options)
}
