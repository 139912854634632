import { Box } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdateApplicationMutation } from 'domains/application/mutations'
import { useApplicationQuery } from 'domains/application/queries'
import { APPLICATION, APPLICATIONS } from 'domains/application/templates'
import { useMethodsQuery } from 'domains/methods/queries'
import { useGetPublicTypesQuery } from 'domains/publicTypes/queries'
import { APPLICATIONS_PATH } from 'enums/paths'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import useCreateApplicationForm from '../../form'
import { formatEditAppForm } from '../../helper'
import ApplicationForm from '../ApplicationForm'

function EditApplicationForm() {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const form = useCreateApplicationForm()
  const updateApplication = useUpdateApplicationMutation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()
  const params = useParams()
  const { id } = params
  const { data: application, isLoading } = useApplicationQuery(
    { id },
    { enabled: !isEmpty(id) },
  )
  const queryClient = useQueryClient()
  const { data: methodsEntites } = useMethodsQuery()
  const { data: publicTypes = [] } = useGetPublicTypesQuery()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  useEffect(() => {
    form.reset(
      formatEditAppForm(
        application,
        form.getValues(),
        methodsEntites,
        publicTypes,
      ),
    )
    if (!isLoading) {
      form.setValue('logo', {
        url: application?.applicationLogoUrl,
        name: application?.applicationLogoUrl,
      })
      form.setValue('picture', {
        url: application?.applicationPictureUrl,
        name: application?.applicationPictureUrl,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, methodsEntites, publicTypes])

  const onSubmit = async (values) => {
    if (!isEmpty(form.getValues('methods'))) {
      if (!form.getValues('isWSRest')) {
        await confirm(
          t('warning-non-wsrest-app'),
          t('confirm-delete-common-methods'),
        )
      }
    }

    if (values.isCommonApp && form.formState.touchedFields.isCommonApp) {
      await confirm(
        t('warning-common-app'),
        t('confirm-delete-common-connections'),
      )
    }
    updateApplication
      .mutateAsync(
        {
          id,
          data: {
            ...values,
            applicationLogoUrl: values?.logo?.url,
            applicationPictureUrl: values?.picture?.url,
          },
        },
        {
          onSuccess: () => {
            popSnackbar(t('app-edit-success'), SUCCESS)
            setIsDirty(false)
            queryClient.invalidateQueries({ queryKey: [APPLICATION] })
            queryClient.invalidateQueries({ queryKey: [APPLICATIONS] })
          },
        },
      )
      .then(() => navigate(APPLICATIONS_PATH))
  }

  return (
    <Box>
      <Helmet>
        <meta
          name="titles"
          content={JSON.stringify([
            { label: t('admin') },
            {
              label: t('applications'),
              to: APPLICATIONS_PATH,
              confirm: {
                title: t('back-to-list-confirmation'),
                message: t('changes-shall-disappear'),
                enable: form.formState.isDirty,
              },
            },
            { label: t('update-title') },
          ])}
        />
      </Helmet>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 2,
            pt: 0,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <ApplicationForm
              control={form.control}
              onSubmit={form.handleSubmit(onSubmit)}
              variant="edit"
            />
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default EditApplicationForm
