import { Box } from '@mui/material'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useCreatePublicTypesMutation } from 'domains/publicTypes/mutations'
import { PUBLIC_TYPES } from 'domains/publicTypes/templates'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreatePublicTypeForm from '../../form'
import PublicTypeForm from '../Form'

function PublicTypeCreateForm({ handleClose }) {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const form = useCreatePublicTypeForm()
  const createPublicType = useCreatePublicTypesMutation()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (values) => {
    await createPublicType.mutateAsync({ data: values })
    popSnackbar(t('public-type-add-success'), SUCCESS)
    setIsDirty(false)
    queryClient.invalidateQueries(PUBLIC_TYPES)
    handleClose()
  }

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          bgcolor: 'common.white',
          borderRadius: 1,
        }}
      >
        <PublicTypeForm form={form} onSubmit={onSubmit} />
      </Box>
    </Box>
  )
}

export default PublicTypeCreateForm
