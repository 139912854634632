import { Delete, Edit } from '@mui/icons-material'
import { GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import { useAuth } from 'contexts/AuthContext'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteMailTemplateMutation } from 'domains/mailTemplates/mutations'
import { useGetMailTemplatesQuery } from 'domains/mailTemplates/queries'
import { MAIL_TEMPLATES } from 'domains/mailTemplates/templates'
import { EMAIL_MANAGEMENT_TEMPLATES_EDIT_PATH } from 'enums/paths'
import { isSupervisor } from 'helpers/role'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'

const useTemplatesGrid = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { data: emailTemplates, isLoading } = useGetMailTemplatesQuery()
  const deleteMailTemplate = useDeleteMailTemplateMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const handleDelete = (id) =>
    confirm(t('irreversible-action'), t('confirm-delete-template'))
      .then(() =>
        deleteMailTemplate.mutate(
          { id },
          {
            onSuccess: () => {
              popSnackbar(t('mail-template-delete-success'), SUCCESS)
              queryClient.invalidateQueries(MAIL_TEMPLATES)
            },
          },
        ),
      )
      .catch(() => null)

  const dataColumns = [
    {
      field: 'key',
      headerName: t('key'),
      width: 250,
      editable: false,
    },
    {
      field: 'name',
      headerName: t('template-name'),
      width: 250,
      editable: false,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'subject',
      headerName: t('subject'),
      flex: 1,
      editable: false,
    },
  ]

  const editAction = (id) => (
    <GridActionsCellItem
      onClick={() =>
        navigate(urlcat(EMAIL_MANAGEMENT_TEMPLATES_EDIT_PATH, { id }))
      }
      icon={<Edit />}
      label={t('edit')}
    />
  )

  const deleteAction = (id) => (
    <GridActionsCellItem
      icon={<Delete />}
      onClick={() => handleDelete(id)}
      label={t('delete')}
    />
  )

  const actionColumn = [
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ id }) =>
        user.role && isSupervisor(user.role.key)
          ? [editAction(id), deleteAction(id)]
          : [editAction(id)],
    },
  ]

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'key',
      sort: 'asc',
    },
  ])

  const columns = [...dataColumns, ...actionColumn]

  const rows = emailTemplates || []

  return { columns, rows, isLoading, sortModel, setSortModel }
}

export default useTemplatesGrid
