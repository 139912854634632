export type MethodToSync = {
  description: string
  state: number
  isInGlobalSync: boolean
}

export enum Availability {
  Ready = 10,
  Busy = 20,
  InWarning = 30,
  Blocked = 40,
}

export type SASStatus = {
  availability: Availability
  isLastSyncGlobal: boolean
  methods: MethodToSync[]
}

export type SyncMethod = {
  name?: string
  description?: string
  isMandatory: boolean
  index: number | null
}

export type SyncMethodsLists = {
  globalSyncMethods: SyncMethod[]
  singleSyncMethods: SyncMethod[]
}

export type ApplicationMethod = {
  methodId?: string
  frequency?: number
}
