import { User } from 'domains/users/types'
import { get, isEmpty } from 'lodash'
import { formatMethod } from './components/MethodsComponent/formatters'

export function formatUsers(users: User[]) {
  const usersFormattedTemp = []
  if (!isEmpty(users)) {
    users.forEach((user) => {
      const firstname = user?.firstname || ''
      const lastname = user?.lastname || ''
      const email = user?.email || ''
      const fullname = `${firstname} ${lastname}`
      const label = isEmpty(fullname.trim()) ? email : `${fullname} (${email})`
      usersFormattedTemp.push({
        label,
        id: get(user, 'id', null),
      })
    })
  }
  return usersFormattedTemp
}

export function findMethod(methods: any[], methodId: string) {
  return methods.find((method) => method.id === methodId)
}

export function formatEditAppForm(
  app: any,
  form: any,
  methods: any[],
  publicTypes: any[],
) {
  const defaultValues = { ...form, ...app }
  defaultValues.applicationPublicTypes.sort((a, b) => {
    const nameA = a.publicType.name.toUpperCase()
    const nameB = b.publicType.name.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
  if (app && !isEmpty(methods)) {
    defaultValues.methods = defaultValues.methods.map((method) => ({
      method: formatMethod(findMethod(methods, method.methodId)),
      frequency: method.frequency,
    }))
  } else {
    defaultValues.methods = []
  }
  return defaultValues
}
