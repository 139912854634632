import { Box, Button, Paper } from '@mui/material'
import { ReactComponent as LoginImage } from 'assets/images/login.svg'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useForgotPasswordMutation } from 'domains/users/mutations'
import { FORGOT_PASSWORD_SUCCESS_PATH } from 'enums/paths'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useForgotPasswordForm from './form'

function ForgotPasswordForm() {
  const { t } = useTranslation()
  const form = useForgotPasswordForm()
  const forgotPassword = useForgotPasswordMutation()
  const navigate = useNavigate()

  const onSubmit = async (values) => {
    forgotPassword.mutate(
      { data: values },
      {
        onSuccess: () => {
          navigate(FORGOT_PASSWORD_SUCCESS_PATH)
        },
      },
    )
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Paper
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 4,
        }}
      >
        <Box
          sx={{
            '& svg': {
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
            },
            textAlign: 'center',
          }}
        >
          <LoginImage />
        </Box>
        <ControlledTextField name="email" label={t('email')} />
        <Button color="primary" type="submit">
          {t('reset-password')}
        </Button>
      </Paper>
    </FormProvider>
  )
}

export default ForgotPasswordForm
