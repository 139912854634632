import { Box } from '@mui/material'
import { ReactComponent as LogoSVG } from 'assets/images/logo.svg'
import { HOME_PATH } from 'enums/paths'
import React from 'react'
import { Link } from 'react-router-dom'

function Logo() {
  return (
    <Box
      sx={{
        borderRadius: 2,
        bgcolor: 'primary.dark',
        '& svg': {
          width: (theme) => theme.spacing(6),
          height: (theme) => theme.spacing(6),
        },
        mr: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
      }}
    >
      <Link to={HOME_PATH}>
        <LogoSVG />
      </Link>
    </Box>
  )
}

export default Logo
