import { Fullscreen, Refresh } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'

function StandardHeaderButtons({
  setOpenDialogFullScreen,
  refetchLogs,
  isFetching,
}) {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Refresh
        onClick={() => refetchLogs()}
        sx={{
          cursor: !isFetching && 'pointer',
          color: isFetching && 'primary.main',
        }}
      />
      <Fullscreen
        onClick={() => setOpenDialogFullScreen(true)}
        sx={{
          cursor: 'pointer',
        }}
      />
    </Box>
  )
}

export default StandardHeaderButtons
