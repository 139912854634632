import { Box, Divider, Grid, Typography } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { titleHeightSx } from '../../style'

function PlanningInfoForm() {
  const { t } = useTranslation()
  return (
    <Grid item xs={12} ml={3} mt={0.5}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          ...titleHeightSx,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t('planning')}
        </Typography>
        <Box
          sx={{
            flex: 1,
          }}
          ml={2}
          mt={1.5}
        >
          <Divider
            sx={{
              bgcolor: (theme) => theme.palette.secondary.main,
              height: (theme) => theme.spacing(0.3),
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              ...titleHeightSx,
            }}
          >
            <Typography variant="label">{t('sync')}</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="syncHour"
                label={t('hours')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="syncMinute"
                label={t('minutes')}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              ...titleHeightSx,
            }}
          >
            <Typography variant="label" mb={2}>
              {t('ofs-deletion')}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="deletionDay"
                label={t('day')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="deletionMonth"
                label={t('month')}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} mt={0.5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              ...titleHeightSx,
            }}
          >
            <Typography variant="label">{t('accessibility-start')}</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="accessHourBegin"
                label={t('hours')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="accessMinuteBegin"
                label={t('minutes')}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} mt={0.5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              ...titleHeightSx,
            }}
          >
            <Typography variant="label">{t('accessibility-end')}</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="accessHourEnd"
                label={t('hours')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="accessMinuteEnd"
                label={t('minutes')}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default PlanningInfoForm
