import { Box, FormHelperText, Typography } from '@mui/material'
import JoditEditor from 'jodit-react'
import { get } from 'lodash'
import React, { forwardRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { WysiwygUploadVariant } from './enum'
import { ControlledWysiwygProps } from './type'

const ControlledWysiwyg = forwardRef((props: ControlledWysiwygProps, ref) => {
  const {
    name,
    control: controlFromProps,
    label,
    variant,
    height = '100%',
    fieldErrorName = '',
    allowResizeX = true,
    allowResizeY = true,
  } = props

  const formContext = useFormContext()
  const controlFromContext = get(formContext, 'control')
  const { t } = useTranslation()
  const controller = useController({
    name,
    control: controlFromProps || controlFromContext,
  })
  const {
    field: { ref: fieldRef, onChange, value },
    fieldState: { error },
  } = controller
  const errorFromOtherField = get(
    formContext.formState.errors,
    fieldErrorName,
    null,
  )
  if (value === undefined) {
    console.error(
      `[FTEL-ERROR] : Le composant ControlledWysiwig '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
    )
  }

  const valueRef = React.useRef(null)
  valueRef.current = value

  const config = React.useMemo(
    () => ({
      readonly: false,
      height,
      statusbar: false,
      allowEmpty: true,
      toolbarAdaptive: false,
      uploader: {
        url: process.env.REACT_APP_PUBLIC_MEDIA_URL,
        insertImageAsBase64URI: variant === WysiwygUploadVariant.BASE64,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        getMessage: (resp) => {
          onChange(`${valueRef.current} <img src="${resp}" />`)
          return resp
        },
      },
      allowResizeX,
      allowResizeY,
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'ul',
        'ol',
        'fontsize',
        'image',
        'table',
        'outdent',
        'indent',
        'align',
        'link',
        'brush',
        'fullsize',
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .jodit-container:not(.jodit_inline)': {
          borderColor: error || errorFromOtherField ? 'error.main' : 'grey.400',
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        '& .jodit_sticky > .jodit-toolbar__box': {
          position: 'static',
        },
        '& .jodit-react-container': {
          flex: 1,
        },
        '& .jodit-workplace': {
          flex: 1,
        },
        height: 1,
      }}
    >
      {label && (
        <Typography
          variant="body1"
          sx={{
            color: error || errorFromOtherField ? 'error.main' : 'common.black',
          }}
        >
          {label}
        </Typography>
      )}
      <JoditEditor
        ref={fieldRef}
        value={value}
        config={config}
        onBlur={(newContent) => onChange(newContent)}
      />
      {(error || errorFromOtherField) && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {get(errorFromOtherField, 'message', null) ??
            get(error, 'message', t('invalid-field'))}
        </FormHelperText>
      )}
    </Box>
  )
})

export default ControlledWysiwyg
