import { Close, FilterList, Refresh } from '@mui/icons-material'
import { Badge, Box } from '@mui/material'
import FullScreenFilterLogsForm from 'components/organisms/LogTabFilter/components/FullScreenFilterLogsForm'
import { isEmpty, trim } from 'lodash'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLogFilters } from '../../contexts/LogFiltersContext'
import { formatLogTypesToForm } from '../helpers'

function FullscreenHeaderButtons({
  setOpenDialogFullScreen,
  refetchLogs,
  isFetching,
}) {
  const form = useFormContext()
  const [openFilterFullScreen, setOpenFilterFullScreen] = useState(false)
  const {
    setFilterType,
    setFilterStartDate,
    setFilterEndDate,
    setFilterMethod,
    setFilterDescription,
  } = useLogFilters()

  const onSubmit = (values) => {
    setFilterType(formatLogTypesToForm(values.types))
    setFilterStartDate(values.dateStart)
    setFilterEndDate(values.dateEnd)
    setFilterMethod(trim(values.method))
    setFilterDescription(trim(values.description))
  }

  const fieldsAreEmpty =
    isEmpty(form.watch('dateStart')) &&
    isEmpty(form.watch('dateEnd')) &&
    isEmpty(form.watch('types')) &&
    isEmpty(form.watch('method')) &&
    isEmpty(form.watch('description'))

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Refresh
        onClick={() => refetchLogs()}
        sx={{
          cursor: !isFetching && 'pointer',
          color: isFetching && 'primary.main',
        }}
      />
      <Badge
        overlap="circular"
        variant="dot"
        invisible={fieldsAreEmpty}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: 'secondary.main',
          },
        }}
      >
        <FilterList
          onClick={() => setOpenFilterFullScreen(!openFilterFullScreen)}
          sx={{
            cursor: 'pointer',
          }}
        />
      </Badge>
      <Close
        onClick={() => {
          setOpenFilterFullScreen(false)
          setOpenDialogFullScreen(false)
        }}
        sx={{
          cursor: 'pointer',
        }}
      />
      <FullScreenFilterLogsForm
        open={openFilterFullScreen}
        onSubmit={onSubmit}
        form={form}
      />
    </Box>
  )
}

export default FullscreenHeaderButtons
