import { Box, Typography } from '@mui/material'
import React from 'react'

function ControllerWithHeader({ children, title }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1.625 }}>
      <Typography
        variant="h3"
        sx={{
          fontSize: 20,
          fontWeight: 'bold',
          color: 'common.white',
          mb: 1,
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  )
}

export default ControllerWithHeader
