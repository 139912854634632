import { Box, CircularProgress, Typography, alpha } from '@mui/material'
import { CircularProgressSizes } from 'enums/circularProgressSizes'
import React from 'react'
import LogCell from '../LogCell'
import LogIcon from '../LogIcon'
import { formatToLogDatetime } from '../helpers'
import { LogLinesProps } from './type'

function LogLines({
  logs,
  methodFlexValue,
  descriptionFlexValue,
  isLoading,
  hideMethods = false,
  sx = {},
}: LogLinesProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        minWidth: 0,
        flex: 1,
        width: '100%',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: (theme) => theme.spacing(1),
          backgroundColor: (theme) => alpha(theme.palette.common.black, 0.8),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'primary.main',
          borderRadius: 5,
          border: 2,
          borderColor: 'common.black',
        },
        borderBottom: (theme) => `1px solid ${theme.palette.common.black}`,
        ...sx,
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            sx={{ color: (theme) => theme.palette.primary.main }}
            size={CircularProgressSizes.MEDIUM}
          />
        </Box>
      ) : (
        logs?.map((log, index) => (
          <Box
            key={log?.id}
            sx={{
              display: 'flex',
              color: 'primary.light',
              width: '100%',
              borderTop: (theme) => `1px solid ${theme.palette.common.black}`,
              ...(index === logs.length - 1 && { flex: 1 }),
            }}
          >
            <LogCell
              sx={{
                width: (theme) => theme.spacing(6.5),
                textAlign: 'center',
              }}
            >
              <LogIcon type={log.type} />
            </LogCell>
            <LogCell
              sx={{
                width: (theme) => theme.spacing(16),
              }}
            >
              <Typography noWrap variant="caption">
                {formatToLogDatetime(log?.createDate)}
              </Typography>
            </LogCell>
            {!hideMethods && (
              <LogCell sx={{ flex: methodFlexValue }}>
                <Typography variant="caption">{log.method}</Typography>
              </LogCell>
            )}
            <LogCell
              sx={{
                flex: descriptionFlexValue,
                minWidth: 0,
                wordWrap: 'break-word',
                borderRight: 0,
              }}
            >
              <Typography variant="caption">{log.description}</Typography>
            </LogCell>
          </Box>
        ))
      )}
    </Box>
  )
}

export default LogLines
