import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'
import Toolbar from './components/Toolbar'
import useTemplatesGrid from './grid'

function EmailTemplatesManager() {
  const { columns, rows, isLoading, sortModel, setSortModel } =
    useTemplatesGrid()

  return (
    <Box
      sx={{
        mt: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          flex: 1,
          borderRadius: 1,
          p: 2,
        }}
        loading={isLoading}
        columns={columns}
        rows={rows}
        onProcessRowUpdateError={null}
        components={{ Toolbar }}
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  )
}

export default EmailTemplatesManager
