import { Box, Grid } from '@mui/material'
import Header from 'components/molecules/Header'
import { useAuth } from 'contexts/AuthContext'
import { HOME_PATH } from 'enums/paths'
import { isAdminOFA } from 'helpers/role'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ActionsList from './components/ActionsList'
import SheetLogs from './components/SheetLogs'
import { LogFiltersProvider } from './contexts/LogFiltersContext'
import {
  SyncMethodsProvider,
  useSyncMethods,
} from './contexts/SyncMethodsContext'

function TrainingOrganizationComponent({ id }) {
  const { trainingOrganization } = useSyncMethods()
  const { t } = useTranslation()
  const params = useParams()
  const { id: ofId } = params
  const { user } = useAuth()

  return (
    <Box sx={{ mb: 2 }}>
      <Header
        titles={[
          { label: t('app-title'), to: HOME_PATH },
          { label: t('of-name', { name: trainingOrganization?.shortName }) },
        ]}
      />
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item xs={12} lg={8}>
          {trainingOrganization && <SheetLogs id={ofId} />}
        </Grid>
        <Grid item xs={12} lg={4}>
          {user.role && isAdminOFA(user.role.key) && <ActionsList id={id} />}
        </Grid>
      </Grid>
    </Box>
  )
}

function WrappedTrainingOrganizationComponent({ id }) {
  return (
    <SyncMethodsProvider id={id}>
      <LogFiltersProvider id={id}>
        <TrainingOrganizationComponent id={id} />
      </LogFiltersProvider>
    </SyncMethodsProvider>
  )
}

export default WrappedTrainingOrganizationComponent
