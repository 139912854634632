import { LOGLINE_PER_PAGE } from 'components/organisms/TrainingOrganization/enums'
import { LOGTYPES } from 'domains/logs/enums'
import { useUnionLogsQuery } from 'domains/logs/queries'
import { formattedEndDate, formattedStartDate } from 'formatters/date'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const AlertFiltersContext = createContext(null)

function AlertFiltersProvider({ children }) {
  const [ofId, setOfId] = useState<string>(null)
  const [applicationId, setApplicationId] = useState<string>(null)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [method, setMethod] = useState<string>('')
  const [methodSearch, setMethodSearch] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [startDate, setStartDate] = useState(formattedStartDate)
  const [endDate, setEndDate] = useState(formattedEndDate)
  const [types, setTypes] = useState([
    LOGTYPES.WARNING.value,
    LOGTYPES.ERROR.value,
    LOGTYPES.CRITICAL.value,
  ])
  const [discriminator, setDiscriminator] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(LOGLINE_PER_PAGE[1])

  const { data, isFetching, refetch } = useUnionLogsQuery(
    {
      ofId,
      applicationId,
      pageIndex,
      rowsPerPage,
      dateStart: startDate,
      dateEnd: endDate,
      types,
      method: methodSearch,
      description,
      discriminator,
    },
    {
      enabled: openDialog,
    },
  )

  const handleOpen = useCallback(
    (
      newOfId,
      newApplicationId,
      newMethod,
      newAlertLabel,
      newStartDate,
      newEndDate,
      newDiscriminator,
    ) => {
      setOfId(newOfId)
      setApplicationId(newApplicationId)
      setMethod(newMethod)
      setMethodSearch(newMethod)
      setDescription('')
      setDialogTitle(newAlertLabel)
      setOpenDialog(true)
      setStartDate(newStartDate)
      setEndDate(newEndDate)
      setDiscriminator(newDiscriminator)
    },
    [
      setOfId,
      setApplicationId,
      setMethod,
      setMethodSearch,
      setDialogTitle,
      setOpenDialog,
      setDiscriminator,
    ],
  )

  useEffect(() => {
    if (openDialog) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, rowsPerPage])

  const values = useMemo(
    () => ({
      openDialog,
      setOpenDialog,
      data,
      dialogTitle,
      pageIndex,
      setPageIndex,
      rowsPerPage,
      setRowsPerPage,
      setDescription,
      setTypes,
      setStartDate,
      setEndDate,
      method,
      setMethod,
      refetch,
      handleOpen,
      isFetching,
      methodSearch,
      setMethodSearch,
    }),
    [
      openDialog,
      setOpenDialog,
      data,
      dialogTitle,
      pageIndex,
      setPageIndex,
      rowsPerPage,
      setRowsPerPage,
      setDescription,
      setTypes,
      setStartDate,
      setEndDate,
      method,
      setMethod,
      refetch,
      handleOpen,
      isFetching,
      methodSearch,
      setMethodSearch,
    ],
  )

  return (
    <AlertFiltersContext.Provider value={values}>
      {children}
    </AlertFiltersContext.Provider>
  )
}

const useAlertFilters = () => {
  const context = useContext(AlertFiltersContext)
  if (context === undefined) {
    throw new Error(
      'useAlertFilters must be used within a AlertFiltersProvider',
    )
  }
  return context
}

export { AlertFiltersProvider, useAlertFilters }
