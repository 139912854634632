import { DateTime } from 'luxon'

export const formattedStartDate: string = DateTime.now()
  .set({ hour: 12, minutes: 0, milliseconds: 0, seconds: 0 })
  .minus({ days: 1 })
  .toISO({
    suppressSeconds: true,
    suppressMilliseconds: true,
    includeOffset: false,
  })

export const formattedEndDate: string = DateTime.now()
  .set({ hour: 23, minutes: 59, milliseconds: 0, seconds: 0 })
  .toISO({
    suppressSeconds: true,
    suppressMilliseconds: true,
    includeOffset: false,
  })
