import { SaveAsRounded } from '@mui/icons-material'
import SyncIcon from '@mui/icons-material/Sync'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdateAuthorizedTokensMutation } from 'domains/authorizedTokens/mutations'
import { useGetAuthorizedTokenQuery } from 'domains/authorizedTokens/queries'
import { AUTHORIZED_TOKENS } from 'domains/authorizedTokens/templates'
import { useOfGenerateTokenQuery } from 'domains/of/queries'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreateAuthorizedTokenForm from '../../form'

function AuthorizedTokenEditForm({ id, handleClose }) {
  const { t } = useTranslation()
  const { data: authorizedToken } = useGetAuthorizedTokenQuery({ id })
  const form = useCreateAuthorizedTokenForm()
  const { control, setValue } = form
  const updateAuthorizedToken = useUpdateAuthorizedTokensMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (values) => {
    await updateAuthorizedToken.mutateAsync({ id, data: values })
    popSnackbar(t('authorized-token-edit-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [AUTHORIZED_TOKENS] })
    handleClose()
    setIsDirty(false)
  }

  const { data: token, refetch } = useOfGenerateTokenQuery()
  const tokenControl = useWatch({
    control,
    name: 'token',
  })
  const generateToken = () => {
    refetch()
    setValue('token', token)
  }

  useEffect(
    function loadPublicTypeFields() {
      if (!isEmpty(authorizedToken)) {
        form.reset(authorizedToken)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authorizedToken],
  )

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            bgcolor: 'common.white',
            borderRadius: 1,
            pt: 1,
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
              <Box mb={2}>
                <ControlledTextField
                  name="name"
                  label={t('name')}
                  fullWidth
                  size="small"
                />
              </Box>
              <Box mb={2}>
                <ControlledTextField
                  name="token"
                  InputLabelProps={{ shrink: false }}
                  label={isEmpty(tokenControl) ? t('token') : ''}
                  fullWidth
                  disabled
                  sx={{
                    borderRadius: 1,
                  }}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={t('generate-token')}>
                        <IconButton
                          size="small"
                          sx={{
                            width: (theme) => theme.spacing(3),
                            height: (theme) => theme.spacing(3),
                          }}
                          onClick={generateToken}
                        >
                          <SyncIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Box>
              <Box mb={2}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  color="secondary"
                  startIcon={<SaveAsRounded />}
                >
                  {t('save')}
                </Button>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}
export default AuthorizedTokenEditForm
