import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'
import Toolbar from './components/Toolbar'
import useApplicationsGrid from './grid'

function ApplicationsManager() {
  const { columns, rows, isFetching, sortModel, setSortModel } =
    useApplicationsGrid()
  return (
    <Box>
      <Box
        sx={{
          mt: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: (theme) => `calc(100vh - ${theme.spacing(16)})`,
        }}
      >
        <DataGrid
          sx={{
            flex: 1,
            borderRadius: 1,
            p: 2,
          }}
          loading={isFetching}
          columns={columns}
          rows={rows}
          components={{ Toolbar }}
          checkboxSelection={false}
          disableColumnMenu
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </Box>
    </Box>
  )
}

export default ApplicationsManager
