import { Box, Button, Paper, Typography } from '@mui/material'
import { ReactComponent as LoginImage } from 'assets/images/login.svg'
import { LOGIN_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function ForgotPasswordSuccess() {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 4,
        maxWidth: (theme) => theme.spacing(80),
      }}
    >
      <Box
        sx={{
          '& svg': {
            width: (theme) => theme.spacing(25),
            height: (theme) => theme.spacing(25),
          },
          textAlign: 'center',
        }}
      >
        <LoginImage />
        <Typography sx={{ mt: '25px', mb: '25px' }} variant="body1">
          {t('forgot-password-success')}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          component={Link}
          to={LOGIN_PATH}
        >
          {t('back-to-connection')}
        </Button>
      </Box>
    </Paper>
  )
}

export default ForgotPasswordSuccess
