import { useSyncMethods } from 'components/organisms/TrainingOrganization/contexts/SyncMethodsContext'
import { useInvokeSyncMethodsMutation } from 'domains/sasMethods/mutations'
import { times } from 'lodash'
import { SyncStateEnum } from '../enums'

function useStartChainSynchronizations({ id, setGlobalSyncStates, baseUrl }) {
  const { globalSyncMethods } = useSyncMethods()
  const invokeSyncMethods = useInvokeSyncMethodsMutation(baseUrl, {})

  const startChainedSynchronizations = async () => {
    const newSyncStateArray = [SyncStateEnum.RUNNING].concat(
      times(globalSyncMethods.length - 1, () => SyncStateEnum.WAITING),
    )
    setGlobalSyncStates(newSyncStateArray)

    await invokeSyncMethods.mutateAsync({
      data: { methodNames: globalSyncMethods.map((method) => method.name) },
    })
  }

  return startChainedSynchronizations
}

export default useStartChainSynchronizations
