import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useAddExistingUserInApplicationForm() {
  const { t } = useTranslation()

  const defaultValues = {
    user: null,
  }

  const validationSchema = Yup.object().shape({
    user: Yup.object().required(t('required-field')).nullable(),
  })

  return useForm({ defaultValues, resolver: yupResolver(validationSchema) })
}

export default useAddExistingUserInApplicationForm
