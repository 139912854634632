import {
  CancelOutlined,
  CheckCircleOutlined,
  HelpOutlineOutlined,
  OfflineBoltOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { LOGTYPES } from 'domains/logs/enums'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LOG_ICON_SIZE } from './enum'
import { LogIconProps } from './type'

function LogIcon({ type }: LogIconProps) {
  const { t } = useTranslation()
  switch (type) {
    case LOGTYPES.INVALID_ENDPOINT.value:
      return (
        <Tooltip title={t('log-invalid-endpoint')}>
          <WarningAmberOutlined
            sx={{
              mt: 0.5,
              color: 'logInvalidEndpoint.main',
              width: LOG_ICON_SIZE,
              height: LOG_ICON_SIZE,
            }}
          />
        </Tooltip>
      )
    case LOGTYPES.INFO.value:
      return (
        <Tooltip title={t('log-info')}>
          <CheckCircleOutlined
            sx={{
              mt: 0.5,
              color: 'success.main',
              width: LOG_ICON_SIZE,
              height: LOG_ICON_SIZE,
            }}
          />
        </Tooltip>
      )
    case LOGTYPES.WARNING.value:
      return (
        <Tooltip title={t('log-warning')}>
          <WarningAmberOutlined
            sx={{
              mt: 0.5,
              color: 'error.light',
              width: LOG_ICON_SIZE,
              height: LOG_ICON_SIZE,
            }}
          />
        </Tooltip>
      )
    case LOGTYPES.ERROR.value:
      return (
        <Tooltip title={t('log-error')}>
          <CancelOutlined
            sx={{
              mt: 0.5,
              color: 'error.main',
              width: LOG_ICON_SIZE,
              height: LOG_ICON_SIZE,
            }}
          />
        </Tooltip>
      )

    case LOGTYPES.CRITICAL.value:
      return (
        <Tooltip title={t('log-critical')}>
          <OfflineBoltOutlined
            sx={{
              mt: 0.5,
              color: 'error.dark',
              width: LOG_ICON_SIZE,
              height: LOG_ICON_SIZE,
            }}
          />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title={t('log-unknown')}>
          <HelpOutlineOutlined
            sx={{
              mt: 0.5,
              color: 'primary.light',
              width: LOG_ICON_SIZE,
              height: LOG_ICON_SIZE,
            }}
          />
        </Tooltip>
      )
  }
}

export default LogIcon
