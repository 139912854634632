import { Delete, Edit } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import {
  formatToReadableDate,
  formatToShortDate,
} from 'components/organisms/TrainingOrganization/components/helpers'
import { useConfirm } from 'contexts/ConfirmContext'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { SOURCE, STATUS } from 'domains/of/enums'
import { useDeleteOFSMutation } from 'domains/of/mutations'
import { OFS_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'
import { useOfs } from './context/ofs'
import { getLabelFromEnum } from './formatters'
import { getDeletionDate, getSyncHour } from './helpers'

const useOFSGrid = () => {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const { ofs, refetch, isFetching } = useOfs()
  const deleteOfs = useDeleteOFSMutation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()

  const handleDelete = (id) =>
    confirm(t('irreversible-action'), t('confirm-delete-ofs'))
      .then(() =>
        deleteOfs.mutate(
          { id },
          {
            onSuccess: () => {
              popSnackbar(t('of-delete-success'), SUCCESS)
              refetch()
            },
            onError: () => {
              popSnackbar(t('of-delete-error'), ERROR)
              refetch()
            },
          },
        ),
      )
      .catch(() => null)

  const dataColumns = [
    {
      field: 'id',
      headerName: t('id'),
      width: 50,
      editable: false,
      height: 100,
      hide: true,
    },
    {
      field: 'name',
      headerName: t('ofs-name'),
      editable: false,
      flex: 4,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'shortName',
      headerName: t('ofs-shortname'),
      editable: false,
      flex: 2.5,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'syncHour',
      headerName: t('job'),
      editable: false,
      maxWidth: 75,
      flex: 1.5,
      valueGetter: getSyncHour,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'deletionDate',
      headerName: t('deletion'),
      editable: false,
      maxWidth: 130,
      flex: 2.5,
      valueGetter: getDeletionDate,
      renderCell: (params) => (
        <TooltipCell value={formatToReadableDate(params.value, t, true)} />
      ),
    },
    {
      field: 'isActive',
      headerName: t('is-active'),
      editable: false,
      flex: 1.2,
      renderCell: (params) => (
        <Typography>{params.value ? t('yes') : t('no')}</Typography>
      ),
    },
    {
      field: 'status',
      headerName: t('status'),
      editable: false,
      maxWidth: 120,
      flex: 2,
      renderCell: (params) => (
        <Typography>{t(getLabelFromEnum(STATUS, params.value))}</Typography>
      ),
    },
    {
      field: 'source',
      headerName: t('source'),
      editable: false,
      flex: 1.5,
      maxWidth: 80,
      renderCell: (params) => (
        <Typography>{t(getLabelFromEnum(SOURCE, params.value))}</Typography>
      ),
    },
    {
      field: 'deployDate',
      headerName: t('deployDate'),
      editable: false,
      flex: 2,
      maxWidth: 120,
      renderCell: (params) => (
        <TooltipCell value={formatToShortDate(params.value)} />
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      type: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          onClick={() => navigate(urlcat(OFS_EDIT_PATH, { id }))}
          icon={<Edit />}
          label={t('edit')}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          onClick={() => handleDelete(id)}
          label={t('delete')}
        />,
      ],
    },
  ]

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ])

  const columns = dataColumns

  const rows = ofs || []

  return { columns, rows, isFetching, sortModel, setSortModel }
}

export default useOFSGrid
