import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditCategoryForm from './components/Edit'
import Toolbar from './components/Toolbar'
import useCategoriesGrid from './grid'

function CategoriesManager() {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [idForm, setIdForm] = useState(null)

  const handleOpen = (id) => {
    setOpen(true)
    setIdForm(id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { columns, rows, isFetching, sortModel, setSortModel } =
    useCategoriesGrid({ handleOpen })

  return (
    <>
      <Box>
        <Box
          sx={{
            mt: 2,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: (theme) => `calc(100vh - ${theme.spacing(16)})`,
          }}
        >
          <DataGrid
            sx={{
              flex: 1,
              borderRadius: 1,
              p: 2,
            }}
            loading={isFetching}
            columns={columns}
            rows={rows}
            components={{ Toolbar }}
            disableColumnMenu
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('edit-category')}
            <IconButton size="small" onClick={() => handleClose()}>
              <Close sx={{ color: 'common.black' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <EditCategoryForm id={idForm} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CategoriesManager
