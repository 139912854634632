import { CssBaseline } from '@mui/material'
import 'animate.css'
import React from 'react'

import VersionNumber from 'components/atoms/Version'
import Snackbar from 'components/organisms/Snackbar'
import { GlobalFormStateProvider } from 'contexts/GlobalFormStateContext'
import QueryClientProvider from 'contexts/QueryClientProvider'
import ThemeProvider from 'contexts/ThemeProvider'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import './config/i18n'
import routes from './config/routes'
import { AuthorizationProvider } from './contexts/AuthAuthorization'
import { AuthProvider } from './contexts/AuthContext'
import { AxiosInterceptors } from './contexts/AxiosInterceptors'
import { ConfirmProvider } from './contexts/ConfirmContext'
import { SnackbarProvider } from './contexts/SnackbarContext'

function Router() {
  return useRoutes(routes)
}

function Entrypoint() {
  return (
    <ThemeProvider>
      <SnackbarProvider>
        <ConfirmProvider>
          <QueryClientProvider>
            <AuthProvider>
              <AuthorizationProvider>
                <BrowserRouter>
                  <AxiosInterceptors>
                    <GlobalFormStateProvider>
                      <CssBaseline />
                      <Snackbar />
                      <Router />
                      <VersionNumber />
                    </GlobalFormStateProvider>
                  </AxiosInterceptors>
                </BrowserRouter>
              </AuthorizationProvider>
            </AuthProvider>
          </QueryClientProvider>
        </ConfirmProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Entrypoint
