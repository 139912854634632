import AppParamEdit from 'components/organisms/AppParamsManager/components/Edit'
import ApplicationsManager from 'components/organisms/ApplicationsManager'
import CreateApplicationForm from 'components/organisms/ApplicationsManager/components/Create'
import EditApplicationForm from 'components/organisms/ApplicationsManager/components/Edit'
import CategoriesManager from 'components/organisms/CategoriesManager'
import FilesManager from 'components/organisms/FilesManager'
import ForgotPasswordForm from 'components/organisms/ForgotPasswordForm'
import ForgotPasswordSuccess from 'components/organisms/ForgotPasswordSuccess'
import MailFusionFieldsForm from 'components/organisms/MailFusionFieldsForm'
import MailFusionFieldsManager from 'components/organisms/MailFusionFieldsManager'
import MailTemplateForm from 'components/organisms/MailTemplateForm'
import MailTemplatesManager from 'components/organisms/MailTemplatesManager'
import EmailTemplateEdit from 'components/organisms/MailTemplatesManager/components/Edit'
import OFSManager from 'components/organisms/OFSManager'
import CreateOFSForm from 'components/organisms/OFSManager/components/Create'
import EditOFSForm from 'components/organisms/OFSManager/components/Edit'
import UsersForm from 'components/organisms/UsersForm'
import UsersEdit from 'components/organisms/UsersManager/components/Edit'
import Admin from 'components/routes/Admin'
import App from 'components/routes/App'
import AppParams from 'components/routes/AppParams'
import Applications from 'components/routes/Applications'
import AuthorizedTokens from 'components/routes/AuthorizedTokens'
import Categories from 'components/routes/Categories'
import Files from 'components/routes/Files'
import ForgotPassword from 'components/routes/ForgotPassword'
import Home from 'components/routes/Home'
import Internationalization from 'components/routes/Internationalization'
import Login from 'components/routes/Login'
import Logout from 'components/routes/Logout'
import MailManagement from 'components/routes/MailManagement'
import NotFound from 'components/routes/NotFound'
import OFS from 'components/routes/OFS'
import PublicTypes from 'components/routes/PublicTypes'
import ResetPassword from 'components/routes/ResetPassword'
import TrainingOrganization from 'components/routes/TrainingOrganization'
import UnderConstruction from 'components/routes/UnderConstruction'
import Users from 'components/routes/Users'
import { useAuth } from 'contexts/AuthContext'
import { APPLICATIONS_PATH, EMAIL_MANAGEMENT_TEMPLATES_PATH } from 'enums/paths'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

function RequireAuth({ children }) {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

const routes = [
  {
    path: '',
    element: (
      <RequireAuth>
        <App />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <Home /> },
      {
        path: 'training-organization/:id',
        element: <TrainingOrganization />,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
    children: [
      {
        index: true,
        element: <ForgotPasswordForm />,
      },
      {
        path: 'success',
        element: <ForgotPasswordSuccess />,
      },
    ],
  },
  {
    path: 'reset-password/:key',
    element: <ResetPassword />,
  },
  {
    path: 'admin',
    element: (
      <RequireAuth>
        <Admin />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <Navigate to={APPLICATIONS_PATH} /> },
      {
        path: 'app-params',
        element: <AppParams />,
      },
      {
        path: 'app-params/:id/edit',
        element: <AppParamEdit />,
      },
      {
        path: 'public-types',
        element: <PublicTypes />,
      },
      {
        path: 'authorized-tokens',
        element: <AuthorizedTokens />,
      },
      {
        path: 'email-management',
        element: <MailManagement />,
        children: [
          {
            index: true,
            element: <Navigate to={EMAIL_MANAGEMENT_TEMPLATES_PATH} />,
          },
          {
            path: 'templates',
            element: <MailTemplatesManager />,
          },
          {
            path: 'templates/create',
            element: <MailTemplateForm />,
          },
          {
            path: 'templates/:id/edit',
            element: <EmailTemplateEdit />,
          },
          {
            path: 'layout',
            element: <UnderConstruction />,
          },
          {
            path: 'fusion-fields',
            element: <MailFusionFieldsManager />,
          },
          {
            path: 'fusion-fields/create',
            element: <MailFusionFieldsForm />,
          },
          {
            path: 'test',
            element: <UnderConstruction />,
          },
          {
            path: '*',
            element: <Navigate to={EMAIL_MANAGEMENT_TEMPLATES_PATH} />,
          },
        ],
      },
      { path: 'internationalization', element: <Internationalization /> },
      {
        path: 'users',
        element: <Users />,
      },
      { path: 'users/create', element: <UsersForm /> },
      {
        path: 'users/:id/edit',
        element: <UsersEdit />,
      },
      {
        path: 'ofs',
        element: <OFS />,
        children: [
          {
            index: true,
            element: <OFSManager />,
          },
          {
            path: 'create',
            element: <CreateOFSForm />,
          },
          {
            path: ':id/edit',
            element: <EditOFSForm />,
          },
        ],
      },
      {
        path: 'applications',
        element: <Applications />,
        children: [
          {
            index: true,
            element: <ApplicationsManager />,
          },
          {
            path: 'create',
            element: <CreateApplicationForm />,
          },
          {
            path: ':id/edit',
            element: <EditApplicationForm />,
          },
        ],
      },
      {
        path: 'files',
        element: <Files />,
        children: [
          {
            index: true,
            element: <FilesManager />,
          },
        ],
      },
      {
        path: 'categories',
        element: <Categories />,
        children: [
          {
            index: true,
            element: <CategoriesManager />,
          },
        ],
      },
      { path: '*', element: <Navigate to={APPLICATIONS_PATH} /> },
    ],
  },
  { path: '*', element: <NotFound /> },
]

export default routes
