import { Cancel, FileUpload } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import Uploady, {
  UploadyContext,
  useItemFinalizeListener,
  useUploady,
} from '@rpldy/uploady'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MEDIA_TYPE_FILE } from './enums'
import { formatFileName } from './helper'

function Upload({
  onChange,
  value,
  resetValue,
  deletionButton = false,
  cutName = false,
}) {
  const uploady = useUploady()
  const { t } = useTranslation()

  const { popSnackbar } = useSnackbar()

  useContext(UploadyContext)

  useItemFinalizeListener((item) => {
    if (item) {
      popSnackbar(t('file-has-been-uploaded'), SUCCESS)
    }
    onChange({
      url: item.uploadResponse?.data,
      name: item?.file?.name,
      type: MEDIA_TYPE_FILE,
    })
  })

  return (
    <Box
      sx={{
        justifyContent: 'center',
        display: 'inline-flex',
        flexDirection: 'row',
        gap: 2,
        p: 1,
        maxWidth: 600,
      }}
    >
      <Box sx={{ width: 130 }}>
        <Button
          size="small"
          color="secondary"
          startIcon={<FileUpload sx={{ color: 'common.black' }} />}
          onClick={(event) => {
            event.stopPropagation()
            uploady.showFileUpload()
          }}
        >
          {t('choose')}
        </Button>
      </Box>
      <Box sx={{ textAlign: 'left' }}>
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
          <Typography display="inline">{t('uploaded-file-name')}</Typography>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            <Typography
              sx={{
                fontWeight: 'bold',
                wordWrap: 'break-word',
                flexWrap: 'wrap',
              }}
            >
              {value?.name
                ? formatFileName(value.name, cutName)
                : t('no-file-uploaded')}
            </Typography>
            {deletionButton && !isEmpty(value?.url) && (
              <IconButton
                sx={{
                  width: (theme) => theme.spacing(2.5),
                  height: (theme) => theme.spacing(2.5),
                  ml: 0.5,
                }}
                onClick={() => resetValue()}
              >
                <Cancel
                  sx={{
                    width: (theme) => theme.spacing(2.5),
                    height: (theme) => theme.spacing(2.5),
                    color: 'error.main',
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Box>
        {value?.url && (
          <a target="_blank" href={value.url} rel="noreferrer">
            <Typography>{t('download')}</Typography>
          </a>
        )}
      </Box>
    </Box>
  )
}
function UploadFile({
  onChange,
  value,
  resetValue,
  deletionButton = false,
  cutName = false,
}) {
  return (
    <Uploady destination={{ url: process.env.REACT_APP_PUBLIC_MEDIA_URL }}>
      <Upload
        onChange={onChange}
        value={value}
        resetValue={resetValue}
        deletionButton={deletionButton}
        cutName={cutName}
      />
    </Uploady>
  )
}
export default UploadFile
