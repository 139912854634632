import { Close, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { DataGrid, GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import { useAuth } from 'contexts/AuthContext'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteAuthorizedTokensMutation } from 'domains/authorizedTokens/mutations'
import { useGetAuthorizedTokensQuery } from 'domains/authorizedTokens/queries'
import { AUTHORIZED_TOKENS } from 'domains/authorizedTokens/templates'
import { isAdminPlus } from 'helpers/role'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { sortModelType } from '../PublicTypesManager/sort'
import AuthorizedTokenEditForm from './components/Edit'
import Toolbar from './components/Toolbar'

function AuthorizedTokensManager() {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const { popSnackbar } = useSnackbar()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const [idForm, setIdForm] = useState(null)
  const { data: authorizedTokens = [], isLoading } =
    useGetAuthorizedTokensQuery()
  const deleteAuthorizedToken = useDeleteAuthorizedTokensMutation()
  const queryClient = useQueryClient()

  const handleOpen = (id) => {
    setOpen(true)
    setIdForm(id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async (id) => {
    await confirm(
      t('authorized-token-delete-confirmation'),
      t('irreversible-action'),
    )
    await deleteAuthorizedToken.mutateAsync({ id })
    popSnackbar(t('authorized-token-delete-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [AUTHORIZED_TOKENS] })
  }

  const [sortModel, setSortModel] = React.useState<GridSortModel>(sortModelType)

  const baseDataColumns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'token',
      headerName: t('token'),
      flex: 1,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
  ]

  const editAction = (params) => (
    <GridActionsCellItem
      icon={<Edit />}
      onClick={() => handleOpen(params.id)}
      label={t('edit')}
    />
  )

  const deleteAction = (params) => (
    <GridActionsCellItem
      icon={<Delete />}
      onClick={() => handleDelete(params.id)}
      label={t('delete')}
    />
  )

  const actionColumn = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t('actions'),
      width: 100,
      getActions: (params) =>
        user.role && isAdminPlus(user.role.key)
          ? [editAction(params), deleteAction(params)]
          : [],
    },
  ]

  const columns = [...baseDataColumns, ...actionColumn]

  return (
    <>
      <Box
        sx={{
          mt: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DataGrid
          sx={{ flex: 1, borderRadius: 1, p: 2 }}
          loading={isLoading}
          columns={columns}
          rows={authorizedTokens}
          components={{ Toolbar }}
          checkboxSelection={false}
          disableColumnMenu
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('edit-authorized-token')}
            <IconButton size="small" onClick={() => handleClose()}>
              <Close sx={{ color: 'common.black' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AuthorizedTokenEditForm id={idForm} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}
export default AuthorizedTokensManager
