import { useQueryWrapper } from 'domains/helpers'
import { USER_ME, USERS, USER, USER_PWD_KEY } from './templates'
import { User } from './types'

export function useUserMeQuery(options?) {
  return useQueryWrapper<User>('GET', USER_ME, null, options)
}

export function useUsersQuery(params?, options?) {
  return useQueryWrapper<User[]>('GET', USERS, params, options)
}

export function useUserQuery(params?, options?) {
  return useQueryWrapper<User>('GET', USER, params, options)
}

export function useUserByPasswordKeyQuery(params?, options?) {
  return useQueryWrapper<User>('GET', USER_PWD_KEY, params, options)
}
