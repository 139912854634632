import { Check } from '@mui/icons-material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { useGroupedLogsQuery } from 'domains/logs/queries'
import { CircularProgressSizes } from 'enums/circularProgressSizes'
import { GetListLengthInPx, OFCardsLiningProps } from 'enums/oFCardsLiningProps'
import { isEmpty, orderBy } from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AlertDatePicker from '../AlertDatePicker'
import AlertDetailDialog from '../AlertDetailDialog'
import { DEFAULT_ALERTS_TYPES_FILTER } from '../AlertDetailDialog/filter'
import OFLogAlert from '../OFLogAlert'
import { ALERT_ICON_CHECK } from './enum'
import useAlertsFilterForm from './form'

function AlertsList({ listLength }) {
  const { t } = useTranslation()
  const form = useAlertsFilterForm()
  const { setValue, watch } = form
  const startDateControl = watch('startDate')
  const endDateControl = watch('endDate')

  const { data: groupedAlerts, isFetching } = useGroupedLogsQuery({
    types: DEFAULT_ALERTS_TYPES_FILTER.map((type) => type.value),
    dateStart: startDateControl,
    dateEnd: endDateControl,
    isReferentiel: false,
  })

  const addOneDay = () => {
    const newStartDate = DateTime.fromISO(startDateControl)
      .plus({ days: 1 })
      .toISO({
        suppressSeconds: true,
        suppressMilliseconds: true,
        includeOffset: false,
      })
    const newEndDate = DateTime.fromISO(endDateControl)
      .plus({ days: 1 })
      .toISO({
        suppressSeconds: true,
        suppressMilliseconds: true,
        includeOffset: false,
      })

    setValue('startDate', newStartDate)
    setValue('endDate', newEndDate)
  }

  const removeOneDay = () => {
    const newStartDate = DateTime.fromISO(startDateControl)
      .minus({ days: 1 })
      .toISO({
        suppressSeconds: true,
        suppressMilliseconds: true,
        includeOffset: false,
      })
    const newEndDate = DateTime.fromISO(endDateControl)
      .minus({ days: 1 })
      .toISO({
        suppressSeconds: true,
        suppressMilliseconds: true,
        includeOffset: false,
      })

    setValue('startDate', newStartDate)
    setValue('endDate', newEndDate)
  }

  return (
    <Box
      sx={{
        bgcolor: 'primary.dark',
        pt: 1,
        borderRadius: 1,
      }}
    >
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...form}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            mt: 1,
            justifyContent: 'space-around',
            '& svg path': {
              fill: (theme) => theme.palette.primary.light,
            },
          }}
        >
          <Box display="flex" mt={1}>
            <IconButton onClick={removeOneDay}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Box>
              <AlertDatePicker
                name="startDate"
                label={t('start-period')}
                InputProps={{ inputProps: { max: endDateControl } }}
              />
            </Box>
          </Box>
          <Box display="flex" mt={1}>
            <Box>
              <AlertDatePicker
                name="endDate"
                label={t('end-period')}
                InputProps={{ inputProps: { min: startDateControl } }}
              />
            </Box>
            <IconButton onClick={addOneDay}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
        {isFetching && (
          <Box
            sx={{
              textAlign: 'center',
              minHeight: (theme) => theme.spacing(50),
            }}
          >
            <CircularProgress
              sx={{ my: 10, color: (theme) => theme.palette.primary.main }}
              size={CircularProgressSizes.MEDIUM}
            />
          </Box>
        )}
        {!isFetching &&
          (isEmpty(groupedAlerts) ? (
            <Box
              sx={{
                pb: 4,
                px: 4,
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1.5,
                minHeight: (theme) => theme.spacing(50),
              }}
            >
              <Check
                sx={{
                  color: 'primary.main',
                  height: ALERT_ICON_CHECK,
                  width: ALERT_ICON_CHECK,
                }}
              />
              <Typography variant="h6" sx={{ color: 'primary.main' }}>
                {t('no-alert-found')}
              </Typography>
            </Box>
          ) : (
            <Box
              component="div"
              sx={{
                pt: 2,
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                minHeight: (theme) => theme.spacing(50),
                maxHeight: {
                  xs: GetListLengthInPx(listLength, OFCardsLiningProps.xs),
                  md: GetListLengthInPx(listLength, OFCardsLiningProps.md),
                  xl: GetListLengthInPx(listLength, OFCardsLiningProps.xl),
                },
              }}
            >
              {groupedAlerts &&
                orderBy(groupedAlerts, 'ofName').map((groupedAlert) => (
                  <OFLogAlert key={groupedAlert.ofName} alert={groupedAlert} />
                ))}
              <AlertDetailDialog
                dateStart={startDateControl}
                dateEnd={endDateControl}
              />
            </Box>
          ))}
      </FormProvider>
    </Box>
  )
}
export default AlertsList
