/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip, Box } from '@mui/material'
import React from 'react'

function RoundImage({ src, alt = '', tooltip, classes, ...props }) {
  return (
    src &&
    (tooltip !== undefined ? (
      <Tooltip title={tooltip}>
        <Box
          sx={{
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            borderRadius: '50%',
            objectFit: 'cover',
            ...classes,
          }}
        >
          <img alt={alt} src={src} {...props} />
        </Box>
      </Tooltip>
    ) : (
      <Box
        sx={{
          width: (theme) => theme.spacing(3),
          height: (theme) => theme.spacing(3),
          borderRadius: '50%',
          objectFit: 'cover',
          ...classes,
        }}
      >
        <img alt={alt} src={src} {...props} />
      </Box>
    ))
  )
}

export default RoundImage
