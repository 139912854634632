import { yupResolver } from '@hookform/resolvers/yup'
import { SOURCE, STATUS } from 'domains/of/enums'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { AnyObject } from 'yup/lib/types'
import { useOf } from '../../context/of'
import { formatEditForm } from '../../helpers'

function useCreateOFSForm(isFormCreate) {
  const { t } = useTranslation()
  const { of } = useOf()

  const [fieldsInError, setFieldsInError] = useState<string[]>([])

  const validateField = (value, context: Yup.TestContext<AnyObject>) =>
    isEmpty(fieldsInError) ||
    !fieldsInError.some(
      (f) => f.trim().toLowerCase() === context?.path?.trim()?.toLowerCase(),
    )

  const defaultValues = {
    name: '',
    shortName: '',
    source: SOURCE.YPAREO.value,
    users: [],
    admins: [],
    readers: [],
    syncHour: 1,
    syncMinute: 0,
    accessHourBegin: 7,
    accessMinuteBegin: 15,
    accessHourEnd: 21,
    accessMinuteEnd: 30,
    deletionDay: 31,
    deletionMonth: 10,
    isActive: false,
    connections: [],
    status: STATUS.DRAFT.value,
    address: '',
    token: '',
    activeDirectoryUrl: '',
    activeDirectoryLogin: '',
    activeDirectoryPassword: '',
    activeDirectoryOU: '',
    activeDirectoryPort: 0,
    ofPublicTypes: [],
    path: '',
    user: null,
    _LogFolderName: '',
    url: '',
    code: '',
  }

  const dayValidation = Yup.number()
    .typeError(t('invalid-number'))
    .min(1, `${t('min-allowed', { value: 1 })}`)
    .max(31, `${t('max-allowed', { value: 31 })}`)
    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
    .nullable()

  const monthValidation = Yup.number()
    .typeError(t('invalid-number'))
    .min(1, `${t('min-allowed', { value: 1 })}`)
    .max(12, `${t('max-allowed', { value: 12 })}`)
    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
    .nullable()

  const minuteValidation = Yup.number()
    .typeError(t('invalid-number'))
    .required(t('required-field'))
    .min(0, `${t('min-allowed', { value: 0 })}`)
    .max(59, `${t('max-allowed', { value: 59 })}`)

  const hourValidation = Yup.number()
    .typeError(t('invalid-number'))
    .required(t('required-field'))
    .min(0, `${t('min-allowed', { value: 0 })}`)
    .max(23, `${t('max-allowed', { value: 23 })}`)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')).nullable(),
    code: Yup.string()
      .required(t('required-field'))
      .nullable()
      .test('error', t('code-already-taken'), validateField),
    shortName: Yup.string().required(t('required-field')).nullable(),
    source: Yup.number().required(t('required-field')),
    token: Yup.string()
      .ensure()
      .when('source', {
        is: SOURCE.YPAREO.value,
        then: Yup.string().required(t('required-field')).nullable(),
        otherwise: Yup.string().nullable(),
      }),
    address: Yup.string()
      .ensure()
      .when('source', {
        is: SOURCE.YPAREO.value,
        then: Yup.string().required(t('required-field')).nullable(),
        otherwise: Yup.string().nullable(),
      }),
    activeDirectoryUrl: Yup.string()
      .ensure()
      .when('source', {
        is: SOURCE.ACTIVE_DIRECTORY.value,
        then: Yup.string().nullable().required(t('required-field')),
        otherwise: Yup.string().nullable(),
      }),
    activeDirectoryPort: Yup.number()
      .typeError(t('invalid-number'))
      .when('source', {
        is: SOURCE.ACTIVE_DIRECTORY.value,
        then: Yup.number().nullable().required(t('required-field')),
        otherwise: Yup.number().nullable(),
      }),
    activeDirectoryOU: Yup.string()
      .ensure()
      .when('source', {
        is: SOURCE.ACTIVE_DIRECTORY.value,
        then: Yup.string().nullable().required(t('required-field')),
        otherwise: Yup.string().nullable(),
      }),
    activeDirectoryLogin: Yup.string()
      .ensure()
      .when('source', {
        is: SOURCE.ACTIVE_DIRECTORY.value,
        then: Yup.string().nullable().required(t('required-field')),
        otherwise: Yup.string().nullable(),
      }),
    activeDirectoryPassword: Yup.string()
      .ensure()
      .when(['source'], {
        is: (source) =>
          source === SOURCE.ACTIVE_DIRECTORY.value && isFormCreate,
        then: Yup.string().nullable().required(t('required-field')),
        otherwise: Yup.string().nullable(),
      }),
    path: Yup.string().nullable(),
    user: Yup.object().nullable(),
    syncMinute: minuteValidation,
    syncHour: hourValidation,
    accessHourBegin: hourValidation,
    accessMinuteBegin: minuteValidation,
    accessHourEnd: hourValidation,
    accessMinuteEnd: minuteValidation,
    deletionDay: dayValidation,
    deletionMonth: monthValidation,
    isActive: Yup.bool(),
    connections: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('required-field')),
        token: Yup.string().nullable(),
        email: Yup.string().email(t('invalid-email')),
        application: Yup.object().nullable().required(t('required-field')),
      }),
    ),
    ofPublicTypes: Yup.array().of(
      Yup.object().shape({
        publicType: Yup.object().required(t('required-field')),
        isActive: Yup.bool(),
        filter: Yup.string().nullable(),
        activeDirectoryFields: Yup.array(),
      }),
    ),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const codeFieldValue = form.watch('code')

  useEffect(() => {
    if (!isEmpty(fieldsInError)) {
      setFieldsInError([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeFieldValue])

  useEffect(() => {
    if (!isEmpty(fieldsInError)) {
      form.trigger('code')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsInError])

  useEffect(() => {
    if (!isEmpty(of)) {
      form.reset(formatEditForm(of, defaultValues))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [of])

  return { form, setFieldsInError }
}

export default useCreateOFSForm
