import { Box } from '@mui/material'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useCreateApplicationMutation } from 'domains/application/mutations'
import { APPLICATION, APPLICATIONS } from 'domains/application/templates'
import { APPLICATIONS_PATH } from 'enums/paths'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useCreateApplicationForm from '../../form'
import ApplicationForm from '../ApplicationForm'

function CreateApplicationForm() {
  const { t } = useTranslation()
  const form = useCreateApplicationForm()
  const createApplication = useCreateApplicationMutation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (values) => {
    await createApplication.mutateAsync({
      data: {
        ...values,
        folderName: values.name,
        applicationLogoUrl: values?.logo?.url,
        applicationPictureUrl: values?.picture?.url,
      },
    })
    setIsDirty(false)
    popSnackbar(t('app-add-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [APPLICATION] })
    queryClient.invalidateQueries({ queryKey: [APPLICATIONS] })
    navigate(APPLICATIONS_PATH)
  }
  return (
    <Box>
      <Helmet>
        <meta
          name="titles"
          content={JSON.stringify([
            { label: t('admin') },
            {
              label: t('applications'),
              to: APPLICATIONS_PATH,
              confirm: {
                title: t('back-to-list-confirmation'),
                message: t('changes-shall-disappear'),
                enable: form.formState.isDirty,
              },
            },
            { label: t('create-title') },
          ])}
        />
      </Helmet>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 2,
            pt: 0,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <ApplicationForm
              control={form.control}
              onSubmit={form.handleSubmit(onSubmit)}
              variant="creation"
            />
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateApplicationForm
