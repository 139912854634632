import { Add } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MethodsComponent from '../MethodsComponent'
import { FrequencyEnum } from '../MethodsComponent/enums'
import MethodsHeader from '../MethodsHeader'

function MethodsForm({ methods, addMethod, removeMethod, selectedMethods }) {
  const { t } = useTranslation()

  const handleAddMethod = (event) => {
    event.stopPropagation()
    addMethod({ method: null, frequency: FrequencyEnum.DAILY.value })
  }

  return (
    <Accordion
      sx={{
        borderRadius: 1,
        bgcolor: 'secondary.main',
        '&.Mui-expanded': { my: 0 },
        '& .MuiCollapse-root': { mb: 2 },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: 0,
          px: 1.5,
          '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
            {
              my: 1,
            },
          '&.Mui-expanded': {
            minHeight: 0,
          },
          ':not(.Mui-expanded) .addButton': {
            display: 'none',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" width={1}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('used-methods')}
          </Typography>
          <Box
            className="addButton"
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'common.black',
              borderRadius: 1,
              p: 0.25,
              mr: 2,
              width: (theme) => theme.spacing(3),
              height: (theme) => theme.spacing(3),
              cursor: 'pointer',
              '& svg': {
                width: '100%',
              },
              '& svg path': {
                fill: (theme) => theme.palette.common.white,
              },
            }}
            onClick={handleAddMethod}
          >
            <Add />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {!isEmpty(methods) && (
          <Box>
            <MethodsHeader />
            {methods.map((method, index) => (
              <MethodsComponent
                key={method.id}
                selectedMethods={selectedMethods}
                indexMethod={index}
                removeMethod={removeMethod}
              />
            ))}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default MethodsForm
