import AdminLayout from 'components/templates/AdminLayout'
import React from 'react'
import { Outlet } from 'react-router-dom'

function Admin() {
  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  )
}

export default Admin
