import { yupResolver } from '@hookform/resolvers/yup'
import { validatePhoneNumbers } from 'helpers/validators'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { APP_ROLES_LABELS, OF_ROLES_LABELS } from './helper'

function useCreateUserForm() {
  const { t } = useTranslation()
  const defaultValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    role: null,
    application: null,
    of: null,
  }

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(t('required-field')),
    lastname: Yup.string().required(t('required-field')),
    email: Yup.string().email(t('invalid-email')).required(t('required-field')),
    phone: Yup.string()
      .nullable(true)
      .test('phone', t('invalid-phone'), validatePhoneNumbers),
    role: Yup.object().required(t('required-field')).nullable(true),
    application: Yup.object().when('role', {
      is: (role) => role && APP_ROLES_LABELS.includes(role.label),
      then: Yup.object().required(t('required-field')).nullable(true),
      otherwise: Yup.object().nullable(true),
    }),
    of: Yup.object().when('role', {
      is: (role) => role && OF_ROLES_LABELS.includes(role.label),
      then: Yup.object().required(t('required-field')).nullable(true),
      otherwise: Yup.object().nullable(true),
    }),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateUserForm
