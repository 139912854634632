import { Box, FormHelperText } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UploadFile from './components/UploadFile'

function ControlledUploadFile({
  name,
  label,
  deletionButton = false,
  cutName = false,
}) {
  const { t } = useTranslation()
  const formContext = useFormContext()
  const resetValue = () => formContext.setValue(name, { name: null, url: null })
  const controlFromContext = get(formContext, 'control')
  const error = get(formContext, `formState.errors.${name}`)

  return (
    <Box sx={{ flex: 1 }}>
      <Controller
        name={name}
        control={controlFromContext}
        render={({ field }) => (
          <UploadFile
            value={field.value}
            onChange={field.onChange}
            resetValue={resetValue}
            deletionButton={deletionButton}
            cutName={cutName}
          />
        )}
      />
      {error && (
        <FormHelperText
          sx={{
            color: 'error.main',
            ml: (theme) => theme.spacing(1.75),
          }}
        >
          {get(error, 'message', t('invalid-field'))}
        </FormHelperText>
      )}
    </Box>
  )
}

export default ControlledUploadFile
