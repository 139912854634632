import TrainingOrganizationOrganism from 'components/organisms/TrainingOrganization'
import React from 'react'
import { useParams } from 'react-router-dom'

function TrainingOrganization() {
  const params = useParams()
  const { id } = params
  return <TrainingOrganizationOrganism id={id} />
}

export default TrainingOrganization
