import { useQueryWrapper } from 'domains/helpers'
import {
  OF,
  OFS,
  OFS_HOME,
  OF_FILES,
  OF_GENERATE_TOKEN,
  OF_USER_MAPPING_FIELDS,
  OF_USER_URL_FUSION_FIELDS,
} from './templates'
import { Of } from './types'

export function useOfsQuery(params?, options?) {
  return useQueryWrapper<Of[]>('GET', OFS, params, options)
}

export function useOfsHomeQuery(params?, options?) {
  return useQueryWrapper<Of[]>('GET', OFS_HOME, params, options)
}

export function useOfQuery(params?, options?) {
  return useQueryWrapper<Of>('GET', OF, params, options)
}

export function useOfGenerateTokenQuery(params?, options?) {
  return useQueryWrapper<string>('GET', OF_GENERATE_TOKEN, params, options)
}

export function useOfFileQuery(params?, options?) {
  return useQueryWrapper<Of[]>('GET', OF_FILES, params, options)
}

export function useOfUrlFusionFieldsQuery(params?, options?) {
  return useQueryWrapper<string[]>(
    'GET',
    OF_USER_URL_FUSION_FIELDS,
    params,
    options,
  )
}

export function useOfMappingFieldsQuery(params?, options?) {
  return useQueryWrapper<string[]>(
    'GET',
    OF_USER_MAPPING_FIELDS,
    params,
    options,
  )
}
