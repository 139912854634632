import { ArrowDropDown } from '@mui/icons-material'
import { Box, Collapse, Divider, Typography } from '@mui/material'
import { LOGTYPES } from 'domains/logs/enums'
import { filter, isNil, orderBy } from 'lodash'
import React, { useState } from 'react'
import AlertChip from '../AlertChip'
import ApplicationLogAlert from '../ApplicationLogAlert'
import {
  LOG_APP_DISCRIMINATOR,
  LOG_OF_DISCRIMINATOR,
} from '../ApplicationLogAlert/enum'
import { OFLogAlertProps } from './type'

function OFLogAlert({ alert }: OFLogAlertProps) {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const OfSubAlert = filter(
    alert.logGroupedApplications,
    (groupedAlerts) => groupedAlerts.discriminator === LOG_OF_DISCRIMINATOR,
  )
  const AppSubAlert = filter(
    alert.logGroupedApplications,
    (groupedAlerts) => groupedAlerts.discriminator === LOG_APP_DISCRIMINATOR,
  )
  const orderedSubAlerts = [
    ...orderBy(OfSubAlert, 'method'),
    ...orderBy(AppSubAlert, ['applicationName', 'method']),
  ]

  const criticLogs = filter(alert.logsCounts, { type: LOGTYPES.CRITICAL.value })
  const errorLogs = filter(alert.logsCounts, { type: LOGTYPES.ERROR.value })
  const warningLogs = filter(alert.logsCounts, { type: LOGTYPES.WARNING.value })

  return (
    <Box
      sx={{
        borderRadius: 3,
        mx: 1,
        mb: 1,
        bgcolor: (theme) => theme.palette.primary.light,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={handleExpandClick}
      >
        <Typography
          sx={{
            ml: 1,
            my: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}
          variant="body2"
        >
          {alert.ofName}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& svg path': {
                fill: (theme) => theme.palette.common.white,
              },
            }}
          >
            <AlertChip
              key={LOGTYPES.CRITICAL.value}
              type={LOGTYPES.CRITICAL.value}
              count={isNil(criticLogs[0]) ? 0 : criticLogs[0].logCount}
              height={(theme) => theme.spacing(3)}
              width={(theme) => theme.spacing(8)}
              sx={{
                cursor: 'pointer',
              }}
            />
            <AlertChip
              key={LOGTYPES.ERROR.value}
              type={LOGTYPES.ERROR.value}
              count={isNil(errorLogs[0]) ? 0 : errorLogs[0].logCount}
              height={(theme) => theme.spacing(3)}
              width={(theme) => theme.spacing(8)}
              sx={{
                cursor: 'pointer',
              }}
            />
            <AlertChip
              key={LOGTYPES.WARNING.value}
              type={LOGTYPES.WARNING.value}
              count={isNil(warningLogs[0]) ? 0 : warningLogs[0].logCount}
              height={(theme) => theme.spacing(3)}
              width={(theme) => theme.spacing(8)}
              sx={{
                cursor: 'pointer',
              }}
            />
          </Box>
          <ArrowDropDown
            sx={{
              transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              margin: 0.5,
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              color: 'primary.dark',
            }}
          />
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ bgcolor: 'primary.dark', mx: 0.5 }} />
        {orderedSubAlerts.map((groupedAlert) => (
          <ApplicationLogAlert
            key={`${groupedAlert.applicationName}-${groupedAlert.method}`}
            alert={groupedAlert}
            ofId={alert.ofId}
            ofName={alert.ofName}
          />
        ))}
      </Collapse>
    </Box>
  )
}

export default OFLogAlert
