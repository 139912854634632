import { MethodToSync, SASStatus } from 'domains/sasMethods/types'
import { filter } from 'lodash'

export function ExtractSyncState(sasStatus: SASStatus, isGlobalSync: boolean) {
  if (sasStatus?.methods) {
    return filter(sasStatus.methods, (method: MethodToSync) => {
      return method.isInGlobalSync === isGlobalSync
    })
  }
  return []
}
