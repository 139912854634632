import { Switch } from '@mui/material'
import { get } from 'lodash'
import React, { forwardRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { ControlledSwitchProps } from './types'

const ControlledSwitch = forwardRef((props: ControlledSwitchProps, ref) => {
  const {
    name = 'NOT_PROVIDED',
    control: controlFromProps,
    disabled,
    ...rest
  } = props
  const formContext = useFormContext()
  const controlFromContext = get(formContext, 'control')
  const controller = useController({
    name,
    control: controlFromProps || controlFromContext,
  })
  const { field } = controller

  if (field.value === undefined) {
    console.error(
      `[FTEL-ERROR] : Le composant ControlledSwitch '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
    )
  }

  const defaultValue: boolean = false

  return (
    <Switch
      size="small"
      checked={field.value ?? defaultValue}
      ref={ref}
      disabled={disabled}
      {...field}
      {...rest}
      value={field.value ?? defaultValue}
    />
  )
})

export default ControlledSwitch
