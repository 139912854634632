import { Add } from '@mui/icons-material'
import { Box } from '@mui/material'
import LinkButton from 'components/atoms/LinkButton'
import { USERS_CREATE_PATH } from 'enums/paths'
import React from 'react'

function Toolbar() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <LinkButton
        to={USERS_CREATE_PATH}
        variant="contained"
        sx={{
          minWidth: (theme) => theme.spacing(5.5),
          height: (theme) => theme.spacing(5.5),
          width: (theme) => theme.spacing(5.5),
          bgcolor: 'secondary.main',
          borderRadius: 1,
          color: 'common.black',
          '&:hover': {
            bgcolor: 'secondary.main',
          },
        }}
      >
        <Add />
      </LinkButton>
    </Box>
  )
}

export default Toolbar
