import { Box, Typography } from '@mui/material'
import React from 'react'
import { FONT_LG, FONT_MD, FONT_XS } from './enum'

function FormTypoReadOnly({ value }) {
  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        pl: 1,
        pr: 1,
        mr: 1,
        borderRadius: 1,
        height: (theme) => theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="caption"
        noWrap
        sx={{
          fontSize: {
            xs: FONT_XS,
            md: FONT_MD,
            lg: FONT_LG,
          },
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default FormTypoReadOnly
