import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import EmailIcon from '@mui/icons-material/Email'
import GridViewIcon from '@mui/icons-material/GridView'
import GroupIcon from '@mui/icons-material/Group'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import BadgeIcon from '@mui/icons-material/Badge'
import LockIcon from '@mui/icons-material/Lock'
import StorageIcon from '@mui/icons-material/Storage'
import CategoryIcon from '@mui/icons-material/Category'
import {
  APPLICATIONS_PATH,
  APP_PARAMS_PATH,
  EMAIL_MANAGEMENT_PATH,
  HOME_PATH,
  OFS_PATH,
  USERS_PATH,
  FILES_PATH,
  PUBLIC_TYPES_PATH,
  AUTHORIZED_TOKENS_PATH,
  CATEGORIES_PATH,
} from 'enums/paths'

export const LINK = 'link'
export const DIVIDER = 'divider'

export const getMenuItems = (t) => [
  {
    key: 'home',
    type: LINK,
    title: t('back-to-app'),
    icon: ArrowBackIcon,
    to: HOME_PATH,
    ignoreSelected: true,
  },
  {
    key: 'divider0',
    type: DIVIDER,
  },
  {
    key: 'public-types',
    type: LINK,
    title: t('public-types'),
    icon: BadgeIcon,
    to: PUBLIC_TYPES_PATH,
  },
  {
    key: 'apps',
    type: LINK,
    title: t('applications-management'),
    icon: PhoneIphoneIcon,
    to: APPLICATIONS_PATH,
  },
  {
    key: 'ofs',
    type: LINK,
    title: t('ofs-management'),
    icon: GridViewIcon,
    to: OFS_PATH,
  },
  {
    key: 'authorized-tokens',
    type: LINK,
    title: t('authorized-tokens'),
    icon: LockIcon,
    to: AUTHORIZED_TOKENS_PATH,
  },
  {
    key: 'files',
    type: LINK,
    title: t('files'),
    icon: StorageIcon,
    to: FILES_PATH,
  },
  {
    key: 'categories',
    type: LINK,
    title: t('categories'),
    icon: CategoryIcon,
    to: CATEGORIES_PATH,
  },
  {
    key: 'mails',
    type: LINK,
    title: t('mail-management'),
    icon: EmailIcon,
    to: EMAIL_MANAGEMENT_PATH,
  },
  {
    key: 'users',
    type: LINK,
    title: t('users-management'),
    icon: GroupIcon,
    to: USERS_PATH,
  },
  {
    key: 'app-params',
    type: LINK,
    title: t('app-params'),
    icon: DisplaySettingsIcon,
    to: APP_PARAMS_PATH,
  },
]
