import axios, { AxiosResponse } from 'axios'
import LoadingScreen from 'components/atoms/LoadingScreen'
import {
  clearJwtToken,
  defaultFetcher,
  getJwtToken,
  setJwtToken,
} from 'domains/helpers'
import { useAuthenticateMutation } from 'domains/users/mutations'
import { REVOKE_TOKEN, USER_ME } from 'domains/users/templates'
import { User } from 'domains/users/types'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { SUCCESS, useSnackbar } from './SnackbarContext'

type AuthContextType = {
  user: User
  loading: boolean
  login: (values: any) => Promise<void>
  logout: () => Promise<AxiosResponse<any, any>>
}

const AuthContext = createContext<AuthContextType>(undefined)

function AuthProvider({ children }) {
  const { t } = useTranslation()
  const [user, setUser] = useState<User>(null)
  const [loading, setLoading] = useState(true)
  const authenticate = useAuthenticateMutation()
  const { popSnackbar } = useSnackbar()

  useEffect(() => {
    if (!getJwtToken()) {
      setUser(null)
      setLoading(false)
    } else if (!user && !!getJwtToken()) {
      setLoading(true)
      defaultFetcher('GET', USER_ME)
        .then((userResponse: User) => {
          setUser(userResponse)
        })
        .catch(() => setUser(null))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [user])

  const login = useCallback(
    (values) => {
      return authenticate
        .mutateAsync(
          { data: values },
          {
            onSuccess: () => {
              popSnackbar(t('login-success'), SUCCESS)
            },
          },
        )
        .then(({ token, user: userResponse }) => {
          setUser(userResponse)
          setJwtToken(token)
          defaultFetcher('GET', USER_ME)
            .then((secondUserResponse) => setUser(secondUserResponse))
            .catch(() => setUser(null))
            .finally(() => setLoading(false))
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authenticate],
  )

  const logout = useCallback(() => {
    return axios
      .post(REVOKE_TOKEN, null, {
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
      })
      .finally(() => {
        clearJwtToken()
        setUser(null)
      })
  }, [])

  const values = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
    }),
    [login, logout, user, loading],
  )

  if (loading) {
    return <LoadingScreen />
  }
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
