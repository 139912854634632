import { Refresh } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledDatePicker from 'components/molecules/ControlledDatePicker'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { LOGTYPES } from 'domains/logs/enums'
import { map } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FIELD_REFRESH_SIZE } from './enum'

function FullScreenFilterLogsForm({
  open,
  onSubmit,
  form,
  resetMethod = null,
}) {
  const { t } = useTranslation()

  const dateStartValue = form.watch('dateStart')
  const dateEndValue = form.watch('dateEnd')

  return (
    <Box
      sx={{
        display: `${open ? 'block' : 'none'}`,
        position: 'absolute',
        padding: 0,
        border: 10,
        borderRadius: 2,
        alignItems: 'center',
        justifyContent: 'flex-end',
        right: (theme) => theme.spacing(2),
        top: (theme) => theme.spacing(5),
      }}
    >
      <FormProvider
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...form}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            gap: 1.25,
            backgroundColor: (theme) => theme.palette.primary.light,
          }}
          component="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Box
              sx={{
                pt: 1,
                width: (theme) => theme.spacing(23),
              }}
            >
              <ControlledDatePicker
                name="dateStart"
                datetime
                label={t('start-date')}
                fullWidth
                size="small"
                sx={{
                  '& .MuiInputLabel-root, .MuiInputBase-root': {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: (theme) => theme.typography.chip.fontSize,
                    height: '100%',
                  },
                }}
                InputProps={{ inputProps: { max: dateEndValue } }}
              />
            </Box>
            <Box
              sx={{
                pt: 1,
                width: (theme) => theme.spacing(23),
              }}
            >
              <ControlledDatePicker
                name="dateEnd"
                datetime
                label={t('end-date')}
                fullWidth
                size="small"
                sx={{
                  '& .MuiInputLabel-root, .MuiInputBase-root': {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: (theme) => theme.typography.chip.fontSize,
                    height: '100%',
                  },
                }}
                InputProps={{ inputProps: { min: dateStartValue } }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                pt: 1,
                overflowX: 'hidden',
                '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root':
                  {
                    flexWrap: 'nowrap',
                    overflowX: 'hidden',
                  },
              }}
            >
              <ControlledAutocomplete
                name="types"
                sx={{
                  flex: 1,
                  '& .MuiInputBase-root': {
                    height: (theme) => theme.spacing(4.6),
                    maxWidth: (theme) => theme.spacing(65),
                    overflowY: 'hidden',
                    fontSize: (theme) => theme.typography.chip.fontSize,
                  },
                  '& .MuiChip-label': {
                    fontSize: (theme) => theme.typography.chip.fontSize,
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  },
                  '& .MuiAutocomplete-root, .MuiAutocomplete-tag': {
                    margin: '2px',
                  },
                }}
                TextFieldProps={{
                  label: t('log-types'),
                  size: 'small',
                }}
                loading={false}
                options={map(LOGTYPES, (value) => value)}
                multiple
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                disableCloseOnSelect
                disableClearable
                ChipProps={{ size: 'small' }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <ControlledTextField
              name="method"
              label={t('method')}
              size="small"
              sx={{
                flex: 4,
                '& MuiFormControl-root-MuiTextField-root, .MuiOutlinedInput-input, .MuiInputLabel-root':
                  {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  },
              }}
              InputProps={
                resetMethod && {
                  endAdornment: (
                    <Refresh
                      onClick={resetMethod}
                      sx={{
                        cursor: 'pointer',
                        color: 'primary.main',
                        width: FIELD_REFRESH_SIZE,
                        height: FIELD_REFRESH_SIZE,
                      }}
                    />
                  ),
                }
              }
            />
            <ControlledTextField
              name="description"
              label={t('description')}
              size="small"
              sx={{
                flex: 5,
                '& MuiFormControl-root-MuiTextField-root, .MuiOutlinedInput-input, .MuiInputLabel-root':
                  {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  },
              }}
            />
            <Button
              size="small"
              sx={{
                bgcolor: 'secondary.main',
                borderRadius: 1,
                color: 'common.black',
                boxShadow: 'none',
                transition: 'border 0s',
                '&:hover': {
                  bgcolor: 'secondary.main',
                  boxShadow: 'none',
                  borderColor: 'primary.main',
                },
                mt: 0.1,
                height: (theme) => theme.spacing(4.6),
              }}
              type="submit"
            >
              {t('apply')}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  )
}

export default FullScreenFilterLogsForm
