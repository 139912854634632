import axios, { AxiosRequestConfig } from 'axios'
import { assign, compact, isEmpty } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import urlcat from 'urlcat'

export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

export const getJwtToken = () => {
  return localStorage.getItem('token')
}

export const setJwtToken = (value: string) => {
  return localStorage.setItem('token', value)
}

export const clearJwtToken = () => {
  return localStorage.removeItem('token')
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
})

export const axiosSasInstance = axios.create({
  timeout: 20000,
})

export const defaultFetcher = (
  method: HttpMethod,
  url: string,
  baseUrl?: string,
  data?: any,
  axiosOptions?: AxiosRequestConfig,
  instance = axiosInstance,
) => {
  const token = localStorage.getItem('token')
  return instance(
    assign(
      {
        method,
        url,
      },
      token
        ? {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {},
      { data },
      axiosOptions,
      !isEmpty(baseUrl) ? { baseURL: `https://${baseUrl}/` } : {},
    ),
  ).then((res) => {
    return res ? res.data : null
  })
}

export function useQueryWrapper<T>(
  method,
  urlTemplate,
  params = {},
  options = {},
  baseUrl?: string,
  fetcher = defaultFetcher,
) {
  let url = urlTemplate

  try {
    url = urlcat(urlTemplate, params)
    // eslint-disable-next-line no-empty
  } catch (error) {
    url = '[FTEL-ERROR] : Url malformée, vérifiez les paramètres de la query'
  }

  const queryKey = compact([urlTemplate, params])
  const query = useQuery<T>(
    queryKey,
    () => fetcher(method, url, baseUrl),
    options,
  )

  return { ...query, queryKey }
}

export const sasFetcher = (
  method: HttpMethod,
  url: string,
  baseUrl?: string,
  data?: any,
  axiosOptions?: AxiosRequestConfig,
) =>
  defaultFetcher(
    method,
    url,
    baseUrl,
    data,
    assign({
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_FTEL}`,
      },
      axiosOptions,
    }),
    axiosSasInstance,
  )

export function useSasQueryWrapper<T>(
  method,
  urlTemplate,
  params = {},
  options = {},
  baseUrl: string,
) {
  return useQueryWrapper<T>(
    method,
    urlTemplate,
    params,
    options,
    baseUrl,
    sasFetcher,
  )
}

type MutationFunctionVariables = { data?: object; [key: string]: any }

export const useMutationWrapper = (
  method: HttpMethod,
  urlTemplate: string,
  options,
  axiosOptions?: AxiosRequestConfig,
  baseUrl?: string,
  fetcher = defaultFetcher,
) => {
  return useMutation((variables: MutationFunctionVariables) => {
    const objectVariables = variables || {}
    const { data, ...params } = objectVariables
    return fetcher(
      method,
      urlcat(urlTemplate, params),
      baseUrl,
      data,
      axiosOptions,
    )
  }, options)
}

export const useSasMutationWrapper = (
  method: HttpMethod,
  urlTemplate: string,
  options,
  baseUrl: string,
  axiosOptions?: AxiosRequestConfig,
) => {
  return useMutationWrapper(
    method,
    urlTemplate,
    options,
    axiosOptions,
    baseUrl,
    sasFetcher,
  )
}

export function fakeFetcher<T>(data) {
  return new Promise<T>((resolve) => {
    setTimeout(() => resolve(data), 800)
  })
}

export const useFakeMutation = (fakeData, options) => {
  return useMutation(() => fakeFetcher(fakeData), options)
}

export function useFakeQuery<T>(queryKey, fakeData, options) {
  return useQuery<T>(queryKey, () => fakeFetcher<T>(fakeData), options)
}
