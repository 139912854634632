import { PaletteOptions } from '@mui/material'

export const greyDark = '#4B4B4B'
export const greyLight = '#DCDCDC'
export const white = '#FFFFFF'
export const black = '#0F0F0F'
const greyMedium = '#8D8D8D'
export const yellowSand = '#FADA59'
const red = '#F27341'
const darkRed = '#FD6E61'
const orangeLight = '#F9B859'
const orange = '#ea8d12'
const green = '#4CAF50'
const purple = '#c380cd'

const palette: PaletteOptions = {
  primary: { light: greyLight, main: greyMedium, dark: greyDark },
  secondary: { main: yellowSand },
  error: { main: red, dark: darkRed, light: orange },
  success: { main: green },
  warning: { main: orangeLight },
  background: {
    default: greyLight,
  },
  logInvalidEndpoint: { main: purple },
  common: {
    black,
    white,
  },
}

export default palette

// https://mui.com/material-ui/customization/palette/
