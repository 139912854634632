import Box from '@mui/material/Box'
import * as React from 'react'
import CCCABTPLogo from 'assets/images/cca-btp.png'
import Titles from './components/Titles'
import Logo from './components/Logo'
import Actions from './components/Actions'

function Header({ titles = [] }) {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        bgcolor: 'primary.light',
        color: 'common.black',
        borderRadius: 1,
        p: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Logo />
        <Titles titles={titles} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: (theme) => theme.spacing(8.5),
            '& img': { width: '100%' },
          }}
        >
          <img src={CCCABTPLogo} alt="ccca-btp-logo" />
        </Box>
        <Actions />
      </Box>
    </Box>
  )
}

export default Header
