import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledRadioGroup from 'components/molecules/ControlledRadioGroup'
import { useMethodsQuery } from 'domains/methods/queries'
import { APIMethod } from 'domains/methods/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FrequenciesArray } from './enums'
import { formatMethods } from './formatters'

function MethodsComponent({ indexMethod, removeMethod, selectedMethods }) {
  const { t } = useTranslation()
  const commonSx = {
    mr: 1,
  }
  const { data: methodsEntites, isLoading } = useMethodsQuery()
  const [methodsFormatted, setMethodsFormatted] = useState([])

  useEffect(() => {
    setMethodsFormatted(formatMethods(methodsEntites))
  }, [methodsEntites])

  const disabledMethodsPredicate = (m: APIMethod) =>
    selectedMethods?.some(
      (selectedMethod) => selectedMethod?.method?.id === m.id,
    )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 1,
        mr: 2,
      }}
    >
      <Box sx={{ flex: 10, ...commonSx }}>
        <ControlledAutocomplete
          name={`methods[${indexMethod}].method`}
          TextFieldProps={{ size: 'small', label: t('method') }}
          loading={isLoading}
          options={methodsFormatted}
          getOptionDisabled={disabledMethodsPredicate}
        />
      </Box>
      <Box sx={{ flex: 4, ml: 2, ...commonSx }}>
        <Box sx={{ textAlign: 'center' }}>
          <ControlledRadioGroup
            name={`methods[${indexMethod}].frequency`}
            options={FrequenciesArray}
            label=""
            direction="row"
            radioProps={{ color: 'success', size: 'small' }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 0.5,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <IconButton
          size="small"
          sx={{
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
          }}
          onClick={() => removeMethod(indexMethod)}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default MethodsComponent
