import { Application } from 'domains/application/types'
import { Of } from 'domains/of/types'
import { File } from 'domains/files/types'
import { Role } from 'domains/roles/types'
import { t } from 'i18next'
import { cloneDeep, isEmpty } from 'lodash'

export function formatRoles(roles: Role[]) {
  const rolesFormattedTemp = []
  if (!isEmpty(roles)) {
    roles.map(({ id: idRole, key }) =>
      rolesFormattedTemp.push({ label: t(key), id: idRole }),
    )
  }
  return rolesFormattedTemp
}

export function formatApplications(applications: Application[]) {
  const applicationsFormattedTemp = []
  if (!isEmpty(applications)) {
    applications.map(({ id, name }) =>
      applicationsFormattedTemp.push({ label: name, id }),
    )
  }
  return applicationsFormattedTemp
}

export function formatOfs(ofs: Of[]) {
  const ofsFormattedTemp = []
  if (!isEmpty(ofs)) {
    ofs.map(({ id, name }) => ofsFormattedTemp.push({ label: name, id }))
  }
  return ofsFormattedTemp
}

export function formatFiles(files: File[]) {
  const filesFormattedTemp = []
  if (!isEmpty(files)) {
    files.map(({ id, url }) => filesFormattedTemp.push({ label: url, id }))
  }
  return filesFormattedTemp
}

export function formatUser(user) {
  const userData: any = cloneDeep(user)
  userData.role = user.role
    ? { label: t(user.role.key), id: user.role.id }
    : null
  userData.application = user.application
    ? { label: user.application.name, id: user.application.id }
    : null
  userData.of = user.of ? { label: user.of.name, id: user.of.id } : null
  return userData
}
