import { Box, Button, Typography } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAddNewUserForm from './form'

function NewUserForm({ addUser, role }) {
  const { t } = useTranslation()
  const newUserForm = useAddNewUserForm(role)

  const handleAddAdmin = (values) => {
    addUser({ ...values, receiveMail: false })
    newUserForm.reset({ role }, { keepDefaultValues: true })
  }

  useEffect(() => {
    newUserForm.setValue('role', role)
  }, [role, newUserForm])

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...newUserForm}>
      <Box sx={{ mb: 2 }}>
        <ControlledTextField
          name="firstname"
          label={t('firstname')}
          fullWidth
          size="small"
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <ControlledTextField
          name="lastname"
          label={t('name')}
          fullWidth
          size="small"
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <ControlledTextField
          name="email"
          label={t('email')}
          fullWidth
          size="small"
        />
      </Box>
      <ControlledTextField
        name="phone"
        label={t('phone')}
        fullWidth
        size="small"
      />
      <Button
        color="secondary"
        sx={{ mt: 2 }}
        fullWidth
        onClick={newUserForm.handleSubmit(handleAddAdmin)}
      >
        <Typography variant="button">{t('add')}</Typography>
      </Button>
    </FormProvider>
  )
}

export default NewUserForm
