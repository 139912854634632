import PublicTypesManager from 'components/organisms/PublicTypesManager'
import Header from 'components/templates/AdminLayout/components/Header'
import { PUBLIC_TYPES_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'

function PublicTypes() {
  const { t } = useTranslation()
  return (
    <>
      <Header
        defaultTitles={[
          { label: t('admin') },
          { label: t('public-types'), to: PUBLIC_TYPES_PATH },
        ]}
      />
      <PublicTypesManager />
    </>
  )
}

export default PublicTypes
