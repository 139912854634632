import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { useGetPublicTypesQuery } from 'domains/publicTypes/queries'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PublicTypesComponent from '../PublicTypesComponent'

function PublicTypesForm({ applicationPublicTypes, variant }) {
  const { t } = useTranslation()
  const form = useFormContext()
  const { data: publicTypes = [], isLoading } = useGetPublicTypesQuery()
  const [tab, setTab] = useState([])

  useEffect(() => {
    if (variant === 'creation') {
      const newTab = []
      if (!isLoading && !isEmpty(publicTypes)) {
        publicTypes.forEach((type) => {
          newTab.push({
            publicType: { id: type.id, name: type.name },
            isEditableOnOfs: false,
            isVisibleInPortal: false,
          })
        })
      }
      setTab(newTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicTypes])

  useEffect(() => {
    if (variant === 'creation') form.setValue('applicationPublicTypes', tab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 1,
        mr: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small" sx={{ fontWeight: 'bold' }}>
              {t('public-types')}
            </TableCell>
            <TableCell size="small" sx={{ fontWeight: 'bold' }} align="right">
              {t('portal-visibility-publicType')}
            </TableCell>
            <TableCell size="small" sx={{ fontWeight: 'bold' }} align="right">
              {t('OF-customisation-publicType')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(applicationPublicTypes) &&
            !isLoading &&
            applicationPublicTypes.map((type, index) => (
              <PublicTypesComponent
                indexAppPublicType={index}
                appPublicType={type}
                key={type.publicType?.id}
              />
            ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default PublicTypesForm
