import React from 'react'
import { Dialog } from '@mui/material'
import { DialogEscapeCloseProps } from './type'

function DialogEscapeClose({
  children,
  onClose,
  open = false,
  ...props
}: DialogEscapeCloseProps) {
  const handleCloseDialog = (event) => {
    if (event?.code === 'Escape') {
      onClose()
    }
  }
  return (
    <Dialog
      open={open}
      onClose={(event) => handleCloseDialog(event)}
      fullWidth
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Dialog>
  )
}

export default DialogEscapeClose
