import { Box, Dialog, DialogContent } from '@mui/material'
import { useLogFilters } from 'components/organisms/TrainingOrganization/contexts/LogFiltersContext'
import { LOGLINE_PER_PAGE } from 'components/organisms/TrainingOrganization/enums'
import { useLogTabQuery } from 'domains/logs/queries'
import React, { useEffect, useState } from 'react'
import FullscreenHeaderButtons from '../FullscreenHeaderButtons'
import LogTabTitleWithIcon from '../LogTabTitleWithIcon'
import LogsTab from '../LogsTab'
import StandardHeaderButtons from '../StandardHeaderButtons'
import { getLogsBorderColor } from '../helpers'
import { getLogsTemplate } from './helper'

function LogsFetcher({ item, ofId, variant }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(LOGLINE_PER_PAGE[1])
  const [totalLogs, setTotalLogs] = useState(0)
  const {
    filterType,
    filterStartDate,
    filterEndDate,
    filterMethod,
    filterDescription,
  } = useLogFilters()

  const template = getLogsTemplate(variant)

  const {
    data,
    refetch: refetchLogs,
    isFetching,
  } = useLogTabQuery(
    template,
    {
      ofId,
      applicationId: item.id,
      pageIndex: page,
      rowsPerPage,
      dateStart: filterStartDate,
      dateEnd: filterEndDate,
      types: filterType,
      method: filterMethod,
      description: filterDescription,
    },
    {
      enabled: !!ofId,
    },
  )

  useEffect(() => {
    refetchLogs()
    setPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, filterStartDate, filterEndDate])

  useEffect(() => {
    if (!isFetching) {
      setTotalLogs(data?.totalLogs ?? 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  const [openDialogFullScreen, setOpenDialogFullScreen] = useState(false)

  return (
    <LogsTab
      title={<LogTabTitleWithIcon item={item} variant={variant} />}
      data={data}
      isFetching={isFetching}
      customHeaderButtons={StandardHeaderButtons({
        setOpenDialogFullScreen,
        refetchLogs,
        isFetching,
      })}
      sx={{
        height: (theme) => theme.spacing(60),
        border: (theme) => `4px solid ${getLogsBorderColor(theme, item)}`,
      }}
      methodFlexValue={5}
      descriptionFlexValue={9}
      totalLogs={totalLogs}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    >
      <Dialog
        open={openDialogFullScreen}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            height: '100%',
            borderRadius: 5,
          },
        }}
      >
        <DialogContent
          sx={{
            p: '0',
            overflow: 'hidden',
          }}
        >
          <LogsTab
            title={<LogTabTitleWithIcon item={item} variant={variant} />}
            data={data}
            isFetching={isFetching}
            customHeaderButtons={FullscreenHeaderButtons({
              setOpenDialogFullScreen,
              refetchLogs,
              isFetching,
            })}
            sx={{
              height: '100%',
            }}
            methodFlexValue={1}
            descriptionFlexValue={3}
            totalLogs={totalLogs}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          >
            <Box />
          </LogsTab>
        </DialogContent>
      </Dialog>
    </LogsTab>
  )
}

export default LogsFetcher
