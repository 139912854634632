export const OFS = '/ofs'
export const OFS_HOME = '/ofs/home'
export const OF = '/ofs/:id'
export const OF_GENERATE_TOKEN = '/ofs/generate-token'
export const OF_UPDATE_AVAILABILITY = '/ofs/update-availability/:id'
export const OF_DEPLOY = '/ofs/:id/deploy'
export const OF_SEND_FTP_INFO_MAIL = '/ofs/:id/send-ftp-info-mail'
export const OF_FILES = '/ofs/:id/files'
export const OF_USER_URL_FUSION_FIELDS = '/ofs/users/url-fusion-fields'
export const OF_USER_MAPPING_FIELDS = '/ofs/users/mapping-fields'
export const OF_EXCEL_EXPORT = '/ofs/export-excel'
