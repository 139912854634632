import { Box, Typography } from '@mui/material'
import Header from 'components/templates/AdminLayout/components/Header'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Internationalization() {
  const { t } = useTranslation()
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Header defaultTitles={[{ label: t('admin') }, { label: t('i18n') }]} />
      </Box>
      <Typography variant="h4">{t('i18n')}</Typography>
    </>
  )
}

export default Internationalization
