import { RoleEnum } from 'domains/roles/enums'

export function isAdminPlus(roleKey) {
  return [RoleEnum.SUPERVISOR.key, RoleEnum.ADMIN.key].includes(roleKey)
}

export function isSupervisor(roleKey) {
  return RoleEnum.SUPERVISOR.key === roleKey
}

export function isAdminAppExclusive(roleKey) {
  return RoleEnum.ADMIN_APP.key === roleKey
}

export function isAdminOFA(roleKey) {
  return [
    RoleEnum.SUPERVISOR.key,
    RoleEnum.ADMIN.key,
    RoleEnum.ADMIN_OFA.key,
  ].includes(roleKey)
}
