import { Close, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { DataGrid, GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import { useAuth } from 'contexts/AuthContext'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { PUBLIC_TYPES_KEYS } from 'domains/publicTypes/enums'
import { useDeletePublicTypesMutation } from 'domains/publicTypes/mutations'
import { useGetPublicTypesQuery } from 'domains/publicTypes/queries'
import { PUBLIC_TYPES } from 'domains/publicTypes/templates'
import { isAdminPlus } from 'helpers/role'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Toolbar from './components/Toolbar'
import { sortModelType } from './sort'
import { GridColType } from './types'
import PublicTypeEditForm from './components/Edit'

function PublicTypesManager() {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const { popSnackbar } = useSnackbar()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const [idForm, setIdForm] = useState(null)
  const { data: publicTypes = [], isLoading } = useGetPublicTypesQuery()
  const deletePublicType = useDeletePublicTypesMutation()
  const queryClient = useQueryClient()

  const handleDelete = async (id, hasConnections) => {
    await confirm(
      t('irreversible-action'),
      hasConnections
        ? t('public-type-delete-confirmation-warning')
        : t('public-type-delete-confirmation'),
    )
    await deletePublicType.mutateAsync({ id })
    popSnackbar(t('public-type-delete-success'), SUCCESS)
    queryClient.invalidateQueries(PUBLIC_TYPES)
  }

  const [sortModel, setSortModel] = React.useState<GridSortModel>(sortModelType)

  const handleOpen = (id) => {
    setOpen(true)
    setIdForm(id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const editAction = ({ row: { id } }) => (
    <GridActionsCellItem
      icon={<Edit />}
      onClick={() => handleOpen(id)}
      label={t('edit')}
    />
  )

  const deleteAction = ({ row: { id, hasConnections } }) => (
    <GridActionsCellItem
      icon={<Delete />}
      onClick={() => handleDelete(id, hasConnections)}
      label={t('delete')}
    />
  )

  const columns: GridColType[] = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'code',
      headerName: t('code'),
      flex: 1,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('actions'),
      width: 100,
      align: 'left',
      getActions: (params) =>
        user.role &&
        isAdminPlus(user.role.key) &&
        !PUBLIC_TYPES_KEYS.includes(params.row.key)
          ? [editAction(params), deleteAction(params)]
          : [editAction(params)],
    },
  ]

  return (
    <>
      <Box
        sx={{
          mt: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DataGrid
          sx={{
            flex: 1,
            borderRadius: 1,
            p: 2,
          }}
          loading={isLoading}
          columns={columns}
          rows={publicTypes}
          components={{ Toolbar }}
          checkboxSelection={false}
          disableColumnMenu
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('edit-public-type')}
            <IconButton size="small" onClick={() => handleClose()}>
              <Close sx={{ color: 'common.black' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            '&.MuiDialogContent-root': {
              paddingBottom: (theme) => theme.spacing(2.5),
              paddingX: 0,
            },
          }}
        >
          <PublicTypeEditForm id={idForm} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PublicTypesManager
