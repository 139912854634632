import { SaveAsRounded } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import Header from 'components/templates/AdminLayout/components/Header'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useApplicationsQuery } from 'domains/application/queries'
import { useOfsQuery } from 'domains/of/queries'
import { useRolesQuery } from 'domains/roles/queries'
import { useCreateUserMutation } from 'domains/users/mutations'
import { USERS } from 'domains/users/templates'
import { USERS_PATH } from 'enums/paths'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  formatApplications,
  formatOfs,
  formatRoles,
} from '../UsersManager/helper'
import useCreateUserForm from './form'
import { APP_ROLES_LABELS, OF_ROLES_LABELS } from './helper'

export const CREATE = 'create'

function UsersForm() {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const form = useCreateUserForm()
  const { data: roles, isLoading: rolesIsLoading } = useRolesQuery()
  const { data: applications, isLoading: applicationIsLoading } =
    useApplicationsQuery()
  const { data: ofs, isLoading: ofsIsLoading } = useOfsQuery()
  const [rolesFormatted, setRolesFormatted] = useState([])
  const [applicationsFormatted, setApplicationsFormatted] = useState([])
  const [ofsFormatted, setOfsFormatted] = useState([])
  const createUser = useCreateUserMutation()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const roleField = form.watch('role')

  useEffect(() => {
    setRolesFormatted(formatRoles(roles))
  }, [roles])

  useEffect(() => {
    setApplicationsFormatted(formatApplications(applications))
  }, [applications])

  useEffect(() => {
    setOfsFormatted(formatOfs(ofs))
  }, [ofs])

  const onSubmit = async (values) => {
    const data = cloneDeep(values)
    data.application = APP_ROLES_LABELS.includes(values.role.label)
      ? values.application
      : null
    data.of = OF_ROLES_LABELS.includes(values.role.label) ? values.of : null
    createUser
      .mutateAsync(
        {
          data: values,
        },
        {
          onSuccess: () => {
            popSnackbar(t('user-create-success'), SUCCESS)
            queryClient.invalidateQueries(USERS)
            setIsDirty(false)
          },
        },
      )
      .then(() => navigate(USERS_PATH))
  }

  return (
    <Box>
      <Header
        defaultTitles={[
          { label: t('admin') },
          {
            label: t('users'),
            to: USERS_PATH,
            confirm: {
              title: t('back-to-list-confirmation'),
              message: t('changes-shall-disappear'),
              enable: form.formState.isDirty,
            },
          },
          { label: t('create-title') },
        ]}
      />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          <Typography variant="h3" mb={2}>
            {t('user-create')}
          </Typography>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="firstname"
                  label={t('firstname')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="lastname"
                  label={t('name')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="email"
                  label={t('email')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="phone"
                  label={t('phone')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledAutocomplete
                  name="role"
                  TextFieldProps={{ size: 'small', label: t('role') }}
                  loading={rolesIsLoading}
                  options={rolesFormatted}
                />
              </Grid>
              {roleField && OF_ROLES_LABELS.includes(roleField.label) && (
                <Grid item xs={12} md={6}>
                  <ControlledAutocomplete
                    name="of"
                    TextFieldProps={{ size: 'small', label: t('of') }}
                    loading={ofsIsLoading}
                    options={ofsFormatted}
                  />
                </Grid>
              )}
              {roleField && APP_ROLES_LABELS.includes(roleField.label) && (
                <Grid item xs={12} md={6}>
                  <ControlledAutocomplete
                    name="application"
                    TextFieldProps={{
                      size: 'small',
                      label: t('application'),
                    }}
                    loading={applicationIsLoading}
                    options={applicationsFormatted}
                  />
                </Grid>
              )}

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveAsRounded />}
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default UsersForm
