import { Box, Button, Paper } from '@mui/material'
import { ReactComponent as LoginImage } from 'assets/images/login.svg'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useAuth } from 'contexts/AuthContext'
import { HOME_PATH } from 'enums/paths'
import addErrorsToForm from 'helpers/errors'
import { get } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useLoginForm from './form'

function LoginForm() {
  const { t } = useTranslation()
  const form = useLoginForm()
  const { login } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const from = get(location, 'state.from.pathname', HOME_PATH)

  const onSubmit = async (values) => {
    return login(values)
      .then(() => navigate(from, { replace: true }))
      .catch((error) => addErrorsToForm(error.response.data, form))
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Paper
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 4,
        }}
      >
        <Box
          sx={{
            '& svg': {
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
            },
            textAlign: 'center',
          }}
        >
          <LoginImage />
        </Box>

        <ControlledTextField
          name="usernameOrEmail"
          label={t('username')}
          size="small"
        />
        <ControlledTextField
          name="password"
          type="password"
          label={t('password')}
          size="small"
        />
        <Button color="primary" type="submit">
          {t('login')}
        </Button>
      </Paper>
    </FormProvider>
  )
}

export default LoginForm
