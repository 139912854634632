import {
  CheckCircleOutline,
  HighlightOff,
  HourglassBottomTwoTone,
  RemoveCircleOutlineRounded,
  WarningAmberOutlined,
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { LogsBlockVariant } from '../enums'

function LogTabTitleWithIcon({ item, variant }) {
  if (isEmpty(item?.name)) return <Box sx={{ flex: 1 }} />

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
      }}
    >
      <Typography display="inline" variant="h2">
        {item.name.toUpperCase()}
      </Typography>
      {variant !== LogsBlockVariant.GENERAL &&
        !item?.hasAnyLogs &&
        !item?.isBusy && (
          <RemoveCircleOutlineRounded
            sx={{
              ml: 1,
              color: 'primary.main',
            }}
          />
        )}
      {variant === LogsBlockVariant.VENDOR && item?.isBusy && (
        <HourglassBottomTwoTone
          sx={{
            ml: 1,
            color: 'secondary.main',
            animation: 'spin 3s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(180deg)',
              },
            },
          }}
        />
      )}
      {variant !== LogsBlockVariant.GENERAL &&
        !item?.isInError &&
        item?.hasAnyLogs &&
        !item?.isBusy &&
        !item?.isInWarning && (
          <CheckCircleOutline
            sx={{
              ml: 1,
              color: 'success.main',
            }}
          />
        )}
      {variant !== LogsBlockVariant.GENERAL &&
        !item?.isInError &&
        item?.hasAnyLogs &&
        !item?.isBusy &&
        item?.isInWarning && (
          <WarningAmberOutlined
            sx={{
              ml: 1,
              color: 'error.light',
            }}
          />
        )}
      {variant !== LogsBlockVariant.GENERAL &&
        item?.isInError &&
        item?.hasAnyLogs &&
        !item?.isBusy && (
          <HighlightOff
            sx={{
              ml: 1,
              color: 'error.main',
            }}
          />
        )}
    </Box>
  )
}

export default LogTabTitleWithIcon
