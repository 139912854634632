import { Box, Button, Typography, alpha } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Toolbar from '@mui/material/Toolbar'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { DIVIDER, LINK, getMenuItems } from 'config/drawers/admin'
import { useConfirm } from 'contexts/ConfirmContext'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { APPLICATIONS_PATH } from 'enums/paths'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function MenuItems() {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm } = useConfirm()

  const { isDirty, setIsDirty } = useGlobalFormState()

  const handleNavigate = (to) => {
    if (isDirty) {
      return confirm(
        t('changes-shall-disappear'),
        t('cancel-form-confirmation'),
      )
        .then(() => {
          setIsDirty(false)
          return navigate(to)
        })
        .catch(() => null)
    }
    return navigate(to)
  }

  return (
    <div>
      <Toolbar>
        <Link
          to={APPLICATIONS_PATH}
          style={{ textDecoration: 'inherit', color: 'inherit' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 150,
            }}
          >
            <Box sx={{ width: 60, '& svg': { width: '100%' } }}>
              <Logo />
            </Box>
            <Box ml={2}>
              <Typography variant="overline">{t('sas')}</Typography>
            </Box>
          </Box>
        </Link>
      </Toolbar>
      <List>
        <Divider />
        {getMenuItems(t).map(
          ({
            key,
            type,
            title,
            icon: Icon,
            to = '#',
            ignoreSelected = false,
          }) => {
            const selected = !ignoreSelected && pathname.includes(to)
            switch (type) {
              case LINK:
                return (
                  <Button
                    key={key}
                    startIcon={<Icon />}
                    disableElevation
                    disableRipple
                    fullWidth
                    sx={{
                      backgroundColor: (theme) =>
                        selected
                          ? alpha(theme.palette.common.white, 0.1)
                          : 'transparent',
                      justifyContent: 'left',
                      textTransform: 'none',
                      color: 'primary.light',
                      pl: 2.5,
                      '&:hover': {
                        backgroundColor: (theme) =>
                          selected
                            ? alpha(theme.palette.common.white, 0.15)
                            : alpha(theme.palette.common.black, 0.2),
                      },
                    }}
                    onClick={() => handleNavigate(to)}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: 'left',
                        pl: 2,
                        py: 0.5,
                      }}
                    >
                      {title}
                    </Typography>
                  </Button>
                )
              case DIVIDER:
                return <Divider key={key} />

              default:
                return null
            }
          },
        )}
      </List>
    </div>
  )
}

export default MenuItems
