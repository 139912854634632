import { useQueryWrapper } from 'domains/helpers'
import { FILE, FILES } from './templates'
import { File } from './types'

export function useFilesQuery(params?, options?) {
  return useQueryWrapper<File[]>('GET', FILES, params, options)
}

export function useFileQuery(params?, options?) {
  return useQueryWrapper<File>('GET', FILE, params, options)
}
