import { Box, Tabs } from '@mui/material'
import Header from 'components/templates/AdminLayout/components/Header'
import LinkTab from 'components/atoms/LinkTab'
import { EMAIL_MANAGEMENT_TEMPLATES_PATH } from 'enums/paths'
import { last } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'

const TEMPLATES = 'templates'
const LAYOUT = 'layout'
const FUSION_FIELDS = 'fusion-fields'
const TEST = 'test'
function MailManagement() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const lastPathnamePart = last(pathname.split('/'))
  const initialValue = [TEMPLATES, LAYOUT, FUSION_FIELDS, TEST].includes(
    lastPathnamePart,
  )
    ? lastPathnamePart
    : TEMPLATES
  const [value, setValue] = React.useState(initialValue)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Header
          defaultTitles={[
            { label: t('admin') },
            {
              label: t('mail-management'),
              to: EMAIL_MANAGEMENT_TEMPLATES_PATH,
            },
          ]}
        />
      </Box>
      <Tabs value={value} onChange={handleChange}>
        <LinkTab to="templates" value={TEMPLATES} label={t('mail-templates')} />
        {/* TODO : À REMETTRE SUR LES SUPERVISEURS */}

        {/* <LinkTab to="layout" value={LAYOUT} label={t('layout')} /> */}
        {/* <LinkTab
          to="fusion-fields"
          value={FUSION_FIELDS}
          label={t('mail-tags')}
        /> */}
        {/* <LinkTab to="test" value={TEST} label={t('mail-test')} /> */}
      </Tabs>
      <Outlet />
    </>
  )
}

export default MailManagement
