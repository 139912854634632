import { Box, Grid } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { SOURCE } from 'domains/of/enums'
import { useGetPublicTypesQuery } from 'domains/publicTypes/queries'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function LDAPSupplier({ supplier, variant }) {
  const { control, setValue } = useFormContext()
  const { t } = useTranslation()

  const ofPublicTypes = useWatch({
    control,
    name: 'ofPublicTypes',
  })
  const { data: publicTypes, isLoading } = useGetPublicTypesQuery()

  useEffect(() => {
    if (
      supplier === SOURCE.ACTIVE_DIRECTORY.value &&
      variant === 'create' &&
      isEmpty(ofPublicTypes)
    ) {
      const newTab = []
      if (!isLoading && !isEmpty(publicTypes)) {
        publicTypes.forEach((type) => {
          newTab.push({
            publicType: type,
            isActive: false,
            filter: '',
            activeDirectoryFields: [],
            currentSelectedLDAPField: '',
            currentSelectedDBField: '',
            requiredLDAPField: false,
          })
        })
        setValue('ofPublicTypes', newTab)
      }
    } else if (!isLoading && !isEmpty(publicTypes) && variant === 'edit') {
      const newTab = []
      ofPublicTypes.forEach((type) => {
        newTab.push({
          publicType: type.publicType,
          isActive: type.isActive,
          filter: type.filter,
          activeDirectoryFields: type.activeDirectoryFields,
          currentSelectedLDAPField: '',
          currentSelectedDBField: '',
          requiredLDAPField: false,
        })
      })

      publicTypes
        .filter(
          (type) => !ofPublicTypes.find((a) => a.publicType.id === type.id),
        )
        .forEach((type) => {
          newTab.push({
            publicType: type,
            isActive: false,
            filter: '',
            activeDirectoryFields: [],
            currentSelectedLDAPField: '',
            currentSelectedDBField: '',
            requiredLDAPField: false,
          })
        })

      setValue('ofPublicTypes', newTab)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier])

  return (
    <Box
      sx={{
        mt: 2,
        display: supplier === SOURCE.ACTIVE_DIRECTORY.value ? 'block' : 'none',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ControlledTextField
            name="activeDirectoryUrl"
            label={t('activeDirectoryUrl')}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ControlledTextField
            name="activeDirectoryPort"
            label={t('activeDirectoryPort')}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <ControlledTextField
            name="activeDirectoryOU"
            label={t('activeDirectoryOU')}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name="activeDirectoryLogin"
            label={t('activeDirectoryLogin')}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name="activeDirectoryPassword"
            type="password"
            label={t('activeDirectoryPassword')}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LDAPSupplier
