import { TextField } from '@mui/material'
import { get, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { forwardRef } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ControlledDatePickerProps } from './types'

const ControlledDatePicker = forwardRef(
  (
    { name, control, datetime = false, ...props }: ControlledDatePickerProps,
    ref,
  ) => {
    const { t } = useTranslation()
    const controller = useController({ name, control })
    const {
      field,
      fieldState: { error },
    } = controller

    if (field.value === undefined) {
      console.error(
        `[FTEL-ERROR] : Le composant ControlledDatePicker '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
      )
    }

    const value = DateTime.fromISO(field.value)
    const formattedValue = !value.invalid ? value.toFormat('yyyy-MM-dd') : ''

    const handleChangeDatetime = (e) => {
      const nextValue = isEmpty(e.target.value) ? '' : e.target.value

      field.onChange(nextValue)
    }

    const handleChangeDate = (e) => {
      const nextValue = isEmpty(e.target.value)
        ? ''
        : DateTime.fromISO(e.target.value).toISO()

      field.onChange(nextValue)
    }

    return (
      <TextField
        ref={ref}
        type={datetime ? 'datetime-local' : 'date'}
        {...field}
        value={datetime ? field.value : formattedValue}
        onChange={datetime ? handleChangeDatetime : handleChangeDate}
        error={!!error}
        InputLabelProps={{ shrink: true }}
        helperText={error && get(error, 'message', t('invalid-field'))}
        sx={{ minWidth: (theme) => theme.spacing(5) }}
        {...props}
      />
    )
  },
)

export default ControlledDatePicker
