import { OfProvider } from 'components/organisms/OFSManager/context/of'
import { OfsProvider } from 'components/organisms/OFSManager/context/ofs'
import Header from 'components/templates/AdminLayout/components/Header'
import { OFS_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'

function OFS() {
  const { t } = useTranslation()
  const params = useParams()
  const { id } = params
  return (
    <OfProvider id={id}>
      <Header
        defaultTitles={[
          { label: t('admin') },
          { label: t('ofs-management'), to: OFS_PATH },
        ]}
      />

      <OfsProvider>
        <Outlet />
      </OfsProvider>
    </OfProvider>
  )
}

export default OFS
