import { LogGroupedApplicationModel } from 'domains/logs/types'
import { isEmpty } from 'lodash'
import { LOGOF_APP_NAME, LOG_OF_DISCRIMINATOR } from './enum'

export function formatAlertLabel(labels: string[]) {
  return labels.filter((l) => !isEmpty(l?.trim())).join(' - ')
}

export function getAlertLabel(alert: LogGroupedApplicationModel) {
  const applicationName =
    alert.applicationName?.toUpperCase() ??
    (alert.discriminator.trim().toLowerCase() ===
    LOG_OF_DISCRIMINATOR.trim().toLowerCase()
      ? LOGOF_APP_NAME.toUpperCase()
      : null)
  const trimmedMethod = alert?.method?.replace('(Sync.)', '').trimEnd()
  return formatAlertLabel([applicationName, trimmedMethod])
}
