import React from 'react'
import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography'

const typography: TypographyOptions & {
  tag: TypographyStyleOptions
  table: TypographyStyleOptions
  label: TypographyStyleOptions
} = {
  fontFamily: ['Montserrat', 'sans-serif'].join(','),
  h1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 26,
  },
  h2: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 20,
  },
  h3: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 20,
  },
  h4: {
    textDecoration: 'line-through',
  },
  h5: {
    textDecoration: 'line-through',
  },
  h6: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 24,
  },
  subtitle1: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 20,
  },
  subtitle2: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 16,
  },
  body1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 16,
  },
  body2: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 14,
  },
  button: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 18,
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: 12,
  },
  overline: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 32,
  },
  tag: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 11,
    textTransform: 'uppercase',
  },
  table: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 15,
  },
  chip: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 13,
  },
  label: { fontFamily: 'Montserrat', fontWeight: 600, fontSize: 14 },
}

export default typography

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tag: React.CSSProperties
    table: React.CSSProperties
    chip: React.CSSProperties
    label: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    tag?: React.CSSProperties
    table?: React.CSSProperties
    chip?: React.CSSProperties
    label: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tag: true
    table: true
    chip: true
    label: true
  }
}

// https://mui.com/material-ui/customization/typography/
