import { Box, Typography } from '@mui/material'
import LoginForm from 'components/organisms/LoginForm'
import { FORGOT_PASSWORD_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Login() {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'primary.dark',
        '& a': {
          textDecoration: 'none',
        },
      }}
    >
      <LoginForm />
      <Link to={FORGOT_PASSWORD_PATH}>
        <Typography
          sx={{
            mt: 2,
            color: 'primary.light',
            transition: '100ms',
            '&:hover': { transform: 'scale(1.1)' },
          }}
          variant="body1"
        >
          {t('forgot-password')}
        </Typography>
      </Link>
    </Box>
  )
}

export default Login
