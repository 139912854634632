import AuthorizedTokensManager from 'components/organisms/AuthorizedTokensManager'
import Header from 'components/templates/AdminLayout/components/Header'
import { AUTHORIZED_TOKENS_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'

function AuthorizedTokens() {
  const { t } = useTranslation()
  return (
    <>
      <Header
        defaultTitles={[
          { label: t('admin') },
          { label: t('authorized-tokens'), to: AUTHORIZED_TOKENS_PATH },
        ]}
      />
      <AuthorizedTokensManager />
    </>
  )
}

export default AuthorizedTokens
