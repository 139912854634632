import { Delete, Edit } from '@mui/icons-material'
import {
  GridActionsCellItem,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import { formatToShortDate } from 'components/organisms/TrainingOrganization/components/helpers'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteUserMutation } from 'domains/users/mutations'
import { useUsersQuery } from 'domains/users/queries'
import { USERS } from 'domains/users/templates'
import { USERS_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'

const useUsersGrid = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { data: users, isFetching } = useUsersQuery()
  const deleteUser = useDeleteUserMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const handleDelete = (id) =>
    confirm(t('irreversible-action'), t('user-delete-confirmation'))
      .then(() =>
        deleteUser.mutate(
          { id },
          {
            onSuccess: () => {
              popSnackbar(t('user-delete-success'), SUCCESS)
              queryClient.invalidateQueries(USERS)
            },
          },
        ),
      )
      .catch(() => null)

  const columns = [
    {
      field: 'firstname',
      headerName: t('firstname'),
      flex: 4,
      editable: false,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'lastname',
      headerName: t('lastname'),
      flex: 4,
      editable: false,
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 4,
      editable: false,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'role',
      headerName: t('role'),
      flex: 3,
      editable: false,
      valueGetter: (params) => {
        return params.value && t(params.value.key)
      },
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'application',
      headerName: t('application'),
      flex: 3,
      editable: false,
      valueGetter: (params) => {
        return params.value?.name
      },
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'of',
      headerName: t('of'),
      flex: 3,
      editable: false,
      valueGetter: (params) => {
        return params.value?.shortName
      },
      renderCell: (params: any) => <TooltipCell value={params.value} />,
    },
    {
      field: 'createDate',
      headerName: t('createDate.small'),
      width: 110,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatToShortDate(params.value)
      },
    },
    {
      field: 'updateDate',
      headerName: t('updateDate.small'),
      width: 110,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatToShortDate(params.value)
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ id }) => [
        <GridActionsCellItem
          onClick={() => navigate(urlcat(USERS_EDIT_PATH, { id }))}
          icon={<Edit />}
          label={t('edit')}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          onClick={() => handleDelete(id)}
          label={t('delete')}
        />,
      ],
    },
  ]

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'lastname',
      sort: 'asc',
    },
  ])

  const rows = users || []

  return { columns, rows, isFetching, sortModel, setSortModel }
}

export default useUsersGrid
