import { AVAILABILITES } from 'domains/of/enums'
import { Of } from 'domains/of/types'
import { isEmpty } from 'lodash'

export function isAvailable(of: Of) {
  return of && of.availability === AVAILABILITES.READY.value
}

export function isInError(of: Of) {
  return of && of.availability === AVAILABILITES.BLOCKED.value
}

export function isInWarning(of: Of) {
  return of && of.availability === AVAILABILITES.IN_WARNING.value
}

export function isBusy(of: Of) {
  return of && of.availability === AVAILABILITES.BUSY.value
}

export function isRefAvailable(of: Of) {
  return of && of.referentielAvailability === AVAILABILITES.READY.value
}

export function isRefInError(of: Of) {
  return of && of.referentielAvailability === AVAILABILITES.BLOCKED.value
}

export function isRefInWarning(of: Of) {
  return of && of.referentielAvailability === AVAILABILITES.IN_WARNING.value
}

export function isRefBusy(of: Of) {
  return of && of.referentielAvailability === AVAILABILITES.BUSY.value
}

export const nbConnectionInError = (of: Of) => {
  return of.connections.filter((c) => c.isInError).length
}

export const nbConnectionInWarning = (of: Of) => {
  return of.connections.filter((c) => c.isInWarning).length
}

export const sortOfsByStateAndShortName = (ofs: Of[]) => {
  if (isEmpty(ofs)) {
    return []
  }
  const slicedOfs = ofs.slice()
  return slicedOfs.sort(
    (a, b) =>
      Number(isInError(b)) - Number(isInError(a)) ||
      nbConnectionInError(b) - nbConnectionInError(a) ||
      Number(isInWarning(b)) - Number(isInWarning(a)) ||
      nbConnectionInWarning(b) - nbConnectionInWarning(a) ||
      Number(isBusy(b)) - Number(isBusy(a)) ||
      a.shortName.localeCompare(b.shortName),
  )
}
