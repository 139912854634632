import { useMutationWrapper } from 'domains/helpers'
import { FILE, FILES, FILE_UPLOAD } from './templates'

export function useDeleteFileMutation(options?) {
  return useMutationWrapper('DELETE', FILE, options)
}
export function useUpdateFileMutation(options?) {
  return useMutationWrapper('PUT', FILE, options)
}

export function usePatchFileMutation(options?) {
  return useMutationWrapper('PATCH', FILE, options)
}

export function useCreateFileMutation(options?) {
  return useMutationWrapper('POST', FILES, options)
}

export function useUploadFileMutation(options?) {
  return useMutationWrapper('POST', FILE_UPLOAD, options)
}
