import { Box } from '@mui/material'
import Header from 'components/templates/AdminLayout/components/Header'
import { CATEGORIES_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

function Categories() {
  const { t } = useTranslation()

  return (
    <Box>
      <Header
        defaultTitles={[
          { label: t('admin') },
          { label: t('categories'), to: CATEGORIES_PATH },
        ]}
      />
      <Outlet />
    </Box>
  )
}

export default Categories
