import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

function UsersPopUp({
  open,
  onClose,
  addUser,
  withExistingUsers,
  role,
  existingUserForm: ExistingUserForm,
  newUserForm: NewUserForm,
  text,
}) {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {t(text)}
          <IconButton size="small" onClick={() => onClose()}>
            <Close sx={{ color: 'common.black' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {withExistingUsers && (
          <>
            <ExistingUserForm open={open} addUser={addUser} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3,
                mb: 3,
              }}
            >
              <Box sx={{ flex: 1, pl: 4 }}>
                <hr />
              </Box>
              <Box sx={{ pl: 4, pr: 4 }}>
                <Typography variant="h3" sx={{ textTransform: 'uppercase' }}>
                  {t('or')}
                </Typography>
              </Box>
              <Box sx={{ flex: 1, pr: 4 }}>
                <hr />
              </Box>
            </Box>
          </>
        )}

        <NewUserForm addUser={addUser} role={role} />
      </DialogContent>
    </Dialog>
  )
}

export default UsersPopUp
