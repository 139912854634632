import { yupResolver } from '@hookform/resolvers/yup'
import { DEFAULT_TYPES_FILTER } from 'components/organisms/TrainingOrganization/components/filter'
import { formattedEndDate, formattedStartDate } from 'formatters/date'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

function useLogsFilterForm() {
  const defaultValues = {
    dateStart: formattedStartDate,
    dateEnd: formattedEndDate,
    types: DEFAULT_TYPES_FILTER,
    method: '',
    description: '',
  }
  const validationSchema = Yup.object().shape({
    dateStart: Yup.string().nullable(),
    dateEnd: Yup.string().nullable(),
    types: Yup.array().nullable(),
    method: Yup.string().nullable(),
    description: Yup.string().nullable(),
  })

  return useForm({ defaultValues, resolver: yupResolver(validationSchema) })
}

export default useLogsFilterForm
