import { Box } from '@mui/material'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useCreateFileMutation } from 'domains/files/mutations'
import { FILES } from 'domains/files/templates'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreateFileForm from '../../form'
import FileForm from '../FileForm'

function CreateFileForm({ handleClose }) {
  const { t } = useTranslation()
  const form = useCreateFileForm()
  const createFile = useCreateFileMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (values) => {
    await createFile.mutateAsync({
      data: {
        name: values.name,
        key: values.key,
        url: values.file?.url ? values.file.url : values.url,
        applications: values.applications,
        oFs: values.oFs,
      },
    })
    popSnackbar(t('file-add-success'), SUCCESS)
    setIsDirty(false)
    queryClient.invalidateQueries(FILES)
    handleClose()
  }

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            bgcolor: 'common.white',
            borderRadius: 1,
            pt: 1,
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <FileForm onSubmit={form.handleSubmit(onSubmit)} />
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateFileForm
