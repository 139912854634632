import ControlledTextField from 'components/molecules/ControlledTextField'
import React from 'react'

function AlertDatePicker({ name, label, InputProps }) {
  return (
    <ControlledTextField
      name={name}
      type="datetime-local"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={InputProps}
      label={label}
      sx={{
        mx: 0.25,
        '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
          filter:
            'invert(96%) sepia(23%) saturate(130%) hue-rotate(230deg) brightness(111%) contrast(73%)',
        },
        '& MuiFormControl-root-MuiTextField-root, .MuiOutlinedInput-input': {
          fontSize: (theme) => ({
            xs: theme.typography.body2.fontSize,
            xl: theme.typography.body1.fontSize,
          }),
        },
        '& label.Mui-focused, .MuiInputLabel-root, .MuiOutlinedInput-input': {
          color: (theme) => theme.palette.primary.light,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: (theme) => theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset, :hover fieldset, .Mui-focused fieldset': {
            borderColor: (theme) => theme.palette.primary.main,
          },
        },
      }}
    />
  )
}

export default AlertDatePicker
