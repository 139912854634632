import Typography from '@mui/material/Typography'
import { useConfirm } from 'contexts/ConfirmContext'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function HeaderTypography({ headerTitle }) {
  const { confirm } = useConfirm()
  const navigate = useNavigate()
  const { setIsDirty } = useGlobalFormState()

  const handleClick = () => {
    confirm(headerTitle?.confirm?.title, headerTitle?.confirm?.message).then(
      () => {
        setIsDirty(false)
        return navigate(headerTitle.to)
      },
    )
  }

  if (isEmpty(headerTitle)) {
    console.error(
      `[FTEL-ERROR] : Un composant HeaderTypography contient une confirmation (confirm) sans redirection (to)"`,
    )
    return null
  }
  if (!isEmpty(headerTitle.confirm) && headerTitle.confirm.enable) {
    return (
      <Typography
        variant="h1"
        noWrap
        component="div"
        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={handleClick}
      >
        {headerTitle.label}
      </Typography>
    )
  }
  return (
    <Typography variant="h1" noWrap component="div">
      {headerTitle.to ? (
        <Link to={headerTitle.to} style={{ color: 'inherit' }}>
          {headerTitle.label}
        </Link>
      ) : (
        headerTitle.label
      )}
    </Typography>
  )
}

export default HeaderTypography
