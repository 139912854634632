import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateCategoryForm() {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    number: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    number: Yup.string().required(t('required-field')),
  })

  return useForm({ defaultValues, resolver: yupResolver(validationSchema) })
}

export default useCreateCategoryForm
