import { BuildRounded, SaveAsRounded } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import ControlledCheckbox from 'components/molecules/ControlledCheckbox'
import ControlledSelect from 'components/molecules/ControlledSelect'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ExistingUserForm from 'components/molecules/ExistingUserForm'
import NewUserForm from 'components/molecules/NewUserForm'
import TabPanel from 'components/molecules/TabPanel'
import { a11yProps } from 'components/molecules/TabPanel/helper'
import UsersForm from 'components/molecules/UsersForm'
import UsersPopUp from 'components/molecules/UsersPopUp'
import { yellowSand } from 'config/theme/palette'
import { useAuth } from 'contexts/AuthContext'
import { useConfirm } from 'contexts/ConfirmContext'
import { SOURCE, STATUS } from 'domains/of/enums'
import { useGetPublicTypesQuery } from 'domains/publicTypes/queries'
import { RoleEnum } from 'domains/roles/enums'
import { useRolesQuery } from 'domains/roles/queries'
import { isAdminPlus } from 'helpers/role'
import { isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Control,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOf } from '../../context/of'
import { findRole } from '../../helpers'
import { titleHeightSx } from '../../style'
import CommonAppsForm from '../CommonAppsForm'
import ExcelSupplier from '../ExcelSupplier'
import LDAPSupplier from '../LDAPSupplier'
import OFSConnectionsForm from '../OFSConnectionsForm'
import OFSGeneralInfoForm from '../OFSGeneralInfoForm'
import OFSPublicTypesLDAPComponent from '../OFSPublicTypesLDAPComponent'
import YpareoSupplier from '../YpareoSupplier'

type OFSForm = {
  control: Control<
    {
      name: string
      code: string
      shortName: string
      source: number
      users: any[]
      admins: any[]
      readers: any[]
      syncHour: number
      syncMinute: number
      accessHourBegin: number
      accessMinuteBegin: number
      accessHourEnd: number
      accessMinuteEnd: number
      deletionDay: number
      deletionMonth: number
      isActive: boolean
      connections: any[]
      status: number
      address: string
      token: string
      activeDirectoryUrl: string
      activeDirectoryPort: number
      activeDirectoryOU: string
      activeDirectoryLogin: string
      activeDirectoryPassword: string
      ofPublicTypes: any[]
      path: string
      user: any
      _LogFolderName: string
      url: string
    },
    any
  >
  onSubmitSave: (
    e?: React.BaseSyntheticEvent<object, any, any>,
  ) => Promise<void>
  onSubmitDeploy: (
    e?: React.BaseSyntheticEvent<object, any, any>,
  ) => Promise<void>
  variant: 'create' | 'edit'
}

function OFSMainForm({
  control,
  onSubmitSave,
  onSubmitDeploy,
  variant,
}: OFSForm) {
  const { t } = useTranslation()
  const [openAdminDialog, setOpenAdminDialog] = useState(false)
  const [openReadersDialog, setOpenReadersDialog] = useState(false)
  const [tabValue, setTabValue] = useState(2)
  const { data: roles } = useRolesQuery()
  const source = useWatch({
    control,
    name: 'source',
  })

  const { of } = useOf()
  const { user } = useAuth()
  const { setValue } = useFormContext()

  const canBeDeployed =
    of?.status === STATUS.DRAFT.value || of?.status === STATUS.DELETED.value

  const canEditSource = isEmpty(of) || of?.status === STATUS.DRAFT.value

  const { confirm } = useConfirm()
  const isEdit = variant === 'edit'
  const toggleAdminDialog = () => {
    setOpenAdminDialog(!openAdminDialog)
  }

  const toggleReadersDialog = () => {
    setOpenReadersDialog(!openReadersDialog)
  }

  const {
    fields: admins,
    append: addAdmin,
    remove: removeAdmin,
  } = useFieldArray({
    control,
    name: 'admins',
  })

  const {
    fields: readers,
    append: addReader,
    remove: removeReader,
  } = useFieldArray({
    control,
    name: 'readers',
  })

  const {
    fields: connections,
    append: addConnection,
    remove: removeConnection,
  } = useFieldArray({
    control,
    name: 'connections',
  })

  const handleDeleteAdmin = (index) => {
    confirm(t('admin-delete-confirmation')).then(() => removeAdmin(index))
  }

  const handleDeleteReader = (index) => {
    confirm(t('reader-delete-confirmation')).then(() => removeReader(index))
  }

  const handleDeleteConnection = (index, event) => {
    event.stopPropagation()
    confirm(t('connection-delete-confirmation')).then(() =>
      removeConnection(index),
    )
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const ofPublicTypes = useWatch({
    control,
    name: 'ofPublicTypes',
  })
  const { data: publicTypes, isLoading } = useGetPublicTypesQuery()

  useEffect(() => {
    if (
      source === SOURCE.ACTIVE_DIRECTORY.value &&
      variant === 'create' &&
      isEmpty(ofPublicTypes)
    ) {
      const newTab = []
      if (!isLoading && !isEmpty(publicTypes)) {
        publicTypes.forEach((type) => {
          newTab.push({
            publicType: type,
            isActive: false,
            filter: '',
            activeDirectoryFields: [],
            currentSelectedLDAPField: '',
            currentSelectedDBField: '',
            requiredLDAPField: false,
          })
        })
        setValue('ofPublicTypes', newTab)
      }
    } else if (!isLoading && !isEmpty(publicTypes) && variant === 'edit') {
      const newTab = []
      ofPublicTypes.forEach((type) => {
        newTab.push({
          publicType: type.publicType,
          isActive: type.isActive,
          filter: type.filter,
          activeDirectoryFields: type.activeDirectoryFields,
          currentSelectedLDAPField: '',
          currentSelectedDBField: '',
          requiredLDAPField: false,
        })
      })

      publicTypes
        .filter(
          (type) => !ofPublicTypes.find((a) => a.publicType.id === type.id),
        )
        .forEach((type) => {
          newTab.push({
            publicType: type,
            isActive: false,
            filter: '',
            activeDirectoryFields: [],
            currentSelectedLDAPField: '',
            currentSelectedDBField: '',
            requiredLDAPField: false,
          })
        })

      setValue('ofPublicTypes', newTab)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source])

  return (
    <Box>
      <Grid
        container
        sx={{
          mt: 2,
          p: 2,
          pt: 0,
          bgcolor: 'common.white',
          borderRadius: 1,
          width: '100%',
        }}
        component="form"
        onSubmit={onSubmitSave}
        rowSpacing={2}
        alignItems="flex-start"
      >
        <OFSGeneralInfoForm control={control} variant={variant} />
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex' }} mb={1}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {t('supplier')}
              </Typography>
              <Box
                sx={{
                  flex: 1,
                }}
                ml={2}
                mt={1.5}
              >
                <Divider
                  sx={{
                    bgcolor: (theme) => theme.palette.secondary.main,
                    height: (theme) => theme.spacing(0.3),
                  }}
                />
              </Box>
            </Grid>
            <Tooltip title={!canEditSource ? t('cannot.edit.of.source') : ''}>
              <Grid item xs={12} md={6}>
                <ControlledSelect
                  control={control}
                  fullWidth
                  size="small"
                  name="source"
                  label={t('source')}
                  loading={false}
                  disabled={!canEditSource}
                  sx={{
                    paddingRight: (theme) => theme.spacing(0.5),
                    fieldset: { legend: { maxWidth: '100%' } },
                  }}
                >
                  {map(SOURCE, (value) => value).map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {t(item.label)}
                    </MenuItem>
                  ))}
                </ControlledSelect>
                <LDAPSupplier supplier={source} variant={variant} />
              </Grid>
            </Tooltip>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  borderRadius: 1,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display:
                      source === SOURCE.ACTIVE_DIRECTORY.value
                        ? 'block'
                        : 'none',
                  }}
                >
                  <Box
                    sx={{
                      ...titleHeightSx,
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {t('active-in-LDAP')}
                    </Typography>
                  </Box>
                  {!isLoading &&
                    !isEmpty(ofPublicTypes) &&
                    ofPublicTypes.map((typeAD, index) => (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: 1,
                            }}
                          >
                            <Box
                              sx={{
                                alignItems: 'center',
                                w: 1,
                              }}
                            >
                              <ControlledCheckbox
                                label={typeAD.publicType.name}
                                onClick={(event) => event.stopPropagation()}
                                name={`ofPublicTypes[${index}].isActive`}
                                sx={{ textOverflow: 'ellipsis' }}
                              />
                            </Box>
                            <Box sx={{ mr: 1 }}>
                              <ControlledTextField
                                onClick={(event) => event.stopPropagation()}
                                fullWidth
                                size="small"
                                name={`ofPublicTypes[${index}].filter`}
                                label={t('LDAP-filter')}
                              />
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <OFSPublicTypesLDAPComponent
                            key={typeAD.publicType.id}
                            index={index}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <YpareoSupplier supplier={source} />
          <ExcelSupplier supplier={source} variant={variant} />
        </Grid>
        <Grid item xs={12}>
          <>
            <Box>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { backgroundColor: yellowSand } }}
                sx={{
                  '& .Mui-selected': {
                    backgroundColor: 'common.black',
                    borderRadius: (theme) => theme.shape.borderRadius,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
                textColor="secondary"
              >
                <Tab
                  label={
                    <Typography variant="body2">
                      {t('admins').toLocaleUpperCase()}
                    </Typography>
                  }
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <Typography variant="body2">
                      {t('readers').toLocaleUpperCase()}
                    </Typography>
                  }
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...a11yProps(1)}
                />
                <Tab
                  label={
                    <Typography variant="body2">
                      {t('allowed-connections').toLocaleUpperCase()}
                    </Typography>
                  }
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <>
              <TabPanel value={tabValue} index={0}>
                <UsersForm
                  handleChangeDialog={toggleAdminDialog}
                  users={admins}
                  removeUser={handleDeleteAdmin}
                  userCategory="admin"
                  text={t('admins')}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <UsersForm
                  handleChangeDialog={toggleReadersDialog}
                  users={readers}
                  removeUser={handleDeleteReader}
                  userCategory="reader"
                  text={t('readers')}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <OFSConnectionsForm
                  variant={variant}
                  addConnection={addConnection}
                  removeConnection={handleDeleteConnection}
                  connections={connections}
                  control={control}
                />
                {variant === 'edit' && <CommonAppsForm />}
              </TabPanel>
            </>
          </>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              startIcon={<SaveAsRounded />}
              type="submit"
            >
              {t('save')}
            </Button>
            <Box ml={2}>
              {user.role &&
                isAdminPlus(user.role.key) &&
                isEdit &&
                canBeDeployed && (
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<BuildRounded />}
                    onClick={onSubmitDeploy}
                  >
                    {t('deploy')}
                  </Button>
                )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <UsersPopUp
        open={openAdminDialog}
        onClose={toggleAdminDialog}
        addUser={addAdmin}
        withExistingUsers={false}
        existingUserForm={ExistingUserForm}
        newUserForm={NewUserForm}
        text="add-admin"
        role={findRole(roles, RoleEnum.ADMIN_OFA.key)}
      />
      <UsersPopUp
        open={openReadersDialog}
        onClose={toggleReadersDialog}
        addUser={addReader}
        withExistingUsers={false}
        existingUserForm={ExistingUserForm}
        newUserForm={NewUserForm}
        text="add-reader"
        role={findRole(roles, RoleEnum.USER_OFA.key)}
      />
    </Box>
  )
}

export default OFSMainForm
