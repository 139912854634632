import { useOfQuery } from 'domains/of/queries'
import { isEmpty } from 'lodash'
import React, { createContext, useContext, useMemo } from 'react'
import { OfContextType } from './type'

const OrderContext = createContext<OfContextType>(null)

function OfProvider({ id, children }) {
  const {
    data: of,
    isLoading: loading,
    refetch,
  } = useOfQuery({ id }, { enabled: !isEmpty(id) })

  const values = useMemo(
    () => ({
      of,
      loading,
      refetch,
    }),
    [of, loading, refetch],
  )

  return (
    <OrderContext.Provider value={values}>{children}</OrderContext.Provider>
  )
}

const useOf = () => {
  const context = useContext(OrderContext)
  if (isEmpty(context)) {
    throw new Error('useOf must be used within a OfProvider')
  }
  return context
}

export { OfProvider, useOf }
