import {
  CancelTwoTone,
  CheckCircleTwoTone,
  HourglassBottomTwoTone,
  MoreHorizTwoTone,
  WarningTwoTone,
} from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { SyncStateEnum } from '../../enums'
import {
  errorSubSyncStateCommonsx,
  runningSubSyncStateCommonsx,
  subSyncStateCommonsx,
  successSubSyncStateCommonsx,
  warningSubSyncStateCommonsx,
} from '../../styles'

function SyncStateIcon({ syncState }) {
  return (
    <Box>
      {syncState === SyncStateEnum.SUCCESS && (
        <Box sx={successSubSyncStateCommonsx}>
          <CheckCircleTwoTone />
        </Box>
      )}
      {syncState === SyncStateEnum.ERROR && (
        <Box sx={errorSubSyncStateCommonsx}>
          <CancelTwoTone />
        </Box>
      )}
      {syncState === SyncStateEnum.RUNNING && (
        <Box sx={runningSubSyncStateCommonsx}>
          <HourglassBottomTwoTone
            sx={{
              animation: 'spin 3s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(180deg)',
                },
              },
            }}
          />
        </Box>
      )}
      {syncState === SyncStateEnum.WAITING && (
        <Box sx={subSyncStateCommonsx}>
          <MoreHorizTwoTone />
        </Box>
      )}
      {syncState === SyncStateEnum.WARNING && (
        <Box sx={warningSubSyncStateCommonsx}>
          <WarningTwoTone />
        </Box>
      )}
      {!Object.values(SyncStateEnum).includes(syncState) && (
        <Box sx={subSyncStateCommonsx} />
      )}
    </Box>
  )
}

export default SyncStateIcon
