import { useQueryWrapper } from 'domains/helpers'
import { MAIL_TEMPLATE, MAIL_TEMPLATES } from './templates'
import { MailTemplate } from './types'

export function useGetMailTemplatesQuery(params?, options?) {
  return useQueryWrapper<MailTemplate[]>('GET', MAIL_TEMPLATES, params, options)
}

export function useGetMailTemplateQuery(params?, options?) {
  return useQueryWrapper<MailTemplate>('GET', MAIL_TEMPLATE, params, options)
}
