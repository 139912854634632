import { isEmpty } from 'lodash'

export function formatApplications(
  applications: any[],
  connectionApplicationsId: any[],
) {
  const applicationsFormattedTemp = []

  if (!isEmpty(applications)) {
    applications
      .filter(
        (application) => !connectionApplicationsId.includes(application.id),
      )
      .map(
        ({
          id,
          name,
          isGenericApp,
          genericAppUrl,
          isWSRest,
          applicationPublicTypes,
        }) =>
          applicationsFormattedTemp.push({
            label: name,
            id,
            name,
            isGenericApp,
            genericAppUrl,
            isWSRest,
            applicationPublicTypes,
          }),
      )
  }
  return applicationsFormattedTemp
}

export function formatApplicationsPublicTypes(
  applications: any[],
  connectionApplicationsId: any[],
) {
  const applicationsFormattedTemp = []

  if (!isEmpty(applications)) {
    applications
      .filter((application) =>
        connectionApplicationsId.includes(application.id),
      )
      .map(
        ({
          id,
          name,
          isGenericApp,
          genericAppUrl,
          isWSRest,
          applicationPublicTypes,
        }) =>
          applicationsFormattedTemp.push({
            label: name,
            id,
            name,
            isGenericApp,
            genericAppUrl,
            isWSRest,
            applicationPublicTypes,
          }),
      )
  }
  return applicationsFormattedTemp
}

export const downloadFile = (response) => {
  const { bytes, fileName, contentType } = response
  const a = document.createElement('a')
  a.href = `data:${contentType};base64,${bytes}`
  a.download = fileName
  a.onclick = () => {
    document.body.removeChild(a)
  }
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
}
