import { Box } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { isAdminAppExclusive } from 'helpers/role'
import React from 'react'
import LogsFetcher from '../LogsFetcher'

function LogsBlock({ variant, collection, ofId }) {
  const { user } = useAuth()

  return (
    <Box sx={{ width: '100%', mb: 2, position: 'relative' }}>
      {collection &&
        collection.map((item, index) => {
          if (
            user.role &&
            (!isAdminAppExclusive(user.role.key) ||
              (isAdminAppExclusive(user.role.key) &&
                user.application?.id === item.application?.id))
          ) {
            return (
              <Box key={item?.id || index} sx={{ mt: 2 }}>
                <LogsFetcher item={item} ofId={ofId} variant={variant} />
              </Box>
            )
          }
          return <Box />
        })}
    </Box>
  )
}

export default LogsBlock
