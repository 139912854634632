import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LogsHeaderProps } from './type'

function LogsHeader({
  title,
  children,
  hideMethods = false,
  methodFlexValue,
  descriptionFlexValue,
}: LogsHeaderProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'common.black',
        color: 'primary.light',
        borderTopLeftRadius: (theme) =>
          parseFloat(theme.shape.borderRadius.toString()) * 4,
        borderTopRightRadius: (theme) =>
          parseFloat(theme.shape.borderRadius.toString()) * 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          mx: 1,
        }}
      >
        {title}
        <Box>{children}</Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          ml: 0.5,
          mb: 0.5,
          mt: 1,
        }}
      >
        <Box
          sx={{
            px: 0.5,
            width: (theme) => theme.spacing(6),
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <Typography variant="table">{t('log-type')}</Typography>
        </Box>
        <Box
          sx={{
            px: 0.5,
            width: (theme) => theme.spacing(16),
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <Typography variant="table">{t('addition')}</Typography>
        </Box>
        {!hideMethods && (
          <Box
            sx={{
              px: 0.5,
              flex: methodFlexValue,
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <Typography variant="table">{t('method')}</Typography>
          </Box>
        )}
        <Box
          sx={{
            px: 0.5,
            flex: descriptionFlexValue,
            minWidth: 0,
            wordWrap: 'break-word',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <Typography variant="table">{t('description')}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default LogsHeader
