import { User } from 'domains/users/types'
import { find, get, isEmpty } from 'lodash'

export function formatUsers(users: User[]) {
  const usersFormattedTemp = []
  if (!isEmpty(users)) {
    users.forEach((user) => {
      const firstname = user?.firstname || ''
      const lastname = user?.lastname || ''
      const email = user?.email || ''
      const fullname = `${firstname} ${lastname}`
      const label = isEmpty(fullname.trim()) ? email : `${fullname} (${email})`
      usersFormattedTemp.push({
        label,
        id: get(user, 'id', null),
      })
    })
  }
  return usersFormattedTemp
}

export function getLabelFromEnum(enums: any, value: any) {
  return find(enums, { value }).label
}
