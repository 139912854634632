import { Box } from '@mui/material'
import React from 'react'
import { LogCellProps } from './type'

function LogCell({ sx, children }: LogCellProps) {
  return (
    <Box
      sx={{
        px: 0.5,
        borderRight: (theme) => `1px solid ${theme.palette.common.black}`,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default LogCell
