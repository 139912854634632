import SettingsIcon from '@mui/icons-material/Settings'
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material'
import ProfileItem from 'components/molecules/ProfileItem'
import { useAuth } from 'contexts/AuthContext'
import { RoleEnum } from 'domains/roles/enums'
import { ADMIN_PATH } from 'enums/paths'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { userMenu } from './helper'

function Actions() {
  const { user } = useAuth()
  const { t } = useTranslation()
  const [anchorAvatarMenu, setAnchorAvatarMenu] = useState(null)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <IconButton
        size="small"
        onClick={(e) =>
          setAnchorAvatarMenu(anchorAvatarMenu == null ? e.currentTarget : null)
        }
      >
        <ProfileItem
          picture={get(user, 'picture', null)}
          firstname={get(user, 'firstname', null)}
          lastname={get(user, 'lastname', null)}
        />
        <Menu
          id="avatar-menu"
          anchorEl={anchorAvatarMenu}
          keepMounted
          open={Boolean(anchorAvatarMenu)}
          onClose={() => setAnchorAvatarMenu(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {userMenu.map(({ key, title, icon: Icon, to = '#' }) => {
            return (
              <ListItem key={key} disablePadding>
                <ListItemButton component={Link} to={to}>
                  <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
                  <ListItemText primary={t(title)} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </Menu>
      </IconButton>

      {(user?.role?.key === RoleEnum.SUPERVISOR.key ||
        user?.role?.key === RoleEnum.ADMIN.key) && (
        <Link to={ADMIN_PATH}>
          <IconButton size="small">
            <SettingsIcon />
          </IconButton>
        </Link>
      )}
    </Box>
  )
}

export default Actions
