import { Box, Button, Grid, Typography } from '@mui/material'
import ControlledHiddenInput from 'components/molecules/ControlledHiddenInput'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useCreateMailFusionFieldMutation } from 'domains/mailFusionFields/mutations'
import { EMAIL_MANAGEMENT_FUSION_FIELDS_PATH } from 'enums/paths'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useCreateFusionFieldForm from './form'

export const CREATE = 'create'

function MailFusionFieldsForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const form = useCreateFusionFieldForm()
  const createMailFusionField = useCreateMailFusionFieldMutation()

  const onSubmit = async (values) => {
    return createMailFusionField
      .mutateAsync({ data: values })
      .then(() => navigate(EMAIL_MANAGEMENT_FUSION_FIELDS_PATH))
  }

  return (
    <Box
      sx={{
        mt: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'common.white',
        p: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h3" mb={2}>
        {t('create-tag')}
      </Typography>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <Grid
          container
          spacing={2}
          component="form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <ControlledHiddenInput name="isDynamic" />
          <Grid item xs={12}>
            <ControlledTextField name="key" label={t('key')} />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField name="value" label={t('value')} />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField name="description" label={t('description')} />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              {t('create-new-tag')}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  )
}

export default MailFusionFieldsForm
