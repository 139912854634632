import { CircularProgress, TextField, Autocomplete } from '@mui/material'
import { CircularProgressSizes } from 'enums/circularProgressSizes'
import { get } from 'lodash'
import React, { forwardRef } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ControlledAutocompleteProps } from './types'

const ControlledAutocomplete = forwardRef(
  (props: ControlledAutocompleteProps, ref) => {
    const {
      name,
      control,
      loading,
      TextFieldProps = {},
      options = [],
      getOptionDisabled,
      multiple,
      isOptionEqualToValue = (option, value) => option.id === value.id,
      ...rest
    } = props
    const { t } = useTranslation()
    const controller = useController({ name, control })
    const {
      field,
      fieldState: { error },
    } = controller

    if (field.value === undefined) {
      console.error(
        `[FTEL-ERROR] : Le composant ControlledAutocomplete '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
      )
    }

    return (
      <Autocomplete
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={isOptionEqualToValue}
        ref={ref}
        options={options}
        getOptionDisabled={getOptionDisabled}
        multiple={multiple}
        {...field}
        onChange={(e, newValue) => field.onChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error && get(error, 'message', t('invalid-field'))}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={CircularProgressSizes.SMALL}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...params.inputProps,
              autoComplete: 'none',
            }}
            {...TextFieldProps}
          />
        )}
        {...rest}
      />
    )
  },
)

export default ControlledAutocomplete
