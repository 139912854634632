import React from 'react'
import HomeOrganism from 'components/organisms/Home'
import { OfsProvider } from 'components/organisms/OFSManager/context/ofs'

function Home() {
  return (
    <OfsProvider>
      <HomeOrganism />
    </OfsProvider>
  )
}

export default Home
