import EditIcon from '@mui/icons-material/Edit'
import { Box, Typography } from '@mui/material'
import { useAuthorization } from 'contexts/AuthAuthorization'
import { ROLES_KEY } from 'domains/users/enums'
import { OFS_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import urlcat from 'urlcat'
import Files from '../Files'
import ManualSynchro from '../ManualSynchro'

function ActionsList({ id }) {
  const { t } = useTranslation()
  const { userIs } = useAuthorization()
  const authorizedToAdministrate = userIs([
    ROLES_KEY.SUPERVISOR,
    ROLES_KEY.ADMIN,
  ])

  return (
    <Box
      sx={{
        bgcolor: 'primary.dark',
        p: 2,
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& svg path': {
            fill: (theme) => theme.palette.primary.light,
          },
        }}
      >
        <EditIcon />
        <Typography
          variant="h2"
          sx={{ color: 'primary.light', ml: 1, textTransform: 'uppercase' }}
        >
          {t('actions')}
        </Typography>
      </Box>
      {authorizedToAdministrate && (
        <Box
          sx={{
            mt: 2,
            minWidth: (theme) => theme.spacing(5.5),
            height: (theme) => theme.spacing(5.5),
            width: '100%',
            padding: '10px',
            textDecoration: 'none',
            textAlign: 'center',
            bgcolor: 'secondary.main',
            borderRadius: 1,
          }}
        >
          <Link
            to={`${urlcat(OFS_EDIT_PATH, { id })}`}
            style={{
              textDecoration: 'none',
              color: '#000',
              textTransform: 'uppercase',
              display: 'block',
              fontSize: '17px',
            }}
          >
            {t('administration')}
          </Link>
        </Box>
      )}
      <ManualSynchro id={id} />
      <Files id={id} />
    </Box>
  )
}

export default ActionsList
