import { Delete, Edit } from '@mui/icons-material'
import { GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import { useConfirm } from 'contexts/ConfirmContext'
import { useDeleteCategoryMutation } from 'domains/categories/mutations'
import { useCategoriesQuery } from 'domains/categories/queries'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useCategoriesGrid = ({ handleOpen }) => {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const { data: categories, refetch, isFetching } = useCategoriesQuery()
  const deleteCategory = useDeleteCategoryMutation()

  const handleDelete = async (id) => {
    await confirm(t('irreversible-action'), t('confirm-delete-category'))
    deleteCategory.mutate({ id }, { onSuccess: () => refetch() })
  }

  const dataColumns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      editable: false,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'number',
      headerName: t('number'),
      flex: 1,
      editable: false,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'actions',
      headerName: t('actions'),
      type: 'actions',
      width: 150,
      getActions: ({ id }) => {
        const actions = []
        actions.push(
          <GridActionsCellItem
            onClick={() => handleOpen(id)}
            icon={<Edit />}
            label={t('edit')}
          />,
        )
        actions.push(
          <GridActionsCellItem
            icon={<Delete />}
            onClick={() => handleDelete(id)}
            label={t('delete')}
          />,
        )
        return actions
      },
    },
  ]

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'number',
      sort: 'asc',
    },
  ])

  const columns = dataColumns

  const rows = categories || []

  return { columns, rows, isFetching, sortModel, setSortModel }
}

export default useCategoriesGrid
