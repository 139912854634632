import { Add } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import UsersFormComponent from '../UsersFormComponent'
import UsersFormHeader from '../UsersFormHeader'

function UsersForm({
  handleChangeDialog,
  users,
  removeUser,
  userCategory,
  text,
}) {
  const handleAddMethod = (event) => {
    event.stopPropagation()
    handleChangeDialog()
  }

  return (
    <Accordion
      sx={{
        borderRadius: 1,
        bgcolor: 'primary.light',
        '&.Mui-expanded': { my: 0 },
        '& .MuiCollapse-root': { mb: 2 },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: 0,
          px: 1.5,
          '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
            {
              my: 1,
            },
          '&.Mui-expanded': {
            minHeight: 0,
          },
          ':not(.Mui-expanded) .addButton': {
            display: 'none',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" width={1}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {text}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'common.black',
              borderRadius: 1,
              p: 0.25,
              mr: 2,
              width: (theme) => theme.spacing(3),
              height: (theme) => theme.spacing(3),
              cursor: 'pointer',
              '& svg': {
                width: '100%',
              },
              '& svg path': {
                fill: (theme) => theme.palette.common.white,
              },
            }}
            onClick={handleAddMethod}
          >
            <Add />
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {users && !isEmpty(users) && (
          <Box sx={{ mt: 2 }}>
            <UsersFormHeader />
            <Box
              sx={{
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                maxHeight: '190px',
              }}
            >
              {users.map((user, index) => (
                <UsersFormComponent
                  key={user.id}
                  user={user}
                  indexUser={index}
                  userCategory={userCategory}
                  removeUser={removeUser}
                />
              ))}
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default UsersForm
