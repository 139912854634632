import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select,
} from '@mui/material'
import { CircularProgressSizes } from 'enums/circularProgressSizes'
import { get, isEmpty } from 'lodash'
import React from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function ControlledSelect({
  name,
  control,
  loading = false,
  label = '',
  children,
  selectProps = {},
  inputLabelProps = {},
  ...props
}) {
  const { t } = useTranslation()
  const controller = useController({ name, control })
  const {
    field,
    fieldState: { error },
  } = controller

  if (field.value === undefined) {
    console.error(
      `[FTEL-ERROR] : Le composant ControlledSelect '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
    )
  }

  const defaultValue = ''

  return (
    <FormControl {...props} error={!!error}>
      <InputLabel {...inputLabelProps}>{label}</InputLabel>
      <Select
        notched={!isEmpty(field.value)}
        label={label}
        input={
          <OutlinedInput
            notched
            label={label}
            endAdornment={
              loading ? (
                <CircularProgress
                  color="inherit"
                  size={CircularProgressSizes.SMALL}
                />
              ) : null
            }
          />
        }
        {...field}
        value={field.value ?? defaultValue}
        {...selectProps}
      >
        {children}
      </Select>
      {error && (
        <FormHelperText>
          {get(error, 'message', t('invalid-field'))}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default ControlledSelect
