import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'

const getReadableMonth = (month?: number, t?: any) => {
  switch (month) {
    case 1:
      return t('january')
    case 2:
      return t('february')
    case 3:
      return t('march')
    case 4:
      return t('april')
    case 5:
      return t('may')
    case 6:
      return t('june')
    case 7:
      return t('july')
    case 8:
      return t('august')
    case 9:
      return t('september')
    case 10:
      return t('october')
    case 11:
      return t('november')
    case 12:
      return t('december')
    default:
      return ''
  }
}

export const formatToReadableDate = (strDate, t, hideYear = false) => {
  if (isEmpty(strDate)) return ''
  const date = DateTime.fromJSDate(new Date(strDate))
  return hideYear
    ? `${date.day} ${getReadableMonth(date.month, t)}`
    : `${date.day} ${getReadableMonth(date.month, t)} ${date.year}`
}

export const formatToShortDate = (strDate) => {
  return isEmpty(strDate)
    ? ''
    : DateTime.fromJSDate(new Date(strDate)).toLocaleString(DateTime.DATE_SHORT)
}

export const formatToLogDatetime = (strDate) => {
  return isEmpty(strDate)
    ? ''
    : DateTime.fromJSDate(new Date(strDate)).toFormat('dd/LL/yy - HH:mm:ss')
}

export const getYesterdayNoon = () => {
  const now = DateTime.now().minus({ days: 1 })
  return `${now.toFormat('yyyy-LL-dd')}T12:00`
}

export const getTodayNoon = () => {
  const now = DateTime.now()
  return `${now.toFormat('yyyy-LL-dd')}T12:00`
}

export const formatLogTypesToForm = (types) => {
  return types?.map((v) => v.value)?.join(',')
}

export const getLogsBorderColor = (theme, item) => {
  if (item?.hasAnyLogs && !item?.isBusy) {
    if (!item?.isInError) {
      if (item?.isInWarning) {
        return theme.palette.error.light
      }
      return theme.palette.success.main
    }
    return theme.palette.error.main
  }
  return theme.palette.primary.main
}
