import { Delete, Edit } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteApplicationMutation } from 'domains/application/mutations'
import { useApplicationsQuery } from 'domains/application/queries'
import { APPLICATIONS } from 'domains/application/templates'
import { APPLICATIONS_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'
import { ApplicationTypeEnum } from './enum'

const useApplicationsGrid = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { popSnackbar } = useSnackbar()
  const { data: applications, isFetching } = useApplicationsQuery()
  const deleteApplication = useDeleteApplicationMutation()
  const queryClient = useQueryClient()

  const handleDelete = async (id, hasConnection) => {
    await confirm(
      t('irreversible-action'),
      hasConnection
        ? t('confirm-delete-application-warning')
        : t('confirm-delete-application'),
    )
    await deleteApplication.mutateAsync({ id })
    popSnackbar(t('application-delete-success'), SUCCESS)
    queryClient.invalidateQueries(APPLICATIONS)
  }

  const displayApplicationType = (isGeneric, isCommon) => {
    if (isGeneric) {
      if (isCommon) {
        return ApplicationTypeEnum.COMMON
      }
      return ApplicationTypeEnum.GENERIC
    }
    return ApplicationTypeEnum.SPECIFIC
  }

  const dataColumns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      editable: false,
    },
    {
      field: 'app-type',
      headerName: t('application-type'),
      width: 200,
      editable: false,
      valueGetter: (params) => {
        return displayApplicationType(
          params.row.isGenericApp,
          params.row.isCommonApp,
        )
      },
      renderCell: (params) => {
        const isGeneric = params.row.isGenericApp
        const isCommon = params.row.isCommonApp
        return (
          <Typography>
            {t(displayApplicationType(isGeneric, isCommon))}
          </Typography>
        )
      },
    },
    {
      field: 'isWSRest',
      headerName: t('ws-rest'),
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Typography>{params.value ? t('yes') : t('no')}</Typography>
      ),
    },
    {
      field: 'isActive',
      headerName: t('active'),
      width: 100,
      renderCell: (params) => (
        <Typography>{params.value ? t('yes') : t('no')}</Typography>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ row: { id, hasConnections } }) => [
        <GridActionsCellItem
          onClick={() => navigate(urlcat(APPLICATIONS_EDIT_PATH, { id }))}
          icon={<Edit />}
          label={t('edit')}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          onClick={() => handleDelete(id, hasConnections)}
          label={t('delete')}
        />,
      ],
    },
  ]

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ])

  const columns = dataColumns

  const rows = applications || []

  return { columns, rows, isFetching, sortModel, setSortModel }
}

export default useApplicationsGrid
