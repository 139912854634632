import {
  BoltRounded,
  CheckRounded,
  ClearRounded,
  QuestionMark,
  WarningRounded,
} from '@mui/icons-material'
import { LOGTYPES } from 'domains/logs/enums'
import { replace } from 'lodash'
import React from 'react'
import { CHIP_ICON_SIZE } from './enum'

export function getALertChipProps(type: number) {
  switch (type) {
    case LOGTYPES.INFO.value:
      return {
        icon: (
          <CheckRounded
            sx={{
              color: 'common.white',
              height: CHIP_ICON_SIZE,
              width: CHIP_ICON_SIZE,
            }}
          />
        ),
        bgcolor: 'success.main',
        trad: 'log-chip-info',
      }
    case LOGTYPES.WARNING.value:
      return {
        icon: (
          <WarningRounded
            sx={{
              color: 'common.white',
              height: CHIP_ICON_SIZE,
              width: CHIP_ICON_SIZE,
            }}
          />
        ),
        bgcolor: 'error.light',
        trad: 'log-chip-warning',
      }
    case LOGTYPES.ERROR.value:
      return {
        icon: (
          <ClearRounded
            sx={{
              color: 'common.white',
              height: CHIP_ICON_SIZE,
              width: CHIP_ICON_SIZE,
            }}
          />
        ),
        bgcolor: 'error.main',
        trad: 'log-chip-error',
      }
    case LOGTYPES.CRITICAL.value:
      return {
        icon: (
          <BoltRounded
            sx={{
              color: 'common.white',
              height: CHIP_ICON_SIZE,
              width: CHIP_ICON_SIZE,
            }}
          />
        ),
        bgcolor: 'error.dark',
        trad: 'log-chip-critical',
      }
    default:
      return {
        icon: (
          <QuestionMark
            sx={{
              color: 'common.white',
              height: CHIP_ICON_SIZE,
              width: CHIP_ICON_SIZE,
            }}
          />
        ),
        bgcolor: 'primary.main',
        trad: 'log-chip-unknown',
      }
  }
}

export function formatTooltipTitle(tooltipTitle: string, count: number) {
  return count > 1
    ? replace(tooltipTitle, /\(s\)/g, 's')
    : replace(tooltipTitle, /\(s\)/g, '')
}
