import { Button, Grid, Typography } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import { formatUsers } from 'components/organisms/ApplicationsManager/helper'
import { useUsersQuery } from 'domains/users/queries'
import { find } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAddExistingUserInApplicationForm from './form'

function ExistingUserForm({ open, addUser }) {
  const { t } = useTranslation()
  const { data: users, isLoading } = useUsersQuery({ enabled: open })
  const [usersFormatted, setUsersFormatted] = useState([])
  const formExistingUser = useAddExistingUserInApplicationForm()

  useEffect(() => {
    setUsersFormatted(formatUsers(users))
  }, [users])

  const handleAddAdmin = (values) => {
    const userSelectedFull = find(users, { id: values.user?.id })
    addUser({ ...userSelectedFull, receiveMail: false })
    formExistingUser.reset()
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...formExistingUser}>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            margin: 'auto',
            p: 2,
            borderRadius: 1,
          }}
        >
          <ControlledAutocomplete
            name="user"
            TextFieldProps={{
              size: 'small',
              label: t('existed'),
            }}
            loading={isLoading}
            options={usersFormatted}
          />
          <Button
            color="secondary"
            sx={{ mt: 2 }}
            fullWidth
            onClick={formExistingUser.handleSubmit(handleAddAdmin)}
          >
            <Typography variant="button">{t('add')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ExistingUserForm
