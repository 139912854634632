import { InfoOutlined } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'
import { orderBy } from 'lodash'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAlertFilters } from '../../contexts/AlertFiltersContext'
import AlertChip from '../AlertChip'
import { formatAlertLabel, getAlertLabel } from './helper'
import { ApplicationLogAlertProps } from './type'

function ApplicationLogAlert({
  alert,
  ofId,
  ofName,
}: ApplicationLogAlertProps) {
  const { t } = useTranslation()
  const alertLabel = getAlertLabel(alert)
  const dialogLabel = formatAlertLabel([ofName?.toUpperCase(), alertLabel])
  const { handleOpen } = useAlertFilters()
  const { watch } = useFormContext()
  const startDate = watch('startDate')
  const endDate = watch('endDate')

  return (
    <Box
      sx={{
        borderRadius: 2,
        m: 0.5,
        bgcolor: (theme) => theme.palette.primary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          ml: 0.75,
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          color: 'primary.light',
        }}
      >
        {alertLabel}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {orderBy(alert.logsCounts, 'type', 'desc').map((logsCount) => (
          <AlertChip
            key={logsCount.type}
            type={logsCount.type}
            count={logsCount.logCount}
            height={(theme) => theme.spacing(2.5)}
            width={(theme) => theme.spacing(8)}
          />
        ))}
        <Tooltip title={t('logs-detail')} followCursor>
          <InfoOutlined
            onClick={() =>
              handleOpen(
                ofId,
                alert.applicationId,
                alert.method,
                dialogLabel,
                startDate,
                endDate,
                alert.discriminator,
              )
            }
            sx={{ color: 'primary.light', mx: 0.5, cursor: 'pointer' }}
          />
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ApplicationLogAlert
