import { Delete, Download, Edit } from '@mui/icons-material'
import { GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import TooltipCell from 'components/atoms/TooltipCell'
import { useConfirm } from 'contexts/ConfirmContext'
import { useDeleteFileMutation } from 'domains/files/mutations'
import { useFilesQuery } from 'domains/files/queries'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useFilesGrid = ({ handleOpen }) => {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const { data: files, refetch, isFetching } = useFilesQuery()
  const deleteFile = useDeleteFileMutation()

  const handleDelete = (id) =>
    confirm(t('irreversible-action'), t('confirm-delete-file'))
      .then(() => deleteFile.mutate({ id }, { onSuccess: () => refetch() }))
      .catch(() => null)

  const concatenateName = (array) => {
    return array.map((element) => element.name).join(', ')
  }

  const dataColumns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      editable: false,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'key',
      headerName: t('key'),
      flex: 1,
      editable: false,
      renderCell: (params) => <TooltipCell value={params.value} />,
    },
    {
      field: 'applications',
      headerName: t('applications'),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <TooltipCell value={concatenateName(params.value)} />
      ),
    },
    {
      field: 'oFs',
      headerName: t('ofs-management'),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <TooltipCell value={concatenateName(params.value)} />
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      type: 'actions',
      width: 150,
      getActions: ({ id, row: { url } }) => {
        const actions = []
        if (url) {
          actions.push(
            <GridActionsCellItem
              onClick={() => window.open(url, '_blank')}
              icon={<Download />}
              label={t('download')}
            />,
          )
        }
        actions.push(
          <GridActionsCellItem
            onClick={() => handleOpen(id)}
            icon={<Edit />}
            label={t('edit')}
          />,
        )
        actions.push(
          <GridActionsCellItem
            icon={<Delete />}
            onClick={() => handleDelete(id)}
            label={t('delete')}
          />,
        )
        return actions
      },
    },
  ]

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ])

  const columns = dataColumns

  const rows = files || []

  return { columns, rows, isFetching, sortModel, setSortModel }
}

export default useFilesGrid
