import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import Header from 'components/molecules/Header'
import { useAuth } from 'contexts/AuthContext'
import { useOfsHomeQuery } from 'domains/of/queries'
import { CircularProgressSizes } from 'enums/circularProgressSizes'
import { HOME_PATH } from 'enums/paths'
import { isAdminPlus } from 'helpers/role'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import WrappedAlertsList from './components/AlertsList/wrapper'
import OfsList from './components/OfsList'
import SearchBar from './components/SearchBar'
import { sortOfsByStateAndShortName } from './helpers'

function Home() {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { data: ofs, isFetching } = useOfsHomeQuery({ search })
  const sortedOfs = sortOfsByStateAndShortName(ofs)
  const { user } = useAuth()

  return (
    <Box sx={{ mb: 2 }}>
      <Header titles={[{ label: t('app-title'), to: HOME_PATH }]} />
      <Grid container sx={{ mt: 2 }} columnSpacing={1}>
        <Grid item xs={12} sm={8} order={{ xs: 2, sm: 1 }} sx={{ pr: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h2" sx={{ color: 'primary.light' }}>
              {t('synchro-of')}
            </Typography>
            {user.role && isAdminPlus(user.role.key) && (
              <SearchBar setSearch={setSearch} />
            )}
          </Box>
          {isFetching ? (
            <Box sx={{ mt: 15, textAlign: 'center' }}>
              <CircularProgress
                sx={{ color: (theme) => theme.palette.primary.main }}
                size={CircularProgressSizes.LARGE}
              />
            </Box>
          ) : (
            <OfsList ofs={sortedOfs} />
          )}
        </Grid>
        <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 1,
              mb: 3,
              '& svg path': {
                fill: (theme) => theme.palette.primary.light,
              },
            }}
          >
            <NotificationsNoneIcon />
            <Typography
              variant="h2"
              sx={{ color: 'primary.light', ml: 1, textTransform: 'uppercase' }}
            >
              {t('alerts')}
            </Typography>
          </Box>
          <WrappedAlertsList listLength={(ofs || []).length} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home
