import { Add, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateFileForm from '../Create'

function Toolbar() {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{
            minWidth: (theme) => theme.spacing(5.5),
            height: (theme) => theme.spacing(5.5),
            width: (theme) => theme.spacing(5.5),
            bgcolor: 'secondary.main',
            borderRadius: 1,
            color: 'common.black',
            '&:hover': {
              bgcolor: 'secondary.main',
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          <Add />
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('add-file')}
            <IconButton size="small" onClick={() => handleClose()}>
              <Close sx={{ color: 'common.black' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CreateFileForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Toolbar
