import { t } from 'i18next'

export const FrequencyEnum = {
  DAILY: { label: t('daily-short'), value: '10' },
  WEEKLY: { label: t('weekly-short'), value: '20' },
}

export const FrequenciesArray = [
  { label: FrequencyEnum.DAILY.label, value: FrequencyEnum.DAILY.value },
  { label: FrequencyEnum.WEEKLY.label, value: FrequencyEnum.WEEKLY.value },
]
