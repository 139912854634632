import { Add, Close, OpenInNew } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { isAdminPlus } from 'helpers/role'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AuthorizedTokenCreateForm from '../Create'

function Toolbar() {
  const { user } = useAuth()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {!isEmpty(user) && user.role && isAdminPlus(user.role.key) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <Button
            variant="contained"
            href={`https://${process.env.REACT_APP_REFERENTIEL_URL}/swagger`}
            target="_blank"
            sx={{
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              height: (theme) => theme.spacing(5.5),
              textTransform: 'none',
              p: 1,
            }}
            endIcon={<OpenInNew />}
          >
            <Typography>{t('referentiel-api')}</Typography>
          </Button>
          <Button
            sx={{
              minWidth: (theme) => theme.spacing(5.5),
              height: (theme) => theme.spacing(5.5),
              width: (theme) => theme.spacing(5.5),
              bgcolor: 'secondary.main',
              borderRadius: 1,
              color: 'common.black',
              '&:hover': {
                bgcolor: 'secondary.main',
              },
            }}
            variant="contained"
            onClick={handleOpen}
          >
            <Add />
          </Button>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('add-authorized-token')}
            <IconButton size="small" onClick={() => handleClose()}>
              <Close sx={{ color: 'common.black' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AuthorizedTokenCreateForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Toolbar
