export const USER_ME = '/users/me'
export const USERS = '/users'
export const USER_REGISTER = '/users/register'
export const USER = '/users/:id'
export const USER_PWD_KEY = '/users/reset/:key'
export const AUTHENTICATE = '/users/authenticate'
export const REFRESH_TOKEN = '/users/refresh-token'
export const REVOKE_TOKEN = '/users/revoke-token'
export const RESET_PASSWORD = '/users/reset-forgot-password'
export const FORGOT_PASSWORD = '/users/forgot-password'
