import { yupResolver } from '@hookform/resolvers/yup'
import { formattedEndDate, formattedStartDate } from 'formatters/date'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

function useAlertsFilterForm() {
  const defaultValues = {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  }
  const validationSchema = Yup.object().shape({
    startDate: Yup.string().nullable(),
    endDate: Yup.string().nullable(),
  })

  return useForm({ defaultValues, resolver: yupResolver(validationSchema) })
}

export default useAlertsFilterForm
