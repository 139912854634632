import { useSyncMethods } from 'components/organisms/TrainingOrganization/contexts/SyncMethodsContext'
import { useInvokeSingleSyncMethodsMutation } from 'domains/sasMethods/mutations'

function useStartSingleSynchronization({ baseUrl }) {
  const { singleSyncMethods } = useSyncMethods()
  const invokeSyncMethods = useInvokeSingleSyncMethodsMutation(baseUrl, {})

  const startSingleSynchronization = async (i) => {
    await invokeSyncMethods.mutateAsync({
      data: { name: singleSyncMethods[i].name },
    })
  }

  return startSingleSynchronization
}

export default useStartSingleSynchronization
