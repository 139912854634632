import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material'
import FormTypoReadOnly from 'components/atoms/FormTypoReadOnly'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PublicTypesForm from '../PublicTypesForm'
import { CommonAppComponentProps } from './type'

function CommonAppComponent({ app }: CommonAppComponentProps) {
  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 2 }}>
      <Accordion
        sx={{
          mb: 2,
          borderRadius: 1,
          '&.Mui-expanded': { my: 0 },
          '& .MuiCollapse-root': { mb: 2 },
        }}
      >
        <AccordionSummary
          sx={{
            minHeight: 0,
            px: 1,
            '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
              {
                my: 1,
              },
            '&.Mui-expanded': {
              minHeight: 0,
            },
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography width={1}>{app.name}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 1 }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flex: 1 }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="caption"
                      sx={{ ml: 0.5, fontWeight: 'bold' }}
                    >
                      {t('name')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="caption"
                      sx={{ ml: 0.5, fontWeight: 'bold' }}
                    >
                      {t('app-url-for-of')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box sx={{ flex: 1 }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <FormTypoReadOnly value={app.name} />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <FormTypoReadOnly value={app.genericAppUrl} />
                  </Grid>
                  {app?.applicationPublicTypes?.some(
                    (type) => type?.isVisibleInPortal,
                  ) && (
                    <Grid item xs={12}>
                      <PublicTypesForm
                        applicationPublicTypes={app?.applicationPublicTypes}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default CommonAppComponent
