import { yupResolver } from '@hookform/resolvers/yup'
import { validatePhoneNumbers } from 'helpers/validators'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useAddNewUserForm(role) {
  const { t } = useTranslation()

  const defaultValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    receiveMail: false,
    role,
  }

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(t('required-field')),
    lastname: Yup.string().required(t('required-field')),
    email: Yup.string().email(t('invalid-email')).required(t('required-field')),
    phone: Yup.string()
      .nullable(true)
      .test('phone', t('invalid-phone'), validatePhoneNumbers),
  })

  return useForm({ defaultValues, resolver: yupResolver(validationSchema) })
}

export default useAddNewUserForm
