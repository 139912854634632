import { Box, Switch, Typography } from '@mui/material'
import ControlledSwitch from 'components/molecules/ControlledSwitch'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

function PublicTypesForm({
  applicationPublicTypes,
  connectionPublicTypes = [],
  indexConnection = null,
  control = null,
}) {
  const { t } = useTranslation()
  const IsNotCommonPublicTypeForm = !isEmpty(control)
  const hasAnyPublicTypes =
    (IsNotCommonPublicTypeForm &&
      !isEmpty(connectionPublicTypes) &&
      !isEmpty(indexConnection)) ||
    !isEmpty(applicationPublicTypes)

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
        {t('public-types-visibility')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          overflow: 'auto',
          maxHeight: (theme) => theme.spacing(10),
        }}
      >
        {hasAnyPublicTypes &&
          applicationPublicTypes.map(
            (type, index) =>
              (type.isVisibleInPortal ||
                (IsNotCommonPublicTypeForm && type.isEditableOnOfs)) && (
                <Box
                  key={type.publicType.key}
                  sx={{
                    p: 1,
                    alignItems: 'center',
                    display: 'flex',
                    mr: 5,
                  }}
                >
                  {isEmpty(control) ? (
                    <Switch
                      size="small"
                      checked={type.isVisibleInPortal}
                      disabled
                      sx={{
                        '&.MuiSwitch-root .MuiSwitch-switchBase': {
                          color: (theme) => theme.palette.error.main,
                        },
                        '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked': {
                          color: (theme) => theme.palette.success.main,
                        },
                      }}
                    />
                  ) : (
                    <ControlledSwitch
                      name={`connections[${indexConnection}].connectionPublicTypes[${index}].isVisibleInPortal`}
                      disabled={!type.isEditableOnOfs}
                      control={control}
                      sx={{
                        '&.MuiSwitch-root .MuiSwitch-switchBase': {
                          color: (theme) => theme.palette.error.main,
                        },
                        '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked': {
                          color: (theme) => theme.palette.success.main,
                        },
                      }}
                    />
                  )}
                  <Typography
                    variant="body2"
                    sx={
                      IsNotCommonPublicTypeForm && type.isEditableOnOfs
                        ? {
                            fontWeight: 'bold',
                            color: 'common.black',
                          }
                        : {
                            fontWeight: 'normal',
                            color: 'primary.main',
                          }
                    }
                  >
                    {type.publicType.name}
                  </Typography>
                </Box>
              ),
          )}
      </Box>
    </Box>
  )
}

export default PublicTypesForm
