import { isEmpty } from 'lodash'
import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { useAuth } from './AuthContext'

type AuthorizationContextType = {
  userIs: (values: string[]) => boolean
}

const AuthorizationContext = createContext<AuthorizationContextType>(undefined)

function AuthorizationProvider({ children }) {
  const { user } = useAuth()

  const userIs = useCallback(
    (values) => {
      return !isEmpty(values.find((element) => element === user.role?.key))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  )

  const values = useMemo(
    () => ({
      userIs,
    }),
    [userIs],
  )
  return (
    <AuthorizationContext.Provider value={values}>
      {children}
    </AuthorizationContext.Provider>
  )
}

const useAuthorization = () => {
  const context = useContext(AuthorizationContext)
  if (context === undefined) {
    throw new Error(
      'useAuthorization must be used within a AuthorizationProvider',
    )
  }
  return context
}

export { AuthorizationProvider, useAuthorization }
