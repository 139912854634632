import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import CircleIcon from '@mui/icons-material/Circle'
import React from 'react'
import { Link } from 'react-router-dom'

function Titles({ titles = [] }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {titles &&
        titles.map((title, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${title}-${index}`}>
              {index !== 0 && (
                <Box
                  ml={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& svg': {
                      width: (theme) => theme.spacing(1.5),
                      height: (theme) => theme.spacing(1.5),
                    },
                  }}
                >
                  <CircleIcon />
                </Box>
              )}
              <Box ml={index !== 0 ? 2 : 0}>
                <Typography variant="h1" noWrap component="div">
                  {title?.to ? (
                    <Link to={title.to} style={{ color: 'inherit' }}>
                      {title.label}
                    </Link>
                  ) : (
                    title.label
                  )}
                </Typography>
              </Box>
            </React.Fragment>
          )
        })}
    </Box>
  )
}

export default Titles
