export const singleSyncWrapperSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}

export const globalSyncWrapperSx = {
  ...singleSyncWrapperSx,
  ...{
    justifyContent: 'left',
    mb: (theme) => theme.spacing(1),
  },
}

export const singleSyncTypographyCommonSx = {
  ...singleSyncWrapperSx,
  ...{
    mb: (theme) => theme.spacing(0.5),
    ml: (theme) => theme.spacing(4.5),
    pr: (theme) => theme.spacing(4.5),
  },
}

export const btnCommonsx = {
  bgcolor: 'common.black',
  color: 'primary.light',
  '&:disabled': {
    bgcolor: 'primary.light',
    color: 'primary.secondary',
  },
  borderRadius: '100%',
  width: (theme) => theme.spacing(3.5),
  height: (theme) => theme.spacing(3.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  mr: (theme) => theme.spacing(1),
}

export const subSyncStateCommonsx = {
  bgcolor: 'none',
  color: 'primary.light',
  width: (theme) => theme.spacing(3.5),
  height: (theme) => theme.spacing(3.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const successSubSyncStateCommonsx = {
  ...subSyncStateCommonsx,
  ...{
    color: 'success.main',
  },
}

export const errorSubSyncStateCommonsx = {
  ...subSyncStateCommonsx,
  ...{
    color: 'error.main',
  },
}

export const runningSubSyncStateCommonsx = {
  ...subSyncStateCommonsx,
  ...{
    color: 'secondary.main',
  },
}

export const warningSubSyncStateCommonsx = {
  ...subSyncStateCommonsx,
  ...{
    color: 'error.light',
  },
}
