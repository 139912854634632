import { SaveAsRounded } from '@mui/icons-material'
import { Box, Button, Divider, Grid, MenuItem, Typography } from '@mui/material'
import ControlledCheckbox from 'components/molecules/ControlledCheckbox'
import ControlledSelect from 'components/molecules/ControlledSelect'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import ControlledWysiwyg from 'components/molecules/ControlledWysiwyg'
import { WysiwygUploadVariant } from 'components/molecules/ControlledWysiwyg/enum'
import ExistingUserForm from 'components/molecules/ExistingUserForm'
import NewUserForm from 'components/molecules/NewUserForm'
import UsersForm from 'components/molecules/UsersForm'
import UsersPopUp from 'components/molecules/UsersPopUp'
import { findRole } from 'components/organisms/OFSManager/helpers'
import { useConfirm } from 'contexts/ConfirmContext'
import { useCategoriesQuery } from 'domains/categories/queries'
import { RoleEnum } from 'domains/roles/enums'
import { useRolesQuery } from 'domains/roles/queries'
import { isEmpty, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import MethodsForm from '../MethodsForm'
import PublicTypesForm from '../PublicTypesForm'
import { ApplicationsFormProps } from './type'
import ApplicationFormTitle from '../ApplicationFormTitle'

function ApplicationsForm({
  control,
  onSubmit,
  variant,
}: ApplicationsFormProps) {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const { setValue } = useFormContext()
  const [openDialog, setOpenDialog] = useState(false)
  const { data: roles } = useRolesQuery()
  const { data: categories } = useCategoriesQuery()

  const handleChangeDialog = () => {
    setOpenDialog(!openDialog)
  }

  const {
    fields: users,
    append: addAdmin,
    remove: removeUser,
  } = useFieldArray({
    control,
    name: 'users',
  })

  const {
    fields: methods,
    prepend: addMethod,
    remove: removeMethod,
  } = useFieldArray({
    control,
    name: 'methods',
  })

  const { fields: applicationPublicTypes } = useFieldArray({
    control,
    name: 'applicationPublicTypes',
  })

  const handleDelete = (index) => {
    confirm(t('user-delete-confirmation')).then(() => removeUser(index))
  }

  const selectedMethods = useWatch({
    control,
    name: `methods`,
  })

  const isWSRestSelected = useWatch({
    control,
    name: 'isWSRest',
  })

  const isGenericAppSelected = useWatch({
    control,
    name: `isGenericApp`,
  })
  const isCommonAppSelected = useWatch({
    control,
    name: `isCommonApp`,
  })
  const logo = useWatch({
    control,
    name: `logo`,
  })
  const picture = useWatch({
    control,
    name: `picture`,
  })

  useEffect(() => {
    if (isCommonAppSelected) {
      setValue('isGenericApp', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommonAppSelected])

  useEffect(() => {
    if (!isGenericAppSelected) {
      setValue('isCommonApp', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenericAppSelected])

  return (
    <Box>
      <Grid
        container
        sx={{
          mt: 2,
          bgcolor: 'common.white',
          borderRadius: 1,
          width: '100%',
        }}
        component="form"
        onSubmit={onSubmit}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ApplicationFormTitle title={t('general-info')} />
          <ControlledCheckbox name="isActive" label={t('active')} />
        </Grid>
        <Grid
          item
          xs={12}
          md={variant === 'edit' ? 6 : 12}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ControlledTextField
            name="name"
            label={t('name')}
            fullWidth
            size="small"
          />
        </Grid>
        {variant === 'edit' && (
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="folderName"
              label={t('foldername')}
              fullWidth
              size="small"
              disabled={variant === 'edit'}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Typography>{t('app-logo')}</Typography>
          <Box sx={{ display: 'flex' }}>
            <ControlledUploadFile
              name="logo"
              label={t('upload-file')}
              deletionButton
              cutName
            />
            {!isEmpty(logo?.url) && (
              <Box
                component="img"
                sx={{
                  width: 'auto',
                  height: (theme) => theme.spacing(10),
                  maxWidth: (theme) => theme.spacing(30),
                  border: 1,
                }}
                alt={logo?.name}
                src={logo?.url}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography>{t('app-picture')}</Typography>
          <Box sx={{ display: 'flex' }}>
            <ControlledUploadFile
              name="picture"
              label={t('upload-file')}
              deletionButton
              cutName
            />
            {!isEmpty(picture?.url) && (
              <Box
                component="img"
                sx={{
                  width: 'auto',
                  height: (theme) => theme.spacing(10),
                  maxWidth: (theme) => theme.spacing(30),
                  border: 1,
                }}
                alt={picture?.name}
                src={picture?.url}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
          <Box sx={{ height: 1 }}>
            <ApplicationFormTitle title={t('description')} />
            <Box mt={2} sx={{ height: '90%' }}>
              <ControlledWysiwyg
                fieldErrorName="joditError"
                name="description"
                label=""
                variant={WysiwygUploadVariant.BASE64}
                allowResizeX={false}
                allowResizeY={false}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{ ml: 2, fontWeight: 'bold', pt: 0 }}
            >
              {t('app-visibility')}
            </Typography>
            <Box
              sx={{
                flex: 1,
              }}
              mx={2}
            >
              <Divider
                sx={{
                  bgcolor: (theme) => theme.palette.secondary.main,
                  height: (theme) => theme.spacing(0.3),
                }}
              />
            </Box>
          </Box>

          <PublicTypesForm
            applicationPublicTypes={applicationPublicTypes}
            variant={variant}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <ApplicationFormTitle title={t('application-type')} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: 2 }}>
          <ApplicationFormTitle title={t('category')} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between">
            <ControlledCheckbox
              name="isWSRest"
              disabled={isGenericAppSelected && !isWSRestSelected}
              label={t('ws-rest')}
            />
            <ControlledCheckbox
              name="isGenericApp"
              disabled={isWSRestSelected && !isGenericAppSelected}
              label={t('generic-app')}
            />
            <ControlledCheckbox
              name="isCommonApp"
              disabled={!isGenericAppSelected}
              label={t('common-app')}
              sx={{ mr: 2 }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {!isNil(categories) && (
            <ControlledSelect
              control={control}
              fullWidth
              size="small"
              name="categoryId"
            >
              {categories.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </ControlledSelect>
          )}
        </Grid>
        {isGenericAppSelected && (
          <Grid item xs={12}>
            <ControlledTextField
              name="genericAppUrl"
              fullWidth
              size="small"
              label={t('generic-app-url')}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <UsersForm
            handleChangeDialog={handleChangeDialog}
            users={users}
            removeUser={handleDelete}
            userCategory="user"
            text={t('users')}
          />
          {isWSRestSelected && (
            <MethodsForm
              selectedMethods={selectedMethods}
              methods={methods}
              addMethod={addMethod}
              removeMethod={removeMethod}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            startIcon={<SaveAsRounded />}
          >
            {t('save')}
          </Button>
        </Grid>
      </Grid>
      <UsersPopUp
        open={openDialog}
        onClose={handleChangeDialog}
        addUser={addAdmin}
        withExistingUsers={false}
        existingUserForm={ExistingUserForm}
        newUserForm={NewUserForm}
        text="add-user"
        role={findRole(roles, RoleEnum.ADMIN_APP.key)}
      />
    </Box>
  )
}

export default ApplicationsForm
