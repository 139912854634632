import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import ControlledSwitch from 'components/molecules/ControlledSwitch'
import ControlledTextField from 'components/molecules/ControlledTextField'
import React from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { titleHeightWithMarginBottomSx } from '../../style'
import PlanningInfoForm from '../PlanningInfoForm'

function OFSGeneralInfoForm({ control, variant }) {
  const { t } = useTranslation()

  const url = useWatch({
    control,
    name: 'url',
  })

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ...titleHeightWithMarginBottomSx,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {t('general-info')}
            </Typography>
            <Box
              sx={{
                flex: 1,
              }}
              mx={2}
            >
              <Divider
                sx={{
                  bgcolor: (theme) => theme.palette.secondary.main,
                  height: (theme) => theme.spacing(0.3),
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="body2">{t('is-active')}</Typography>
              <ControlledSwitch
                name="isActive"
                control={control}
                disabled={false}
                sx={{
                  '&.MuiSwitch-root .MuiSwitch-switchBase': {
                    color: (theme) => theme.palette.error.main,
                  },
                  '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked': {
                    color: (theme) => theme.palette.success.main,
                  },
                }}
              />
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="name"
                label={t('name')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="shortName"
                label={t('ofs-shortname')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="code"
                label={t('of-code')}
                fullWidth
                size="small"
              />
            </Grid>
            {variant === 'edit' && (
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="url"
                  label={t('api-url')}
                  fullWidth
                  size="small"
                  disabled
                  InputProps={{
                    endAdornment: url && (
                      <IconButton
                        href={`https://${url}/swagger`}
                        target="_blank"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            )}
            {variant === 'edit' && (
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="_LogFolderName"
                  label={t('foldername')}
                  fullWidth
                  size="small"
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PlanningInfoForm />
      </Grid>
    </>
  )
}

export default OFSGeneralInfoForm
