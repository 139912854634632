import { useMutationWrapper } from 'domains/helpers'
import { PUBLIC_TYPE, PUBLIC_TYPES } from './templates'

export function useUpdatePublicTypesMutation(options?) {
  return useMutationWrapper('PUT', PUBLIC_TYPE, options)
}

export function usePatchPublicTypesMutation(options?) {
  return useMutationWrapper('PATCH', PUBLIC_TYPE, options)
}

export function useDeletePublicTypesMutation(options?) {
  return useMutationWrapper('DELETE', PUBLIC_TYPE, options)
}

export function useCreatePublicTypesMutation(options?) {
  return useMutationWrapper('POST', PUBLIC_TYPES, options)
}
