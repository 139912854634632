import { Box, Button, Typography } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledDatePicker from 'components/molecules/ControlledDatePicker'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { formatLogTypesToForm } from 'components/organisms/TrainingOrganization/components/helpers'
import { useLogFilters } from 'components/organisms/TrainingOrganization/contexts/LogFiltersContext'
import { LOGTYPES } from 'domains/logs/enums'
import { map, trim } from 'lodash'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function GlobalFilterLogsForm() {
  const { t } = useTranslation()
  const logsFilterForm = useFormContext()
  const {
    setFilterType,
    setFilterStartDate,
    setFilterEndDate,
    setFilterMethod,
    setFilterDescription,
  } = useLogFilters()

  const onSubmit = (values) => {
    setFilterType(formatLogTypesToForm(values.types))
    setFilterStartDate(values.dateStart)
    setFilterEndDate(values.dateEnd)
    setFilterMethod(trim(values.method))
    setFilterDescription(trim(values.description))
  }

  const dateStartValue = logsFilterForm.watch('dateStart')
  const dateEndValue = logsFilterForm.watch('dateEnd')

  return (
    <Box
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      <Typography variant="body1" sx={{ textTransform: 'uppercase' }}>
        {t(`log-filters`)}
      </Typography>
      <Box
        component="form"
        onSubmit={logsFilterForm.handleSubmit(onSubmit)}
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <Box
            sx={{
              flex: 1,
              pt: 1,
              maxWidth: (theme) => theme.spacing(25),
            }}
          >
            <ControlledDatePicker
              name="dateStart"
              datetime
              label={t('start-date')}
              fullWidth
              size="small"
              InputProps={{ inputProps: { max: dateEndValue } }}
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: (theme) => theme.typography.tag.fontSize,
                  height: '100%',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              pt: 1,
              maxWidth: (theme) => theme.spacing(25),
            }}
          >
            <ControlledDatePicker
              name="dateEnd"
              datetime
              label={t('end-date')}
              fullWidth
              size="small"
              InputProps={{ inputProps: { min: dateStartValue } }}
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: (theme) => theme.typography.tag.fontSize,
                  height: '100%',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              pt: 1,
              overflowX: 'hidden',
              '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root':
                {
                  flexWrap: 'nowrap',
                  overflowX: 'hidden',
                },
            }}
          >
            <ControlledAutocomplete
              name="types"
              TextFieldProps={{ label: t('log-types'), size: 'small' }}
              loading={false}
              options={map(LOGTYPES, (value) => value)}
              multiple
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              sx={{
                flex: 1,
                '& .MuiInputBase-root': {
                  fontSize: (theme) => theme.typography.chip.fontSize,
                },
                '& .MuiChip-label': {
                  fontSize: (theme) => theme.typography.chip.fontSize,
                },
                '& .MuiInputLabel-root': {
                  fontSize: (theme) => theme.typography.body2.fontSize,
                },
                '& .MuiAutocomplete-root, .MuiAutocomplete-tag': {
                  margin: '2px',
                },
              }}
              disableCloseOnSelect
              disableClearable
              ChipProps={{ size: 'small' }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <ControlledTextField
            name="method"
            label={t('method')}
            size="small"
            sx={{ flex: 4 }}
          />
          <ControlledTextField
            name="description"
            label={t('description')}
            size="small"
            sx={{ flex: 5 }}
          />
          <Button
            size="small"
            sx={{
              bgcolor: 'secondary.main',
              borderRadius: 1,
              color: 'common.black',
              boxShadow: 'none',
              transition: 'border 0s',
              '&:hover': {
                bgcolor: 'secondary.main',
                boxShadow: 'none',
              },
            }}
            type="submit"
          >
            {t('apply')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default GlobalFilterLogsForm
