import { Components } from '@mui/material'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { black, white } from './palette'

const components: Components = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        backgroundColor: white,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundColor: white,
        color: black,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: 'small',
      color: 'success',
    },
  },
}

export default components

// https://mui.com/material-ui/customization/theme-components/
