/* eslint-disable react/jsx-props-no-spreading */
import { SaveAsRounded } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import {
  formatApplications,
  formatOfs,
} from 'components/organisms/UsersManager/helper'
import { useApplicationsQuery } from 'domains/application/queries'
import { useOfsQuery } from 'domains/of/queries'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type FilesFormProps = {
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>
}

function FilesForm({ onSubmit }: FilesFormProps) {
  const { t } = useTranslation()
  const { data: ofs, isLoading: ofsIsLoading } = useOfsQuery()
  const { data: applications, isLoading: applicationsIsLoading } =
    useApplicationsQuery()
  const [applicationsFormatted, setApplicationsFormatted] = useState([])
  const [ofsFormatted, setOfsFormatted] = useState([])

  useEffect(() => {
    setApplicationsFormatted(formatApplications(applications))
  }, [applications])

  useEffect(() => {
    setOfsFormatted(formatOfs(ofs))
  }, [ofs])

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Box mb={2}>
        <ControlledAutocomplete
          name="applications"
          TextFieldProps={{ size: 'small', label: t('applications') }}
          loading={applicationsIsLoading}
          options={applicationsFormatted}
          multiple
        />
      </Box>
      <Box mb={2}>
        <ControlledAutocomplete
          name="oFs"
          TextFieldProps={{ size: 'small', label: t('ofs-management') }}
          loading={ofsIsLoading}
          options={ofsFormatted}
          multiple
        />
      </Box>
      <Box mb={2}>
        <ControlledTextField
          name="name"
          label={t('file-name')}
          fullWidth
          size="small"
        />
      </Box>
      <Box mb={2}>
        <ControlledTextField
          name="key"
          label={t('key')}
          fullWidth
          size="small"
        />
      </Box>

      <Box mb={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: 'auto',
            pt: 2,
            pb: 3,
          }}
        >
          <ControlledUploadFile name="file" label={t('upload-file')} />
        </Box>
        <Button
          fullWidth
          variant="contained"
          type="submit"
          color="secondary"
          startIcon={<SaveAsRounded />}
        >
          {t('save')}
        </Button>
      </Box>
    </Box>
  )
}

export default FilesForm
