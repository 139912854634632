import { Box, Grid } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { SOURCE } from 'domains/of/enums'
import React from 'react'
import { useTranslation } from 'react-i18next'

function YpareoSupplier({ supplier }) {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        mr: 1,
        display: supplier === SOURCE.YPAREO.value ? 'block' : 'none',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name="address"
            label={t('ypareo-rest')}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            name="token"
            label={t('token')}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default YpareoSupplier
