import { Box, TablePagination } from '@mui/material'
import { LOGLINE_PER_PAGE } from 'components/organisms/TrainingOrganization/enums'
import { toNumber } from 'lodash'
import React from 'react'
import LogLines from '../LogLines'
import LogsHeader from '../LogsHeader'
import { LogsTabProps } from './type'

function LogsTab({
  data,
  title = <Box sx={{ flex: 1 }} />,
  isFetching,
  customHeaderButtons,
  methodFlexValue,
  descriptionFlexValue,
  totalLogs,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  children,
  sx,
}: LogsTabProps) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(toNumber(event.target.value))
    setPage(0)
  }

  return (
    <Box
      sx={{
        backgroundColor: 'primary.dark',
        borderRadius: 5,
        flexWrap: 'nowrap',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <LogsHeader
          title={title}
          methodFlexValue={methodFlexValue}
          descriptionFlexValue={descriptionFlexValue}
        >
          {customHeaderButtons}
        </LogsHeader>
        <LogLines
          logs={data?.logs}
          methodFlexValue={methodFlexValue}
          descriptionFlexValue={descriptionFlexValue}
          isLoading={isFetching}
        />
        <TablePagination
          component="div"
          count={totalLogs}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={LOGLINE_PER_PAGE}
          sx={{
            '.MuiTablePagination-toolbar': {
              color: 'primary.light',
            },
            '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-actions':
              {
                fontSize: (theme) => theme.typography.table.fontSize,
              },
          }}
          SelectProps={{
            sx: {
              color: 'primary.light',
              '.MuiSvgIcon-root ': {
                color: 'primary.light',
              },
            },
          }}
        />
        {children}
      </Box>
    </Box>
  )
}

export default LogsTab
