import AppParamsManager from 'components/organisms/AppParamsManager'
import Header from 'components/templates/AdminLayout/components/Header'
import { APP_PARAMS_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'

function AppParams() {
  const { t } = useTranslation()
  return (
    <>
      <Header
        defaultTitles={[
          { label: t('admin') },
          { label: t('app-params'), to: APP_PARAMS_PATH },
        ]}
      />
      <AppParamsManager />
    </>
  )
}

export default AppParams
