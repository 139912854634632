import { Close, FilterList, Refresh } from '@mui/icons-material'
import {
  Badge,
  Box,
  DialogContent,
  DialogTitle,
  TablePagination,
  Typography,
} from '@mui/material'
import FullScreenFilterLogsForm from 'components/organisms/LogTabFilter/components/FullScreenFilterLogsForm'
import LogLines from 'components/organisms/TrainingOrganization/components/LogLines'
import { formatLogTypesToForm } from 'components/organisms/TrainingOrganization/components/helpers'
import { LOGLINE_PER_PAGE } from 'components/organisms/TrainingOrganization/enums'
import DialogEscapeClose from 'components/templates/DialogEscapeClose'
import { toNumber, trim } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlertFilters } from '../../contexts/AlertFiltersContext'
import { DESCRIPTION_FLEX, METHOD_FLEX } from './enum'
import useAlertsFilterForm from './form'

function AlertDetailDialog({ dateStart, dateEnd }) {
  const { t } = useTranslation()
  const [openFilters, setOpenFilters] = useState(false)
  const [hideMethods, setHideMethods] = useState(true)

  const {
    openDialog,
    setOpenDialog,
    data,
    dialogTitle,
    pageIndex,
    setPageIndex,
    rowsPerPage,
    setRowsPerPage,
    setDescription,
    setTypes,
    setStartDate,
    setEndDate,
    method,
    refetch,
    isFetching,
    setMethodSearch,
  } = useAlertFilters()

  const { form, resetFormValues, resetFormValuesOnly, fieldsAreEmpty } =
    useAlertsFilterForm(dateStart, dateEnd, method)

  const onSubmit = (values) => {
    setHideMethods(trim(method) === trim(values.method))
    setTypes(formatLogTypesToForm(values.types))
    setStartDate(values.dateStart)
    setEndDate(values.dateEnd)
    setDescription(trim(values.description))
    setMethodSearch(trim(values.method))
  }

  const onClose = () => {
    setOpenDialog(false)
    setHideMethods(true)
    resetFormValues({})
  }

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(toNumber(event.target.value))
    setPageIndex(0)
  }

  useEffect(() => {
    if (openDialog) {
      resetFormValues({ method, dateStart, dateEnd })
      setPageIndex(0)
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog])

  return (
    <DialogEscapeClose
      maxWidth="xl"
      fullWidth
      open={!!openDialog}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: '100%',
          borderRadius: 4,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'common.black',
          color: 'primary.light',
          p: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            m: 1,
            alignItems: 'center',
          }}
        >
          <Typography display="inline" variant="h2" sx={{ flex: 1 }}>
            {dialogTitle}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            <Refresh
              onClick={() => !isFetching && refetch()}
              sx={{
                cursor: !isFetching && 'pointer',
                color: isFetching && 'primary.main',
              }}
            />
            <Badge
              overlap="circular"
              variant="dot"
              invisible={fieldsAreEmpty}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'secondary.main',
                },
              }}
            >
              <FilterList
                onClick={() => setOpenFilters(!openFilters)}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Badge>
            <Close
              onClick={onClose}
              sx={{
                cursor: 'pointer',
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            mx: 0.5,
            mb: 0.5,
          }}
        >
          <Box
            sx={{
              px: 0.5,
              width: (theme) => theme.spacing(6),
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <Typography variant="table">{t('log-type')}</Typography>
          </Box>
          <Box
            sx={{
              px: 0.5,
              width: (theme) => theme.spacing(16),
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <Typography variant="table">{t('addition')}</Typography>
          </Box>
          {!hideMethods && (
            <Box
              sx={{
                px: 0.5,
                flex: METHOD_FLEX,
                minWidth: 0,
                wordWrap: 'break-word',
                display: 'flex',
                alignContent: 'center',
              }}
            >
              <Typography variant="table">{t('method')}</Typography>
            </Box>
          )}
          <Box
            sx={{
              px: 0.5,
              flex: DESCRIPTION_FLEX,
              minWidth: 0,
              wordWrap: 'break-word',
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <Typography variant="table">{t('description')}</Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: 2,
          px: 0,
          bgcolor: 'primary.dark',
        }}
      >
        <LogLines
          logs={data?.logs ?? []}
          methodFlexValue={METHOD_FLEX}
          descriptionFlexValue={DESCRIPTION_FLEX}
          isLoading={isFetching}
          hideMethods={hideMethods}
        />
        <Box sx={{ height: (theme) => theme.spacing(4) }}>
          <TablePagination
            component="div"
            count={data?.totalLogs ?? 0}
            page={pageIndex}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={LOGLINE_PER_PAGE}
            sx={{
              '.MuiTablePagination-toolbar': {
                color: 'primary.light',
              },
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-actions':
                {
                  fontSize: (theme) => theme.typography.table.fontSize,
                },
            }}
            SelectProps={{
              sx: {
                color: 'primary.light',
                '.MuiSvgIcon-root ': {
                  color: 'primary.light',
                },
              },
            }}
          />
        </Box>
      </DialogContent>
      <FullScreenFilterLogsForm
        open={openFilters}
        onSubmit={onSubmit}
        form={form}
        resetMethod={() => resetFormValuesOnly({ method })}
      />
    </DialogEscapeClose>
  )
}

export default AlertDetailDialog
