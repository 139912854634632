import { TextField } from '@mui/material'
import { get } from 'lodash'
import React, { forwardRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ControlledTextFieldProps } from './types'

const ControlledTextField = forwardRef(
  (props: ControlledTextFieldProps, ref) => {
    const {
      name = 'NOT_PROVIDED',
      control: controlFromProps,
      type,
      InputLabelProps = {},
      ...rest
    } = props
    const formContext = useFormContext()
    const controlFromContext = get(formContext, 'control')
    const { t } = useTranslation()
    const controller = useController({
      name,
      control: controlFromProps || controlFromContext,
    })
    const {
      field,
      fieldState: { error },
    } = controller

    if (field.value === undefined) {
      console.error(
        `[FTEL-ERROR] : Le composant ControlledTextField '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
      )
    }

    let defaultValue: number | string = ''
    if (type === 'number') {
      defaultValue = 0
    }

    return (
      <TextField
        size="small"
        ref={ref}
        {...field}
        {...rest}
        type={type}
        error={!!error}
        value={field.value ?? defaultValue}
        InputLabelProps={{
          shrink: `${field.value}`.length > 0,
          ...InputLabelProps,
        }}
        helperText={error && get(error, 'message', t('invalid-field'))}
      />
    )
  },
)

export default ControlledTextField
