import { useQueryWrapper } from 'domains/helpers'
import { APP_PARAMETER, APP_PARAMETERS } from './templates'
import { AppParameter } from './types'

export function useGetAppParametersQuery(params?, options?) {
  return useQueryWrapper<AppParameter[]>('GET', APP_PARAMETERS, params, options)
}

export function useGetAppParameterQuery(params?, options?) {
  return useQueryWrapper<AppParameter>('GET', APP_PARAMETER, params, options)
}
