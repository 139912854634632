import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SyncIcon from '@mui/icons-material/Sync'
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useApplicationsQuery } from 'domains/application/queries'
import {
  useOfGenerateTokenQuery,
  useOfUrlFusionFieldsQuery,
} from 'domains/of/queries'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PublicTypesForm from '../PublicTypesForm'
import {
  formatApplications,
  formatApplicationsPublicTypes,
} from '../Toolbar/helpers'
import { formatAndSortFusionFields } from './helper'

function OFSConnectionsComponent({
  indexConnection,
  removeConnection,
  connectionApplicationsId,
  variant,
}) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { setValue, control, getValues } = useFormContext()
  const commonSx = {
    mr: 1,
  }
  const { data: fusionFields, isLoading: loadingFusionField } =
    useOfUrlFusionFieldsQuery()
  const { data: applications, isLoading } = useApplicationsQuery({
    isActive: true,
    isCommonApp: false,
  })
  const [applicationsFormatted, setApplicationsFormatted] = useState([])
  const [applicationPublicTypesFormatted, setApplicationPublicTypesFormatted] =
    useState([])

  useEffect(() => {
    setApplicationsFormatted(
      formatApplications(applications, connectionApplicationsId),
    )
    setApplicationPublicTypesFormatted(
      formatApplicationsPublicTypes(applications, connectionApplicationsId),
    )
  }, [applications, connectionApplicationsId])

  const { data: token, refetch } = useOfGenerateTokenQuery({})

  const generateToken = () => {
    refetch()
    setValue(`connections[${indexConnection}].token`, token, {
      shouldDirty: true,
    })
  }
  const handleOpenFusionFieldMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseFusionFieldMenu = () => {
    setAnchorEl(null)
  }

  const handleSelectFusionField = (event) => {
    setValue(
      `connections[${indexConnection}].connectionUrl`,
      `${getValues(`connections[${indexConnection}].connectionUrl`) ?? ''}{{${
        event.currentTarget.dataset.myValue
      }}}`,
      { shouldDirty: true },
    )
    setAnchorEl(null)
  }

  const nameControl = useWatch({
    control,
    name: `connections[${indexConnection}].name`,
  })
  const connectionPublicTypes = useWatch({
    control,
    name: `connections[${indexConnection}].connectionPublicTypes`,
  })

  const appControl = useWatch({
    control,
    name: `connections[${indexConnection}].application`,
  })

  const applicationPublicTypes =
    applicationPublicTypesFormatted?.find((app) => app.id === appControl?.id)
      ?.applicationPublicTypes ?? []

  const showApplicationPublicTypes = !isEmpty(
    applicationPublicTypes.filter(
      (type) => type.isVisibleInPortal || type.isEditableOnOfs,
    ),
  )

  const tokenControl = useWatch({
    control,
    name: `connections[${indexConnection}].token`,
  })

  useEffect(() => {
    if (isEmpty(appControl)) {
      setValue(`connections[${indexConnection}].token`, '')
      setValue(`connections[${indexConnection}].connectionPublicTypes`, [])
    } else {
      if (!appControl.isWSRest) {
        setValue(`connections[${indexConnection}].token`, '')
      }
      if (variant === 'create')
        setValue(`connections[${indexConnection}].connectionUrl`, '')
      if (!isEmpty(appControl?.genericAppUrl)) {
        setValue(
          `connections[${indexConnection}].connectionUrl`,
          appControl.genericAppUrl,
        )
      }
      if (!isEmpty(appControl.applicationPublicTypes) && variant === 'edit')
        appControl.applicationPublicTypes.forEach((type, index) => {
          setValue(
            `connections[${indexConnection}].connectionPublicTypes[${index}].publicType`,
            type.publicType,
          )
          if (connectionPublicTypes[index]?.isVisibleInPortal === undefined) {
            setValue(
              `connections[${indexConnection}].connectionPublicTypes[${index}].isVisibleInPortal`,
              type.isVisibleInPortal,
            )
          }
        })
      if (!isEmpty(appControl.applicationPublicTypes) && variant === 'create') {
        const newTab = []
        appControl.applicationPublicTypes.forEach((type) => newTab.push(type))
        newTab.forEach((type, index) => {
          setValue(
            `connections[${indexConnection}].connectionPublicTypes[${index}].publicType`,
            type.publicType,
          )
          setValue(
            `connections[${indexConnection}].connectionPublicTypes[${index}].isVisibleInPortal`,
            type.isVisibleInPortal,
          )
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appControl])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ flex: 1, ...commonSx }}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {t('name')}
            </Typography>
            <Box sx={{ pr: 3 }}>
              <ControlledTextField
                name={`connections[${indexConnection}].name`}
                InputLabelProps={{ shrink: false }}
                label={isEmpty(nameControl) ? t('name') : ''}
                fullWidth
                sx={{ bgcolor: 'common.white', borderRadius: 1 }}
              />
            </Box>
          </Grid>
          <>
            <Grid item xs={12} md={8}>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {t('app-url-for-of')}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <ControlledTextField
                  name={`connections[${indexConnection}].connectionUrl`}
                  fullWidth
                  InputProps={{
                    endAdornment: !appControl?.isGenericApp && (
                      <Tooltip title={t('hover-url-fusion-fields')}>
                        <IconButton
                          size="small"
                          sx={{
                            width: (theme) => theme.spacing(3),
                            height: (theme) => theme.spacing(3),
                          }}
                          onClick={(e) => handleOpenFusionFieldMenu(e)}
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  disabled={appControl?.isGenericApp}
                  sx={{ bgcolor: 'common.white', borderRadius: 1 }}
                />
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseFusionFieldMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {!loadingFusionField &&
                  formatAndSortFusionFields(t, fusionFields ?? []).map(
                    (field) => (
                      <MenuItem
                        key={field.value}
                        data-my-value={field.value}
                        onClick={(e) => handleSelectFusionField(e)}
                      >
                        {field.label}
                      </MenuItem>
                    ),
                  )}
              </Menu>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {t('application')}
              </Typography>
              <Box sx={{ pr: 3 }}>
                <ControlledAutocomplete
                  name={`connections[${indexConnection}].application`}
                  TextFieldProps={{
                    size: 'small',
                    label: isEmpty(appControl) ? t('application') : '',
                    sx: { bgcolor: 'common.white', borderRadius: 1 },
                    InputLabelProps: { shrink: false },
                  }}
                  loading={isLoading}
                  options={applicationsFormatted}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {t('token')}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <ControlledTextField
                  name={`connections[${indexConnection}].token`}
                  InputLabelProps={{ shrink: false }}
                  label={isEmpty(tokenControl) ? t('token') : ''}
                  fullWidth
                  disabled
                  sx={{
                    bgcolor: !appControl?.isWSRest
                      ? 'primary.light'
                      : 'common.white',
                    borderRadius: 1,
                  }}
                  InputProps={{
                    endAdornment: !isEmpty(appControl) &&
                      appControl?.isWSRest && (
                        <Tooltip title={t('generate-token')}>
                          <IconButton
                            size="small"
                            sx={{
                              width: (theme) => theme.spacing(3),
                              height: (theme) => theme.spacing(3),
                            }}
                            onClick={generateToken}
                          >
                            <SyncIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                  }}
                />
              </Box>
            </Grid>
            {showApplicationPublicTypes && (
              <Grid item xs={12}>
                <PublicTypesForm
                  connectionPublicTypes={connectionPublicTypes}
                  applicationPublicTypes={applicationPublicTypes}
                  indexConnection={indexConnection}
                  control={control}
                />
              </Grid>
            )}
          </>
        </Grid>
      </Box>
    </Box>
  )
}

export default OFSConnectionsComponent
