import { SaveAsRounded } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function PublicTypeForm({ form, onSubmit }) {
  const { t } = useTranslation()

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <Box sx={{ mb: 2 }}>
          <ControlledTextField
            name="name"
            label={t('name')}
            fullWidth
            size="small"
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <ControlledTextField
            name="code"
            label={t('code')}
            fullWidth
            size="small"
          />
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          fullWidth
          sx={{ mt: 2 }}
          startIcon={<SaveAsRounded />}
        >
          {t('save')}
        </Button>
      </Box>
    </FormProvider>
  )
}

export default PublicTypeForm
