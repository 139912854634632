import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { usePatchMailFusionFieldMutation } from 'domains/mailFusionFields/mutations'
import { replaceOp } from 'helpers/jsonPatch'
import { get, pickBy, toPairs } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Toolbar from './components/Toolbar'
import useFusionFieldsGrid from './grid'

function MailFusionFieldsManager() {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const { columns, rows, isLoading } = useFusionFieldsGrid()
  const patchMailFusionField = usePatchMailFusionFieldMutation()

  const handleRowUpdate = async (next, prev) => {
    const { id } = prev
    const [field, nextValue] = get(
      toPairs(pickBy(next, (value, key) => get(prev, key) !== value)),
      '[0]',
    )

    try {
      await patchMailFusionField.mutateAsync({
        id,
        data: [replaceOp(field, nextValue)],
      })
      return next
    } catch (error) {
      const message = get(error, `response.data.${field}[0]`, t('error'))
      popSnackbar(message, ERROR)
      return prev
    }
  }

  return (
    <Box sx={{ mt: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          flex: 1,
          borderRadius: 1,
          p: 2,
        }}
        loading={isLoading}
        columns={columns}
        rows={rows}
        // NE PAS REPRODUIRE
        getRowId={(row) => (row.isDynamic ? row.key : row.id)}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={null}
        isCellEditable={(params) => !params.row.isDynamic}
        components={{ Toolbar }}
      />
    </Box>
  )
}

export default MailFusionFieldsManager
