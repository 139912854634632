import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function LinkButton({ to, ...props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <Button
      component={Link}
      to={to}
      sx={{
        minWidth: (theme) => theme.spacing(5.5),
        height: (theme) => theme.spacing(5.5),
        width: (theme) => theme.spacing(5.5),
        bgcolor: 'secondary.main',
        borderRadius: 1,
        color: 'common.black',
        '&:hover': {
          bgcolor: 'secondary.main',
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default LinkButton
