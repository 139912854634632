import {
  CheckCircleOutline,
  RemoveCircleOutlineRounded,
  WarningAmberOutlined,
} from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { ReferentielTagProps } from './types'

function ReferentielTag({
  name,
  isInError = false,
  isAvailable = true,
  isInWarning = false,
  large = false,
}: ReferentielTagProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: (theme) => `1px solid ${theme.palette.primary.dark}`,
        borderRadius: 4,
        pr: 0.4,
        pl: 0.6,
        py: 0.3,
        bgcolor: 'transparent',
        color: 'primary.dark',
        gap: 0.5,
      }}
    >
      <Typography variant={large ? 'h2' : 'chip'}>{name}</Typography>
      {!isInError && !isInWarning && !isAvailable && (
        <RemoveCircleOutlineRounded
          sx={{
            color: 'primary.main',
            height: (theme) =>
              large ? theme.spacing(3.5) : theme.spacing(2.2),
            width: (theme) => (large ? theme.spacing(3.5) : theme.spacing(2.2)),
          }}
        />
      )}
      {!isInError && !isInWarning && isAvailable && (
        <CheckCircleOutline
          sx={{
            color: 'success.main',
            height: (theme) =>
              large ? theme.spacing(3.5) : theme.spacing(2.2),
            width: (theme) => (large ? theme.spacing(3.5) : theme.spacing(2.2)),
          }}
        />
      )}
      {!isInError && isInWarning && !isAvailable && (
        <WarningAmberOutlined
          sx={{
            color: 'error.light',
            height: (theme) =>
              large ? theme.spacing(3.5) : theme.spacing(2.2),
            width: (theme) => (large ? theme.spacing(3.5) : theme.spacing(2.2)),
          }}
        />
      )}
      {isInError && !isInWarning && !isAvailable && (
        <HighlightOffIcon
          sx={{
            color: 'error.main',
            height: (theme) =>
              large ? theme.spacing(3.5) : theme.spacing(2.2),
            width: (theme) => (large ? theme.spacing(3.5) : theme.spacing(2.2)),
          }}
        />
      )}
    </Box>
  )
}

export default ReferentielTag
