import { RoleEnum } from 'domains/roles/enums'
import { t } from 'i18next'

export const OF_ROLES_LABELS = [
  t(RoleEnum.ADMIN_OFA.key),
  t(RoleEnum.USER_OFA.key),
]
export const OF_ROLES_KEYS = [RoleEnum.ADMIN_OFA.key, RoleEnum.USER_OFA.key]
export const APP_ROLES_LABELS = [t(RoleEnum.ADMIN_APP.key)]
export const APP_ROLES_KEYS = [RoleEnum.ADMIN_APP.key]
