import { Box, Typography } from '@mui/material'
import { ReactComponent as SynchroBusy } from 'assets/images/synchro-busy-45.svg'
import { ReactComponent as SynchroError } from 'assets/images/synchro-error-45.svg'
import { ReactComponent as SynchroSuccess } from 'assets/images/synchro-success-45.svg'
import { ReactComponent as SynchroWarning } from 'assets/images/synchro-warning-45.svg'
import { getLabelFromEnum } from 'components/organisms/OFSManager/formatters'

import ReferentielTag from 'components/organisms/Home/components/ReferentielTag'
import {
  isAvailable,
  isBusy,
  isInError,
  isInWarning,
  isRefAvailable,
  isRefInError,
  isRefInWarning,
} from 'components/organisms/Home/helpers'
import GlobalFilterLogsForm from 'components/organisms/LogTabFilter/components/GlobalFilterLogsForm'
import { useAuth } from 'contexts/AuthContext'
import { SOURCE } from 'domains/of/enums'
import { isAdminAppExclusive, isSupervisor } from 'helpers/role'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSyncMethods } from '../../contexts/SyncMethodsContext'
import ChangeAvailability from '../ChangeAvailability'
import LogsBlock from '../LogsBlock'
import { LogsBlockVariant } from '../enums'
import useLogsFilterForm from './form'

function SheetLogs({ id }) {
  const { t } = useTranslation()
  const { trainingOrganization: of } = useSyncMethods()
  const { user } = useAuth()
  const logsFilterForm = useLogsFilterForm()

  const WSConnections =
    of?.connections?.filter((connection) => connection.application.isWSRest) ??
    []

  return (
    <Box
      sx={{
        borderRadius: 1,
        p: 2,
        pt: 0,
        bgcolor: 'primary.light',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" width="100%" alignItems="center">
          <Box>
            {isAvailable(of) && <SynchroSuccess />}
            {isInWarning(of) && <SynchroWarning />}
            {isInError(of) && <SynchroError />}
            {isBusy(of) && <SynchroBusy />}
          </Box>
          <Box ml={1} pb={1}>
            <Typography variant="overline" sx={{ mb: 2 }}>
              {of?.name || ''}
            </Typography>
          </Box>
        </Box>
        <Box alignSelf="start" mt={1.5}>
          <ReferentielTag
            name={t('referentiel')}
            isInError={isRefInError(of)}
            isAvailable={isRefAvailable(of)}
            isInWarning={isRefInWarning(of)}
            large
          />
        </Box>
      </Box>
      {user.role && isSupervisor(user.role.key) && (
        <ChangeAvailability ofId={of.id} />
      )}
      <FormProvider
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...logsFilterForm}
      >
        <GlobalFilterLogsForm />

        {user.role && !isAdminAppExclusive(user.role.key) && (
          <>
            <LogsBlock
              variant={LogsBlockVariant.GENERAL}
              collection={[
                {
                  id: of?.id,
                  name: of?.shortName,
                  isInError: of?.isInError,
                  hasAnyLogs: of?.hasAnyLogs,
                  isInWarning: isInWarning(of),
                },
              ]}
              ofId={of.id}
            />
            <LogsBlock
              variant={LogsBlockVariant.VENDOR}
              collection={[
                {
                  id: of?.id,
                  name: t(getLabelFromEnum(SOURCE, of?.source)),
                  isInError: of?.isSourceInError,
                  hasAnyLogs: of?.hasAnySourceLogs,
                  isInWarning: isInWarning(of),
                  isBusy: isBusy(of),
                },
              ]}
              ofId={of.id}
            />
            <LogsBlock
              variant={LogsBlockVariant.IS_REFERENTIEL}
              collection={[
                {
                  id: of?.id,
                  name: t('referentiel'),
                  isInError: isRefInError(of),
                  hasAnyLogs: of?.hasAnyLogs,
                  isInWarning: isRefInWarning(of),
                },
              ]}
              ofId={of.id}
            />
          </>
        )}
        <LogsBlock
          variant={LogsBlockVariant.APP}
          collection={WSConnections.map((connection) => {
            return {
              ...connection,
              ...{
                id: connection.application.id,
              },
            }
          })}
          ofId={id}
        />
      </FormProvider>
    </Box>
  )
}

export default SheetLogs
