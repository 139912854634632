import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { MAX_NAME_LENGTH } from './enum'

function useCreateApplicationForm() {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    description: '',
    isActive: false,
    folderName: '',
    users: [],
    methods: [],
    applicationPublicTypes: [],
    isGenericApp: false,
    isCommonApp: false,
    isWSRest: false,
    genericAppUrl: null,
    applicationLogoUrl: '',
    applicationPictureUrl: '',
    logo: undefined,
    picture: undefined,
    categoryId: null,
  }

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string()
        .required(t('required-field'))
        .max(MAX_NAME_LENGTH, t('max-char', { value: MAX_NAME_LENGTH })),
      description: Yup.string().nullable(),
      isActive: Yup.bool(),
      isCommonApp: Yup.bool(),
      isWSRest: Yup.bool(),
      isGenericApp: Yup.bool(),
      genericAppUrl: Yup.string().when('isGenericApp', {
        is: true,
        then: Yup.string().required(t('required-field')).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      methods: Yup.array().of(
        Yup.object({
          method: Yup.object().nullable().required(t('required-field')),
        }),
      ),
    })
    .test('Boolean checked', null, (form) => {
      if (
        form.isActive &&
        (form.isCommonApp || form.isWSRest || form.isGenericApp)
      ) {
        return true
      }
      if (
        !form.isActive &&
        (!form.isCommonApp || !form.isWSRest || !form.isGenericApp)
      )
        return true
      return new Yup.ValidationError(
        t('warning-application-status-not-checked'),
        null,
        'isWSRest',
      )
    })

  return useForm({ defaultValues, resolver: yupResolver(validationSchema) })
}

export default useCreateApplicationForm
