import AddCircleIcon from '@mui/icons-material/AddCircle'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import ControlledCheckbox from 'components/molecules/ControlledCheckbox'
import ControlledSelect from 'components/molecules/ControlledSelect'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useConfirm } from 'contexts/ConfirmContext'
import { useOfMappingFieldsQuery } from 'domains/of/queries'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { REQUIRED_LDAP_MAPPED_FIELDS } from './enum'

function OFSPublicTypesLDAPComponent({ index }) {
  const { t } = useTranslation()
  const { control, setValue, setError, clearErrors } = useFormContext()
  const currentSelectedDBField = useWatch({
    control,
    name: `ofPublicTypes[${index}].currentSelectedDBField`,
  })
  const currentSelectedLDAPField = useWatch({
    control,
    name: `ofPublicTypes[${index}].currentSelectedLDAPField`,
  })
  const publicTypeActive = useWatch({
    control,
    name: `ofPublicTypes[${index}].isActive`,
  })
  const requiredLDAPField = useWatch({
    control,
    name: `ofPublicTypes[${index}].requiredLDAPField`,
  })
  const { confirm } = useConfirm()
  const [refFusionField, setRefFusionField] = useState([])
  const { data: userRefFusionField, isLoading } = useOfMappingFieldsQuery()

  const { append: addMappedField, remove: removeMappedField } = useFieldArray({
    control,
    name: `ofPublicTypes[${index}].activeDirectoryFields`,
    rules: { minLength: 4 },
  })

  const activeDirectoryFields = useWatch({
    control,
    name: `ofPublicTypes[${index}].activeDirectoryFields`,
  })
  useEffect(() => {
    if (!isEmpty(userRefFusionField) && !isLoading)
      if (!isEmpty(activeDirectoryFields)) {
        setRefFusionField(
          userRefFusionField.filter(
            (field) =>
              !activeDirectoryFields.some((f) => f.dataBaseFieldName === field),
          ),
        )
      } else {
        setRefFusionField(userRefFusionField)
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRefFusionField])

  useEffect(() => {
    if (REQUIRED_LDAP_MAPPED_FIELDS.includes(currentSelectedDBField)) {
      setValue(`ofPublicTypes[${index}].requiredLDAPField`, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedDBField])

  const handleAddMappedField = () => {
    clearErrors()
    if (
      isEmpty(currentSelectedDBField) ||
      currentSelectedDBField.trim().length === 0
    ) {
      setError(`ofPublicTypes[${index}].currentSelectedDBField`, {
        types: {
          required: t('required-field'),
        },
      })
      if (
        isEmpty(currentSelectedLDAPField) ||
        currentSelectedLDAPField.trim().length === 0
      ) {
        setError(`ofPublicTypes[${index}].currentSelectedLDAPField`, {
          types: {
            required: t('required-field'),
          },
        })
      }
    } else if (isEmpty(currentSelectedLDAPField)) {
      setError(`ofPublicTypes[${index}].currentSelectedLDAPField`, {
        types: {
          required: t('required-field'),
        },
      })
      if (isEmpty(currentSelectedDBField)) {
        setError(`ofPublicTypes[${index}].currentSelectedDBField`, {
          types: {
            required: t('required-field'),
          },
        })
      }
    } else {
      addMappedField({
        dataBaseFieldName: currentSelectedDBField,
        activeDirectoryFieldName: currentSelectedLDAPField,
        isRequired: requiredLDAPField,
      })
      setRefFusionField(
        refFusionField.filter(
          (indexField) => indexField !== currentSelectedDBField,
        ),
      )
      setValue(`ofPublicTypes[${index}].currentSelectedDBField`, '', {
        shouldDirty: true,
      })
      setValue(`ofPublicTypes[${index}].currentSelectedLDAPField`, '', {
        shouldDirty: true,
      })
      setValue(`ofPublicTypes[${index}].requiredLDAPField`, false, {
        shouldDirty: true,
      })
    }
  }

  const handleRemoveMappedField = async (indexField, field) => {
    await confirm(t('mapped-field-delete-confirmation'))
    removeMappedField(indexField)
    setRefFusionField([...refFusionField, field.dataBaseFieldName])
  }
  return (
    <Box sx={{ ml: 1 }}>
      <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
        {t('mapping-field-LDAP')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.default',
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <ControlledSelect
            control={control}
            fullWidth
            disabled={!publicTypeActive}
            InputLabelProps={{ shrink: true }}
            label={t('referentiel-field')}
            loading={isLoading}
            size="small"
            sx={{
              bgcolor: 'common.white',
              mr: 2,
              flex: 1,
            }}
            name={`ofPublicTypes[${index}].currentSelectedDBField`}
          >
            {refFusionField.map((field) => (
              <MenuItem key={field} value={field}>
                <Typography display="inline">{t(field)}</Typography>
                {REQUIRED_LDAP_MAPPED_FIELDS.includes(field) && (
                  <Typography
                    display="inline"
                    sx={{ color: 'error.dark', fontWeight: 'bold' }}
                  >
                    *
                  </Typography>
                )}
              </MenuItem>
            ))}
          </ControlledSelect>
          <ControlledTextField
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{
              bgcolor: 'common.white',
              borderRadius: 1,
              flex: 1,
            }}
            name={`ofPublicTypes[${index}].currentSelectedLDAPField`}
            label={t('LDAP-field')}
          />
          <IconButton onClick={handleAddMappedField}>
            <AddCircleIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {currentSelectedDBField && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'nowrap',
                mr: 7,
              }}
            >
              <ControlledCheckbox
                label={t('required-field-ldap')}
                name={`ofPublicTypes[${index}].requiredLDAPField`}
                sx={{ textOverflow: 'ellipsis' }}
                disabled={REQUIRED_LDAP_MAPPED_FIELDS.includes(
                  currentSelectedDBField,
                )}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: 'auto',
        }}
      >
        {!isEmpty(activeDirectoryFields) &&
          activeDirectoryFields.map((field, indexField) => (
            <Box key={field.dataBaseFieldName} sx={{ display: 'flex' }}>
              <Chip
                sx={{
                  bgcolor: 'secondary.main',
                  display: 'flex',
                  mt: 1,
                  mr: 1,
                }}
                onDelete={() => handleRemoveMappedField(indexField, field)}
                label={
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <Typography variant="caption">
                      {t(field.dataBaseFieldName)}
                    </Typography>
                    <ArrowRightAltIcon />
                    <Typography variant="caption">
                      {field.activeDirectoryFieldName}
                    </Typography>
                    <Tooltip title={t('required-field-sync-ldap-tooltip')}>
                      <Box sx={{ color: 'error.dark', fontWeight: 'bold' }}>
                        {field.isRequired ? '*' : ''}
                      </Box>
                    </Tooltip>
                  </Box>
                }
              />
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default OFSPublicTypesLDAPComponent
