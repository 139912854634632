import { Box, Chip, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatTooltipTitle, getALertChipProps } from './helper'

function AlertChip({ count, type, height, width, sx = {} }) {
  const { icon, bgcolor, trad } = getALertChipProps(type)
  const { t } = useTranslation()

  if (count === 0) {
    return (
      <Chip
        size="small"
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.35,
              px: 0.1,
            }}
          />
        }
        sx={{ bgcolor: 'primary.light', mr: 0.5, height, width, ...sx }}
      />
    )
  }

  return (
    <Tooltip title={formatTooltipTitle(t(trad, { count }), count)} followCursor>
      <Chip
        size="small"
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.35,
              px: 0.1,
            }}
          >
            {icon}
            <Typography color="common.white" noWrap variant="chip">
              {count}
            </Typography>
          </Box>
        }
        sx={{ bgcolor, mr: 0.5, height, width, ...sx }}
      />
    </Tooltip>
  )
}

export default AlertChip
