import React, { createContext, useContext, useMemo, useState } from 'react'
import { DEFAULT_TYPES_FILTER } from '../components/filter'
import {
  formatLogTypesToForm,
  getTodayNoon,
  getYesterdayNoon,
} from '../components/helpers'

const LogFiltersContext = createContext(null)

function LogFiltersProvider({ id, children }) {
  const [filterType, setFilterType] = useState(
    formatLogTypesToForm(DEFAULT_TYPES_FILTER),
  )
  const [filterStartDate, setFilterStartDate] = useState(getYesterdayNoon())
  const [filterEndDate, setFilterEndDate] = useState(getTodayNoon())
  const [filterMethod, setFilterMethod] = useState('')
  const [filterDescription, setFilterDescription] = useState('')

  const values = useMemo(
    () => ({
      filterType,
      setFilterType,
      filterStartDate,
      setFilterStartDate,
      filterEndDate,
      setFilterEndDate,
      filterMethod,
      setFilterMethod,
      filterDescription,
      setFilterDescription,
    }),
    [
      filterType,
      setFilterType,
      filterStartDate,
      setFilterStartDate,
      filterEndDate,
      setFilterEndDate,
      filterMethod,
      setFilterMethod,
      filterDescription,
      setFilterDescription,
    ],
  )

  return (
    <LogFiltersContext.Provider value={values}>
      {children}
    </LogFiltersContext.Provider>
  )
}

const useLogFilters = () => {
  const context = useContext(LogFiltersContext)
  if (context === undefined) {
    throw new Error('useLogFilters must be used within a LogFiltersProvider')
  }
  return context
}

export { LogFiltersProvider, useLogFilters }
