import { useConfirm } from 'contexts/ConfirmContext'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeployOF, useUpdateOFSMutation } from 'domains/of/mutations'
import { OF, OFS } from 'domains/of/templates'
import { OFS_PATH } from 'enums/paths'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useOf } from '../../context/of'
import OFSMainForm from '../OFSForm'
import useCreateOFSForm from '../OFSForm/form'

function EditOFSForm() {
  const isFormCreate = false
  const { form, setFieldsInError } = useCreateOFSForm(isFormCreate)
  const updateOFS = useUpdateOFSMutation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const params = useParams()
  const { id } = params
  const queryClient = useQueryClient()
  const { of } = useOf()
  const deployOf = useDeployOF()
  const { confirm } = useConfirm()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const deploy = () =>
    confirm(t('irreversible-action'), t('confirm-deploy-of'))
      .then(() =>
        deployOf
          .mutateAsync({
            id,
          })
          .then(() => {
            queryClient.invalidateQueries({ queryKey: [OF] })
            setIsDirty(false)
            popSnackbar(t('after.deploy'), SUCCESS)
          })
          .catch(() =>
            popSnackbar(t('error.while.creating.environment'), ERROR),
          ),
      )
      .catch(() => null)

  const onSubmitSave = async (values) => {
    if (!isEmpty(of)) {
      try {
        await updateOFS.mutateAsync({
          id,
          data: {
            ...values,
            users: [...values.admins, ...values.readers],
            availability: of.availability,
          },
        })
        popSnackbar(t('of-update-success'), SUCCESS)
        setIsDirty(false)
        queryClient.invalidateQueries({ queryKey: [OF] })
        queryClient.invalidateQueries({ queryKey: [OFS] })
        navigate(OFS_PATH)
      } catch (error) {
        setFieldsInError(Object.keys(error?.response?.data ?? []))
      }
    }
  }

  const onSubmitDeploy = async (values) => {
    if (!isEmpty(of)) {
      updateOFS
        .mutateAsync(
          {
            id,
            data: {
              ...values,
              users: [...values.admins, ...values.readers],
              availability: of.availability,
            },
          },
          {
            onSuccess: () => {
              deploy()
              queryClient.invalidateQueries({ queryKey: [OFS] })
            },
          },
        )
        .then(() => navigate(OFS_PATH))
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Helmet>
        <meta
          name="titles"
          content={JSON.stringify([
            { label: t('admin') },
            {
              label: t('ofs-management'),
              to: OFS_PATH,
              confirm: {
                title: t('back-to-list-confirmation'),
                message: t('changes-shall-disappear'),
                enable: form.formState.isDirty,
              },
            },
            { label: t('update-title') },
          ])}
        />
      </Helmet>
      <OFSMainForm
        control={form.control}
        onSubmitSave={form.handleSubmit(onSubmitSave)}
        onSubmitDeploy={form.handleSubmit(onSubmitDeploy)}
        variant="edit"
      />
    </FormProvider>
  )
}

export default EditOFSForm
