import { Box } from '@mui/material'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useCreateCategoryMutation } from 'domains/categories/mutations'
import { CATEGORIES, CATEGORY } from 'domains/categories/templates'
import { CATEGORIES_PATH } from 'enums/paths'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreateCategoryForm from '../../form'
import CategoriesForm from '../CategoryForm'

function CreateCategoryForm({ handleClose }) {
  const { t } = useTranslation()
  const form = useCreateCategoryForm()
  const createCategory = useCreateCategoryMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (values) => {
    await createCategory.mutateAsync({
      data: {
        name: values.name,
        number: values.number,
      },
    })
    popSnackbar(t('category-add-success'), SUCCESS)
    setIsDirty(false)
    queryClient.invalidateQueries({ queryKey: [CATEGORY] })
    queryClient.invalidateQueries({ queryKey: [CATEGORIES] })
    handleClose()
  }

  return (
    <Box>
      <Helmet>
        <meta
          name="titles"
          content={JSON.stringify([
            { label: t('admin') },
            {
              label: t('categories'),
              to: CATEGORIES_PATH,
              confirm: {
                title: t('back-to-list-confirmation'),
                message: t('changes-shall-disappear'),
                enable: form.formState.isDirty,
              },
            },
            { label: t('create-title') },
          ])}
        />
      </Helmet>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <CategoriesForm onSubmit={form.handleSubmit(onSubmit)} />
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateCategoryForm
