import CircleIcon from '@mui/icons-material/Circle'
import Box from '@mui/material/Box'
import { find } from 'lodash'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import HeaderTypography from './components/HeaderTypography'

function Header({ defaultTitles = [] }) {
  const [titles, setTitles] = React.useState(defaultTitles)
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'common.black',
        color: 'common.white',
        borderRadius: 1,
        p: 2,
      }}
    >
      <Helmet
        onChangeClientState={(state) => {
          setTitles(() => {
            const metaTitle = find(
              state.metaTags,
              (element) => element.name === 'titles',
            )?.content
            return metaTitle ? JSON.parse(metaTitle) : defaultTitles
          })
        }}
      />
      {titles &&
        titles.map((title, index) => {
          if (!title) return ''
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${title.label}-${index}`}>
              {index !== 0 && (
                <Box
                  ml={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& svg': {
                      width: (theme) => theme.spacing(1.5),
                      height: (theme) => theme.spacing(1.5),
                    },
                  }}
                >
                  <CircleIcon />
                </Box>
              )}
              <Box ml={index !== 0 ? 2 : 0}>
                <HeaderTypography headerTitle={title} />
              </Box>
            </React.Fragment>
          )
        })}
    </Box>
  )
}

export default Header
