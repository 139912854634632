import Box from '@mui/material/Box'
import React from 'react'
import Drawer from './components/Drawer'
import { DRAWER_WIDTH } from './enums'

function AdminLayout({ children }) {
  const [open, setOpen] = React.useState(false)
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Drawer open={open} setOpen={setOpen} drawerWidth={DRAWER_WIDTH} />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3,
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default AdminLayout
