import axios, { AxiosError } from 'axios'
import { ERROR, useSnackbar, WARNING } from 'contexts/SnackbarContext'
import { axiosInstance, getJwtToken, setJwtToken } from 'domains/helpers'
import { REFRESH_TOKEN } from 'domains/users/templates'
import { LOGIN_PATH } from 'enums/paths'
import { get, isEmpty } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'

let alreadyRefreshing = false
let pendingRequests = []

export function AxiosInterceptors({ children }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { popSnackbar } = useSnackbar()

  const unauthorized = useCallback(
    (error?) => {
      popSnackbar(t('must-authenticate'), WARNING)
      navigate(LOGIN_PATH)
      return error
    },
    [navigate, popSnackbar, t],
  )

  const resumePendingRequests = () => {
    while (!isEmpty(pendingRequests)) {
      const { req, resolve, reject } = pendingRequests.shift()
      req.headers = {
        ...req.headers,
        Authorization: `Bearer ${getJwtToken()}`,
      }
      axios
        .request(req)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    }
  }

  const refreshTokenAndTryAgain = (error) => {
    return new Promise((resolve, reject) => {
      const req = error.config
      pendingRequests.push({ req, resolve, reject })

      if (alreadyRefreshing) {
        return
      }

      alreadyRefreshing = true

      axios
        .post(REFRESH_TOKEN, null, {
          headers: {
            Authorization: `Bearer ${getJwtToken()}`,
          },
          withCredentials: true,
          baseURL: process.env.REACT_APP_API_URL,
        })
        .then((response) => response.data)
        .then((jwtToken) => {
          if (isEmpty(jwtToken)) {
            logout()
            unauthorized()
            return
          }

          setJwtToken(jwtToken)
          resumePendingRequests()
        })
        .catch((err) => {
          logout()
          unauthorized(err)
        })
        .finally(() => {
          alreadyRefreshing = false
          pendingRequests = []
        })
    })
  }

  const popSnackbarIfAvailable = (error: AxiosError) => {
    const errorData = get(error, 'response.data', {})
    if (!isEmpty(errorData)) {
      const errorValues = Object.values(errorData)
      popSnackbar(`${errorValues[0]}`, ERROR)
    }
  }

  useEffect(() => {
    const authInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        popSnackbarIfAvailable(error)
        switch (error.response.status) {
          case 401:
            return refreshTokenAndTryAgain(error)
          default:
            return Promise.reject(error)
        }
      },
    )

    return () => axiosInstance.interceptors.response.eject(authInterceptor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}
