import { Box, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOf } from '../../context/of'
import CommonAppComponent from '../CommonAppComponent'

function CommonAppsForm() {
  const { t } = useTranslation()
  const { of } = useOf()

  return (
    !isEmpty(of?.commonApps) && (
      <Box
        sx={{
          p: 1.5,
          bgcolor: 'secondary.main',
          borderRadius: 1,
        }}
      >
        <Typography>{t('common-apps')}</Typography>
        {of.commonApps.map((app, index) => (
          <CommonAppComponent key={app.id} app={app} />
        ))}
      </Box>
    )
  )
}

export default CommonAppsForm
