import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

function MethodsHeader() {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mr: 2,
      }}
    >
      <Box sx={{ flex: 10 }}>
        <Typography variant="caption" sx={{ fontWeight: 'bold', ml: 2 }}>
          {t('methods')}
        </Typography>
      </Box>
      <Box sx={{ flex: 4, textAlign: 'center', mr: 2 }}>
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          {t('sync')}
        </Typography>
      </Box>
      <Box sx={{ flex: 0.5 }} />
    </Box>
  )
}

export default MethodsHeader
