import React, { createContext, useContext, useMemo, useState } from 'react'

const GlobalFormStateContext = createContext(null)

function GlobalFormStateProvider({ children }) {
  const [isDirty, setIsDirty] = useState(false)

  const values = useMemo(
    () => ({
      isDirty,
      setIsDirty,
    }),
    [isDirty, setIsDirty],
  )

  return (
    <GlobalFormStateContext.Provider value={values}>
      {children}
    </GlobalFormStateContext.Provider>
  )
}

const useGlobalFormState = () => {
  const context = useContext(GlobalFormStateContext)
  if (context === undefined) {
    throw new Error(
      'useGlobalFormState must be used within a GlobalFormStateProvider',
    )
  }
  return context
}

export { GlobalFormStateProvider, useGlobalFormState }
