const breakpoints = {
  values: {
    xs: 0,
    sm: 900,
    md: 1200,
    lg: 1400,
    xl: 1600,
  },
}

export default breakpoints
