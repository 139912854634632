import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'
import useOFSGrid from './grid'
import Toolbar from './components/Toolbar'

function OFSManager() {
  const { columns, rows, isFetching, sortModel, setSortModel } = useOFSGrid()

  return (
    <Box
      sx={{
        mt: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        sx={{
          flex: 1,
          borderRadius: 1,
          p: 2,
          position: 'relative',
        }}
        loading={isFetching}
        columns={columns}
        rows={rows}
        checkboxSelection={false}
        components={{ Toolbar }}
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  )
}

export default OFSManager
