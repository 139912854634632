import { Box } from '@mui/material'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdatePublicTypesMutation } from 'domains/publicTypes/mutations'
import { useGetPublicTypeQuery } from 'domains/publicTypes/queries'
import { PUBLIC_TYPES } from 'domains/publicTypes/templates'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreatePublicTypeForm from '../../form'
import PublicTypeForm from '../Form'

function PublicTypeEditForm({ id, handleClose }) {
  const { t } = useTranslation()
  const { data: publicType } = useGetPublicTypeQuery({ id })
  const form = useCreatePublicTypeForm()
  const updatePublicType = useUpdatePublicTypesMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (values) => {
    await updatePublicType.mutateAsync({ id, data: values })
    popSnackbar(t('public-type-edit-success'), SUCCESS)
    setIsDirty(false)
    queryClient.invalidateQueries(PUBLIC_TYPES)
    handleClose()
  }

  useEffect(
    function loadPublicTypeFields() {
      if (!isEmpty(publicType)) {
        form.reset(publicType)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [publicType],
  )

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          bgcolor: 'common.white',
          borderRadius: 1,
        }}
      >
        <PublicTypeForm form={form} onSubmit={onSubmit} />
      </Box>
    </Box>
  )
}

export default PublicTypeEditForm
