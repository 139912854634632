import { Avatar, Box, Typography, Tooltip } from '@mui/material'
import RoundImage from 'components/atoms/RoundImage'
import { isEmpty } from 'lodash'
import React from 'react'

function ProfileItem({
  picture,
  firstname,
  lastname,
  spaceSize = 3,
  inversedColors = false,
}) {
  const firstNameInitial = firstname ? firstname.charAt(0).toUpperCase() : ''
  const lastnameInitial = lastname ? lastname.charAt(0).toUpperCase() : ''
  const initials = firstNameInitial + (spaceSize > 2 ? lastnameInitial : '')
  if (picture) {
    return (
      <Tooltip title={`${firstname} ${lastname}`}>
        <Box>
          <RoundImage
            src={picture}
            classes={{
              width: (theme) => theme.spacing(spaceSize),
              height: (theme) => theme.spacing(spaceSize),
              objectFit: 'cover',
            }}
          />
        </Box>
      </Tooltip>
    )
  }

  if (!isEmpty(initials)) {
    return (
      <Tooltip title={`${firstname} ${lastname}`}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: (theme) => theme.spacing(spaceSize),
            height: (theme) => theme.spacing(spaceSize),
            objectFit: 'cover',
            border: (theme) =>
              `1px solid ${
                inversedColors
                  ? theme.palette.common.white
                  : theme.palette.primary.main
              }`,
            color: (theme) =>
              inversedColors
                ? theme.palette.common.white
                : theme.palette.primary.main,
            borderRadius: '100%',
          }}
        >
          <Typography variant="body2" noWrap>
            {initials}
          </Typography>
        </Box>
      </Tooltip>
    )
  }

  return (
    <Avatar
      sx={{
        width: (theme) => theme.spacing(spaceSize),
        height: (theme) => theme.spacing(spaceSize),
        objectFit: 'cover',
      }}
    />
  )
}

export default ProfileItem
