import { useSasQueryWrapper } from 'domains/helpers'
import { METHODS_AVAILABILITY } from './templates'
import { SASStatus } from './types'

export function useAvailabilityQuery(baseUrl: string, params?, options?) {
  return useSasQueryWrapper<SASStatus>(
    'GET',
    METHODS_AVAILABILITY,
    params,
    options,
    baseUrl,
  )
}
