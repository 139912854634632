import {
  CheckCircleOutline,
  HourglassBottomTwoTone,
  RemoveCircleOutlineRounded,
  WarningAmberOutlined,
} from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { SynchroTagProps } from './types'

function SynchroTag({
  name,
  successSynchro,
  hasAnyLogs,
  isBusy = false,
  isInWarning,
  variant,
}: SynchroTagProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: (theme) =>
          variant === 'vendor'
            ? `1px solid ${theme.palette.primary.dark}`
            : 'none',
        borderRadius: 4,
        pr: 0.4,
        pl: 0.6,
        py: 0.3,
        bgcolor: variant === 'vendor' ? 'transparent' : 'primary.dark',
        color: variant === 'vendor' ? 'primary.dark' : 'primary.light',
        gap: 0.5,
      }}
    >
      <Typography variant="tag">{name}</Typography>
      {isBusy && (
        <HourglassBottomTwoTone
          sx={{
            color: 'primary.dark',
            animation: 'spin 3s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(180deg)',
              },
            },
          }}
        />
      )}
      {!isBusy && !hasAnyLogs && (
        <RemoveCircleOutlineRounded
          sx={{
            color: 'primary.main',
          }}
        />
      )}
      {!isBusy && !isInWarning && successSynchro && hasAnyLogs && (
        <CheckCircleOutline
          sx={{
            color: 'success.main',
          }}
        />
      )}
      {!isBusy && isInWarning && successSynchro && hasAnyLogs && (
        <WarningAmberOutlined
          sx={{
            color: 'error.light',
          }}
        />
      )}
      {!isBusy && !successSynchro && hasAnyLogs && (
        <HighlightOffIcon
          sx={{
            color: 'error.main',
          }}
        />
      )}
    </Box>
  )
}

export default SynchroTag
