import { useMutationWrapper } from 'domains/helpers'
import {
  APPLICATION,
  APPLICATIONS,
  APPLICATION_EXCEL_EXPORT,
} from './templates'

export function useCreateApplicationMutation(options?) {
  return useMutationWrapper('POST', APPLICATIONS, options)
}

export function useUpdateApplicationMutation(options?) {
  return useMutationWrapper('PUT', APPLICATION, options)
}

export function useDeleteApplicationMutation(options?) {
  return useMutationWrapper('DELETE', APPLICATION, options)
}

export function useExportExcel(options?) {
  return useMutationWrapper('POST', APPLICATION_EXCEL_EXPORT, options)
}
