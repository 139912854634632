import { useMutationWrapper } from 'domains/helpers'
import { APP_PARAMETER, APP_PARAMETERS } from './templates'

export function useUpdateAppParametersMutation(options?) {
  return useMutationWrapper('PUT', APP_PARAMETER, options)
}

export function usePatchAppParametersMutation(options?) {
  return useMutationWrapper('PATCH', APP_PARAMETER, options)
}

export function useDeleteAppParametersMutation(options?) {
  return useMutationWrapper('DELETE', APP_PARAMETER, options)
}

export function useCreateAppParametersMutation(options?) {
  return useMutationWrapper('POST', APP_PARAMETERS, options)
}
