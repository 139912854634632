import { useMutationWrapper } from 'domains/helpers'
import { CATEGORIES, CATEGORY } from './templates'

export function useDeleteCategoryMutation(options?) {
  return useMutationWrapper('DELETE', CATEGORY, options)
}

export function useUpdateCategoryMutation(options?) {
  return useMutationWrapper('PUT', CATEGORY, options)
}

export function usePatchCategoryMutation(options?) {
  return useMutationWrapper('PATCH', CATEGORY, options)
}

export function useCreateCategoryMutation(options?) {
  return useMutationWrapper('POST', CATEGORIES, options)
}
