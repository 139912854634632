export enum ROLES_KEY {
  SUPERVISOR = 'SUPERVISOR',

  /**
   * Administrateur Technique
   * Ils auront accès à l'ensemble de l'application ainsi qu'aux paramètres techniques
   * globaux de l'application
   */
  ADMIN = 'ADMIN',

  /**
   * Administrateur Application
   * Ce rôle sera défini à l'intérieur d'une application. Il permettra d'ouvrir des accès
   * aux éditeurs des applications. Ils pourront se connecter à l'application mais ne verront
   * que les OF-A pour lesquels leur application est gérée. Ils pourront juste visualiser les
   * données de ces OF-A avec juste leur application (ils ne verront pas les données des autres
   * applications)
   */
  ADMIN_APP = 'ADMIN_APP',

  /**
   * Administrateur OF-A
   * Ce rôle sera défini à l'intérieur d'un OF-A. Il pourra se connecter à l'application mais ne
   * verra que les données de son OF-A et pas celles des autres. Ils pourront voir les logs de
   * leur OF-A et synchroniser si besoin les données avec l'outil de base.
   */
  ADMIN_OFA = 'ADMIN_OFA',

  /**
   * Lecteur CCA BTP
   * Ils auront une vision globale sur l'ensemble de l'application et des OF-A. Ils pourront juste
   * visualiser les données
   */
  USER_CCCA_BTP = 'USER_CCCA_BTP',

  /**
   * Lecteur OF-A
   * Ce rôle sera défini à l'intérieur d'un OF-A. Il pourra se connecter à l'application mais ne verra
   * que les données de son OF-A et pas celles des autres. Il pourra juste visualiser les données.
   */
  USER_OFA = 'USER_OFA',
}
