import { Box, Button } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { isEmpty } from 'lodash'
import React from 'react'
import { FormProvider, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useOfs } from '../../context/ofs'
import ControllerWithHeader from '../ToolbarControllerWithHeader'
import useOFSFilerModalForm from './filterForm'

function FilterModalForm() {
  const { t } = useTranslation()
  const filterForm = useOFSFilerModalForm()
  const { control } = filterForm

  const labelControl = useWatch({
    control,
    name: 'search',
  })
  const { setParams } = useOfs()

  const onSubmit = (values) => {
    setParams(values)
  }

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <FormProvider {...filterForm}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          height: (theme) => theme.spacing(22.5),
          width: (theme) => theme.spacing(47),
          bgcolor: 'primary.main',
          padding: (theme) => theme.spacing(2.8, 3.5),
        }}
        component="form"
        onSubmit={filterForm.handleSubmit(onSubmit)}
      >
        <ControllerWithHeader title={t('search')}>
          <ControlledTextField
            name="search"
            label={isEmpty(labelControl) ? t('search') : ''}
            InputLabelProps={{ shrink: false }}
            fullWidth
            size="small"
            sx={{ bgcolor: 'primary.light', span: { display: 'none' } }}
          />
        </ControllerWithHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <Button
            sx={{
              borderRadius: 1,
              color: 'common.black',
              bgcolor: 'secondary.main',
              '&:hover': {
                bgcolor: 'secondary.main',
              },
            }}
            type="submit"
          >
            {t('apply')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  )
}

export default FilterModalForm
