import { Box, Button } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { AVAILABILITES } from 'domains/of/enums'
import { usePatchOFSMutation } from 'domains/of/mutations'
import { OF } from 'domains/of/templates'
import { replaceOp } from 'helpers/jsonPatch'
import { map } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useAvailabilityForm from '../../hooks/availabilityForm'

function ChangeAvailability({ ofId }) {
  const { t } = useTranslation()
  const form = useAvailabilityForm()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const patchOf = usePatchOFSMutation()
  const onSubmit = async (data) => {
    patchOf.mutateAsync(
      {
        id: ofId,
        data: [replaceOp('availability', data.availability.value)],
      },
      {
        onSuccess: () => {
          popSnackbar(t('of-update-success'), SUCCESS)
          queryClient.invalidateQueries(OF)
        },
      },
    )
  }
  return (
    <Box
      sx={{
        width: '100%',
        mt: 1,
      }}
    >
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...form}
      >
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center">
            <ControlledAutocomplete
              name="availability"
              TextFieldProps={{
                size: 'small',
                label: t('availability'),
                color: 'primary',
                sx: {
                  bgcolor: 'primary.light',
                  borderWidth: 5,
                  borderRadius: 2,
                  input: {
                    color: 'black',
                  },
                  mr: 2,
                  width: (theme) => theme.spacing(30),
                },
              }}
              loading={false}
              options={map(AVAILABILITES, (value) => ({
                ...value,
                label: t(value.label),
              }))}
            />
            <Button type="submit">{t('update')}</Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

export default ChangeAvailability
