import { SaveAsRounded } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import Header from 'components/templates/AdminLayout/components/Header'
import ControlledCheckbox from 'components/molecules/ControlledCheckbox'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useAuth } from 'contexts/AuthContext'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdateAppParametersMutation } from 'domains/appParameters/mutations'
import { useGetAppParameterQuery } from 'domains/appParameters/queries'
import { APP_PARAMETERS } from 'domains/appParameters/templates'
import { APP_PARAMS_PATH } from 'enums/paths'
import { isSupervisor } from 'helpers/role'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import useCreateAppParamForm from '../../form'

function AppParamEdit() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: appParam } = useGetAppParameterQuery({ id })
  const navigate = useNavigate()
  const form = useCreateAppParamForm()
  const updateAppParam = useUpdateAppParametersMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (values) => {
    return updateAppParam
      .mutateAsync(
        { id, data: values },
        {
          onSuccess: () => {
            popSnackbar(t('app-param-edit-success'), SUCCESS)
            queryClient.invalidateQueries(APP_PARAMETERS)
            setIsDirty(false)
          },
        },
      )
      .then(() => navigate(APP_PARAMS_PATH))
  }

  useEffect(
    function loadAppParamFields() {
      if (!isEmpty(appParam)) {
        try {
          form.reset(appParam)
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appParam],
  )

  return (
    <Box>
      <Header
        defaultTitles={[
          { label: t('admin') },
          {
            label: t('app-params'),
            to: APP_PARAMS_PATH,
            confirm: {
              title: t('back-to-list-confirmation'),
              message: t('changes-shall-disappear'),
              enable: form.formState.isDirty,
            },
          },
          { label: t('update-title') },
        ]}
      />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          <Typography variant="h3" mb={2}>
            {t('edit-app-param')}
          </Typography>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="key"
                  label={t('key')}
                  fullWidth
                  size="small"
                  disabled={user.role && !isSupervisor(user.role.key)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="value"
                  label={t('value')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  name="description"
                  label={t('description')}
                  fullWidth
                  size="small"
                  disabled={user.role && !isSupervisor(user.role.key)}
                />
              </Grid>
              {user.role && isSupervisor(user.role.key) && (
                <Grid item xs={12}>
                  <ControlledCheckbox
                    name="isOnlyForSupervisor"
                    label={t('only-supervisor')}
                    disabled={user.role && !isSupervisor(user.role.key)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveAsRounded />}
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default AppParamEdit
