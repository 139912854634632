import { Add, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { isAdminPlus } from 'helpers/role'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PublicTypeCreateForm from '../Create'

function Toolbar() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!isEmpty(user) && user.role && isAdminPlus(user.role.key) && (
          <Button
            onClick={handleOpen}
            sx={{
              minWidth: (theme) => theme.spacing(5.5),
              height: (theme) => theme.spacing(5.5),
              width: (theme) => theme.spacing(5.5),
              bgcolor: 'secondary.main',
              borderRadius: 1,
              color: 'common.black',
              '&:hover': {
                bgcolor: 'secondary.main',
              },
            }}
            variant="contained"
          >
            <Add />
          </Button>
        )}
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('create-public-type')}
            <IconButton size="small" onClick={() => handleClose()}>
              <Close sx={{ color: 'common.black' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <PublicTypeCreateForm handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Toolbar
