import { SaveAsRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Typography } from '@mui/material'
import useCreateMailTemplateForm from 'components/organisms/MailTemplateForm/form'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useAuth } from 'contexts/AuthContext'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useGetMailFusionFieldsQuery } from 'domains/mailFusionFields/queries'
import { useUpdateMailTemplateMutation } from 'domains/mailTemplates/mutations'
import { useGetMailTemplateQuery } from 'domains/mailTemplates/queries'
import { MAIL_TEMPLATES } from 'domains/mailTemplates/templates'
import { EMAIL_MANAGEMENT_TEMPLATES_PATH } from 'enums/paths'
import { formatValuesWithEditor } from 'helpers/formatter'
import { isSupervisor } from 'helpers/role'
import { forEach, get, isEmpty, set } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

function EmailTemplateEdit() {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const { id } = useParams()
  const [loaded, setLoaded] = useState(false)
  const emailEditorRef = useRef(null)
  const { data: mailTemplate } = useGetMailTemplateQuery({ id })
  const { data: mailFusionFields } = useGetMailFusionFieldsQuery()
  const updateMailTemplate = useUpdateMailTemplateMutation()
  const form = useCreateMailTemplateForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  useEffect(
    function loadMailFusionFields() {
      if (loaded && !isEmpty(mailFusionFields)) {
        const fusionsFields = {}
        forEach(mailFusionFields, ({ description, key }) =>
          set(fusionsFields, key, { name: key, value: `{{${key}}}` }),
        )
        emailEditorRef.current.editor.setMergeTags(fusionsFields)
      }
    },
    [loaded, mailFusionFields],
  )

  useEffect(
    function loadMailTemplateDesign() {
      if (loaded && !isEmpty(mailTemplate)) {
        try {
          form.reset(mailTemplate)
          const design = JSON.parse(get(mailTemplate, 'design', '{}'))
          emailEditorRef.current.editor.loadDesign(design)
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded, mailTemplate],
  )

  const onSubmit = (values) => {
    const editorExport = new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((html) => {
        resolve(html)
      })
    })
    editorExport.then(({ design, html }: any) => {
      const valuesWithEditor = formatValuesWithEditor(values, design, html)
      return updateMailTemplate
        .mutateAsync(
          { id, data: valuesWithEditor },
          {
            onSuccess: () => {
              popSnackbar(t('mail-template-update-success'), SUCCESS)
              setIsDirty(false)
              queryClient.invalidateQueries(MAIL_TEMPLATES)
            },
          },
        )
        .then(() => navigate(EMAIL_MANAGEMENT_TEMPLATES_PATH))
    })
  }

  return (
    <Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Helmet>
          <meta
            name="titles"
            content={JSON.stringify([
              { label: t('admin') },
              {
                label: t('mail-management'),
                to: EMAIL_MANAGEMENT_TEMPLATES_PATH,
                confirm: {
                  title: t('back-to-list-confirmation'),
                  message: t('changes-shall-disappear'),
                  enable: form.formState.isDirty,
                },
              },
              { label: t('update-title') },
            ])}
          />
        </Helmet>
        <Box
          sx={{
            p: 2,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          <Typography variant="h3" mb={2}>
            {t('edit-mail-template')}
          </Typography>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="key"
                  label={t('key')}
                  fullWidth
                  size="small"
                  disabled={user.role && !isSupervisor(user.role.key)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="name"
                  label={t('template-name')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  name="subject"
                  label={t('subject')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <EmailEditor
                  style={{ width: '100%' }}
                  ref={emailEditorRef}
                  onLoad={() => setLoaded(true)}
                />
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <LoadingButton
                  loading={updateMailTemplate.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<SaveAsRounded />}
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default EmailTemplateEdit
