import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

function UsersFormHeader() {
  const { t } = useTranslation()
  const commonSx = { ml: 0.5, fontWeight: 'bold' }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="caption" sx={commonSx}>
              {t('firstname')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption" sx={commonSx}>
              {t('lastname')}
            </Typography>
          </Grid>
          <Grid item xs={4.25}>
            <Typography variant="caption" sx={commonSx}>
              {t('email')}
            </Typography>
          </Grid>
          <Grid item xs={1.75}>
            <Typography variant="caption" sx={commonSx}>
              {t('phone')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: (theme) => theme.spacing(7) }}>
        <Typography variant="caption" sx={commonSx}>
          {t('mails')}
        </Typography>
      </Box>
    </Box>
  )
}

export default UsersFormHeader
