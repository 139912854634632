import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useLoginForm() {
  const { t } = useTranslation()
  const defaultValues = {
    usernameOrEmail: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    usernameOrEmail: Yup.string().required(t('required-field')),
    password: Yup.string().required(t('required-field')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useLoginForm
