export const DEBOUNCED_REFETCH_DELAY = {
  SHORT: 2500,
  MEDIUM: 5000,
  LONG: 10000,
}

export const REFETCH_INTERVAL = {
  SHORT: 5000,
  MEDIUM: 15000,
  LONG: 30000,
}

export const LOGS_TAKE = {
  SMALL: 50,
  MEDIUM: 100,
  LARGE: 150,
}

export const LOGLINE_PER_PAGE = [150, 300, 450]
