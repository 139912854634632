import { useQueryWrapper } from 'domains/helpers'
import { PUBLIC_TYPE, PUBLIC_TYPES } from './templates'
import { PublicType } from './types'

export function useGetPublicTypesQuery(params?, options?) {
  return useQueryWrapper<PublicType[]>('GET', PUBLIC_TYPES, params, options)
}

export function useGetPublicTypeQuery(params?, options?) {
  return useQueryWrapper<PublicType>('GET', PUBLIC_TYPE, params, options)
}
