import { Box, Button, Grid, Typography } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { SOURCE, STATUS } from 'domains/of/enums'
import { useSendFtpInfoMail } from 'domains/of/mutations'
import { isAdminPlus } from 'helpers/role'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { titleHeightWithMarginBottomSx } from '../../style'
import { useOf } from '../../context/of'

function ExcelSupplier({ supplier, variant }) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const isEdit = variant === 'edit'
  const params = useParams()
  const { id } = params

  const sendOfFtpInfoMail = useSendFtpInfoMail()
  const { popSnackbar } = useSnackbar()
  const { of } = useOf()
  const sendFtpInfoMail = async () => {
    try {
      await sendOfFtpInfoMail.mutateAsync({ id })
    } catch {
      popSnackbar(t('error'), ERROR)
    }
    popSnackbar(t('mail-sent-succesfully'), SUCCESS)
  }

  const canSendFTPInfoMail = of?.status === STATUS.ONLINE.value

  return (
    <Box
      sx={{
        mr: 1,
        display:
          (supplier === SOURCE.EXCEL.value ||
            supplier === SOURCE.EXCEL_DATA_DIRECTORY.value) &&
          variant === 'edit'
            ? 'block'
            : 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...titleHeightWithMarginBottomSx,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {supplier === SOURCE.EXCEL.value
            ? t('excel-info')
            : t('excel-data-directory-info')}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {user.role &&
          isAdminPlus(user.role.key) &&
          isEdit &&
          canSendFTPInfoMail ? (
            <Button onClick={sendFtpInfoMail}>
              {t('send-of-ftp-mail-info')}
            </Button>
          ) : (
            t('you-must-deploy-first')
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ExcelSupplier
