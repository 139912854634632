export const OFCardsLiningProps = {
  xs: { cardsPerLine: 1, height: 170 },
  sm: { cardsPerLine: 2, height: 180 },
  md: { cardsPerLine: 3, height: 190 },
  xl: { cardsPerLine: 4, height: 200 },
}

type OFCardsLiningItem = {
  cardsPerLine: number
  height: number
}

export function GetListLengthInPx(
  listLength: number,
  liningItem: OFCardsLiningItem,
) {
  return `${
    Math.ceil(listLength / liningItem.cardsPerLine) * (liningItem.height + 8) +
    2
  }px`
}
