import { useMutationWrapper } from 'domains/helpers'
import { AUTHORIZED_TOKEN, AUTHORIZED_TOKENS } from './templates'

export function useUpdateAuthorizedTokensMutation(options?) {
  return useMutationWrapper('PUT', AUTHORIZED_TOKEN, options)
}

export function usePatchAuthorizedTokensMutation(options?) {
  return useMutationWrapper('PATCH', AUTHORIZED_TOKEN, options)
}

export function useDeleteAuthorizedTokensMutation(options?) {
  return useMutationWrapper('DELETE', AUTHORIZED_TOKEN, options)
}

export function useCreateAuthorizedTokensMutation(options?) {
  return useMutationWrapper('POST', AUTHORIZED_TOKENS, options)
}
