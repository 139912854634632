import { Of } from 'domains/of/types'
import { RoleEnum } from 'domains/roles/enums'
import { Role } from 'domains/roles/types'
import { User } from 'domains/users/types'
import { find } from 'lodash'
import { DateTime } from 'luxon'

export function formatHour(hour: number, minute: number) {
  let h = hour.toString()
  let m = minute.toString()
  if (hour.toString().length === 1) {
    h = `0${hour}`
  }
  if (minute.toString().length === 1) {
    m = `0${minute}`
  }
  return `${h}h${m}`
}

export function getDeletionDate(params) {
  if ((params.row.deletionMonth || params.row.deletionDate) === undefined) {
    return ''
  }
  return `${DateTime.now().year}-${params.row.deletionMonth}-${
    params.row.deletionDay
  }`
}

export function getSyncHour(params) {
  return formatHour(params.row.syncHour, params.row.syncMinute)
}

export function findRole(roles: Role[], key: string) {
  return find(roles, { key })
}

function filterUsersByRole(users: User[], role: string) {
  return users.filter((user) => user.role.key === role)
}

function formatConnectionPublicTypes(connectionPublicTypes) {
  connectionPublicTypes.map((type) => {
    return {
      ...type,
      isEditableOnOfs: false,
    }
  })
}

export function formatEditForm(of: Of, form: any) {
  const defaultValues = { ...form, ...of }
  defaultValues.ofPublicTypes = defaultValues.ofPublicTypes.map((type) => {
    return {
      ...type,
      isActive: true,
      currentSelectedLDAPField: '',
      currentSelectedDBField: '',
    }
  })
  defaultValues.connections = defaultValues.connections.map((connection) => {
    return {
      ...connection,
      application: {
        ...connection.application,
        connectionPublicTypes: formatConnectionPublicTypes(
          connection.connectionPublicTypes,
        ),
      },
    }
  })
  if (of.users) {
    defaultValues.admins = filterUsersByRole(of.users, RoleEnum.ADMIN_OFA.key)
    defaultValues.readers = filterUsersByRole(of.users, RoleEnum.USER_OFA.key)
    defaultValues.connections = defaultValues.connections.map((connection) => {
      return {
        ...connection,
        application: {
          ...connection.application,
          label: connection.application.name,
          connectionPublicTypes: formatConnectionPublicTypes(
            connection.connectionPublicTypes,
          ),
        },
      }
    })
  }
  return defaultValues
}
