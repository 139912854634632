import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { MAX_NAME_LENGTH } from './enum'

function useCreatePublicTypeForm() {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    code: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('required-field'))
      .max(MAX_NAME_LENGTH, t('max-char', { value: MAX_NAME_LENGTH })),
    code: Yup.string().required(t('required-field')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreatePublicTypeForm
