import { useQueryWrapper } from 'domains/helpers'
import { LOG_GROUPED, LOG_UNION } from './templates'
import { LogDataModel, LogGroupedOfModel } from './types'

export function useGroupedLogsQuery(params?, options?) {
  return useQueryWrapper<LogGroupedOfModel[]>(
    'GET',
    LOG_GROUPED,
    params,
    options,
  )
}

export function useUnionLogsQuery(params?, options?) {
  return useQueryWrapper<LogDataModel>('GET', LOG_UNION, params, options)
}

export function useLogTabQuery(template, params?, options?) {
  return useQueryWrapper<LogDataModel>('GET', template, params, options)
}
