import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Grid, IconButton } from '@mui/material'
import FormTypoReadOnly from 'components/atoms/FormTypoReadOnly'
import ControlledCheckbox from 'components/molecules/ControlledCheckbox'
import React from 'react'

function UsersFormComponent({ user, indexUser, removeUser, userCategory }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Grid container>
          <Grid item xs={3}>
            <FormTypoReadOnly value={user?.firstname} />
          </Grid>
          <Grid item xs={3}>
            <FormTypoReadOnly value={user?.lastname} />
          </Grid>
          <Grid item xs={4.25}>
            <FormTypoReadOnly value={user?.email} />
          </Grid>
          <Grid item xs={1.75}>
            <FormTypoReadOnly value={user?.phone} />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: (theme) => theme.spacing(7),
          mr: 1,
        }}
      >
        <ControlledCheckbox
          name={`${userCategory}s[${indexUser}].receiveMail`}
          label=""
        />
        <IconButton
          size="small"
          sx={{
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
          }}
          onClick={() => removeUser(indexUser)}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default UsersFormComponent
