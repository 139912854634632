import Box from '@mui/material/Box'
import React from 'react'

function StandardLayout({ children }) {
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'common.black',
          flexGrow: 1,
          p: 3,
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default StandardLayout
