import { Add } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from '@mui/material'
import ControlledSwitch from 'components/molecules/ControlledSwitch'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OFSConnectionsComponent from '../OFSConnectionsComponent'
import { getApplicationIdFromOFConnections } from './helper'

function OFSConnectionsForm({
  connections,
  removeConnection,
  addConnection,
  variant,
  control,
}) {
  const { t } = useTranslation()
  const [isNewConnectionCreated, setIsNewConnectionCreated] = useState(false)

  const handleAddConnection = () => {
    addConnection({
      isActive: false,
      name: t('connection-number', {
        connectionNumber: connections.length + 1,
      }),
      application: null,
      token: '',
      connectionUrl: '',
      connectionPublicTypes: [],
    })
    setIsNewConnectionCreated(true)
  }

  return (
    <Box
      sx={{
        p: 1.5,
        mb: 2,
        bgcolor: 'primary.light',
        borderRadius: 1,
      }}
    >
      <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t('allowed-connections')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'common.black',
            borderRadius: 1,
            p: 0.25,
            width: (theme) => theme.spacing(3),
            height: (theme) => theme.spacing(3),
            cursor: 'pointer',
            '& svg': {
              width: '100%',
            },
            '& svg path': {
              fill: (theme) => theme.palette.common.white,
            },
          }}
          onClick={handleAddConnection}
        >
          <Add />
        </Box>
      </Box>
      {!isEmpty(connections) && (
        <Box sx={{ mt: 2 }}>
          {connections.map((connection, index) => (
            <Accordion
              sx={{
                mb: 2,
                borderRadius: 1,
                '&.Mui-expanded': { my: 0 },
                '& .MuiCollapse-root': { mb: 2 },
              }}
              defaultExpanded={
                connections.length - 1 === index && isNewConnectionCreated
              }
            >
              <AccordionSummary
                sx={{
                  minHeight: 0,
                  px: 1,
                  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
                    {
                      my: 1,
                    },
                  '&.Mui-expanded': {
                    minHeight: 0,
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography width={1}>{connection.name}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: 1,
                    mr: 2,
                  }}
                >
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    {t('active')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: (theme) => theme.spacing(7),
                      pointerEvents: 'auto',
                    }}
                  >
                    <ControlledSwitch
                      name={`connections[${index}].isActive`}
                      onClick={(event) => event.stopPropagation()}
                      control={control}
                      disabled={false}
                      sx={{
                        '&.MuiSwitch-root .MuiSwitch-switchBase': {
                          color: (theme) => theme.palette.error.main,
                        },
                        '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked': {
                          color: (theme) => theme.palette.success.main,
                        },
                      }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        width: (theme) => theme.spacing(3),
                        height: (theme) => theme.spacing(3),
                      }}
                      onClick={(event) => removeConnection(index, event)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 1 }}>
                <OFSConnectionsComponent
                  key={connection.id}
                  indexConnection={index}
                  removeConnection={removeConnection}
                  connectionApplicationsId={getApplicationIdFromOFConnections(
                    connections,
                  )}
                  variant={variant}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default OFSConnectionsForm
