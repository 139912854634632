import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ControlledRadioGroupProps } from './types'

function ControlledRadioGroup({
  name,
  control,
  label = '',
  options = [],
  direction = 'column',
  formLabelProps = {},
  radioGroupProps = {},
  formControlLabelProps = {},
  radioProps = {},
  ...props
}: ControlledRadioGroupProps) {
  const { t } = useTranslation()
  const controller = useController({ name, control })
  const {
    field,
    fieldState: { error },
  } = controller

  if (field.value === undefined) {
    console.error(
      `[FTEL-ERROR] : Le composant ControlledRadioGroup '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
    )
  }

  return (
    <FormControl error={!!error} {...props}>
      <FormLabel {...formLabelProps}>{label}</FormLabel>
      <RadioGroup
        {...field}
        value={`${field.value}`}
        {...radioGroupProps}
        sx={{ flexDirection: direction, ...radioGroupProps?.sx }}
      >
        {options.map(({ value, label: optionLabel }) => (
          <FormControlLabel
            key={value}
            value={`${value}`}
            control={<Radio {...radioProps} />}
            label={optionLabel}
            {...formControlLabelProps}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText>
          {get(error, 'message', t('invalid-field'))}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default ControlledRadioGroup
