/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip, Typography } from '@mui/material'
import React from 'react'

function TooltipCell({ value }) {
  if (value) {
    return (
      <Tooltip title={value}>
        <Typography noWrap variant="inherit">
          {value}
        </Typography>
      </Tooltip>
    )
  }
}

export default TooltipCell
