import { isEmpty } from 'lodash'

export function formatMethods(methods: any[]) {
  const methodsFormattedTemp = []
  if (!isEmpty(methods)) {
    methods.map(({ id, description }) =>
      methodsFormattedTemp.push({ label: description, id }),
    )
  }
  return methodsFormattedTemp
}

export function formatMethod(method) {
  return { label: method?.description, id: method?.id }
}
