import { yupResolver } from '@hookform/resolvers/yup'
import { toUpper } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateFileForm() {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    applications: [],
    oFs: [],
    url: '',
    key: '',
    file: undefined,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    key: Yup.string()
      .required(t('required-field'))
      .transform((prev) => toUpper(prev))
      .trim()
      .matches(/^[^\s]+$/, t('whitespace-disabled')),
    file: Yup.object().nullable().required(t('required-field')),
    applications: Yup.array().of(
      Yup.object().nullable().required(t('required-field')),
    ),
    oFs: Yup.array().of(Yup.object().nullable().required(t('required-field'))),
  })

  return useForm({ defaultValues, resolver: yupResolver(validationSchema) })
}

export default useCreateFileForm
