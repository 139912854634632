import { Box, Typography } from '@mui/material'
import React from 'react'
import { singleSyncTypographyCommonSx } from '../../styles'
import SyncStateIcon from '../SyncStateIcon'

function GlobalSingleSynchro({ syncMethodWithState }) {
  return (
    <Box>
      <Box sx={singleSyncTypographyCommonSx}>
        <Typography
          variant="body1"
          sx={
            syncMethodWithState.syncMethod.isMandatory
              ? { color: 'primary.light' }
              : { color: 'secondary.main' }
          }
        >
          {syncMethodWithState.syncMethod.description}
        </Typography>
        <SyncStateIcon
          syncState={syncMethodWithState.syncStateWithName?.state}
        />
      </Box>
    </Box>
  )
}

export default GlobalSingleSynchro
