import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { DEFAULT_ALERTS_TYPES_FILTER } from './filter'

function useAlertsFilterForm(dateStart, dateEnd, method) {
  const defaultValues = {
    dateStart,
    dateEnd,
    types: DEFAULT_ALERTS_TYPES_FILTER,
    method,
    description: '',
  }
  const validationSchema = Yup.object().shape({
    dateStart: Yup.string().nullable(),
    dateEnd: Yup.string().nullable(),
    types: Yup.array().nullable(),
    method: Yup.string().nullable(),
    description: Yup.string().nullable(),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const resetFormValues = (values) => {
    form.reset({
      ...defaultValues,
      ...values,
    })
  }

  const resetFormValuesOnly = (values) => {
    form.reset({
      ...form.getValues(),
      ...values,
    })
  }

  const fieldsAreEmpty =
    isEmpty(form.watch('dateStart')) &&
    isEmpty(form.watch('dateEnd')) &&
    isEmpty(form.watch('types')) &&
    isEmpty(form.watch('method')) &&
    isEmpty(form.watch('description'))

  return {
    form,
    resetFormValues,
    resetFormValuesOnly,
    fieldsAreEmpty,
  }
}

export default useAlertsFilterForm
