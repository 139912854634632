import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

function ApplicationFormTitle({ title }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold', pt: 0 }}>
        {title}
      </Typography>
      <Box
        sx={{
          flex: 1,
        }}
        mx={2}
      >
        <Divider
          sx={{
            bgcolor: (theme) => theme.palette.secondary.main,
            height: (theme) => theme.spacing(0.3),
          }}
        />
      </Box>
    </Box>
  )
}

export default ApplicationFormTitle
