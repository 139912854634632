import { useOfsQuery } from 'domains/of/queries'
import { isEmpty } from 'lodash'
import React, { createContext, useContext, useMemo, useState } from 'react'

const OrderContext = createContext(null)

function OfsProvider({ children }) {
  const [params, setParams] = useState({})
  const { data: ofs, refetch, isFetching } = useOfsQuery({ ...params })

  const values = useMemo(
    () => ({
      ofs,
      setParams,
      refetch,
      isFetching,
    }),
    [ofs, refetch, isFetching],
  )

  return (
    <OrderContext.Provider value={values}>{children}</OrderContext.Provider>
  )
}

const useOfs = () => {
  const context = useContext(OrderContext)
  if (isEmpty(context)) {
    throw new Error('useOf must be used within a OfProvider')
  }
  return context
}

export { OfsProvider, useOfs }
