import { Grid } from '@mui/material'
import { Of } from 'domains/of/types'
import React, { useEffect, useState } from 'react'
import OfCard from '../OfCard'
import { OfsListProps } from './type'

function OfsList({ ofs }: OfsListProps) {
  const [ofsIntern, setOfsIntern] = useState<Of[]>([])

  useEffect(() => {
    setOfsIntern(ofs)
  }, [ofs])

  return (
    <Grid container spacing={1}>
      {ofsIntern &&
        ofsIntern.map((of) => (
          <OfCard key={of.id} of={of} isLonely={ofsIntern.length <= 1} />
        ))}
    </Grid>
  )
}

export default OfsList
