import { useQueryWrapper } from 'domains/helpers'
import { APPLICATION, APPLICATIONS } from './templates'
import { Application } from './types'

export function useApplicationsQuery(params?, options?) {
  return useQueryWrapper<Application[]>('GET', APPLICATIONS, params, options)
}

export function useApplicationQuery(params?, options?) {
  return useQueryWrapper<Application>('GET', APPLICATION, params, options)
}
