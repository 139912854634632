import { useMutationWrapper } from 'domains/helpers'
import {
  OF,
  OFS,
  OF_DEPLOY,
  OF_EXCEL_EXPORT,
  OF_SEND_FTP_INFO_MAIL,
  OF_UPDATE_AVAILABILITY,
} from './templates'

export function useCreateOFSMutation(options?) {
  return useMutationWrapper('POST', OFS, options)
}

export function useUpdateOFSMutation(options?) {
  return useMutationWrapper('PUT', OF, options)
}

export function usePatchOFSMutation(options?) {
  return useMutationWrapper('PATCH', OF, options)
}

export function useDeleteOFSMutation(options?) {
  return useMutationWrapper('DELETE', OF, options)
}

export function useUpdateOFAvailability(options?) {
  return useMutationWrapper('POST', OF_UPDATE_AVAILABILITY, options)
}

export function useDeployOF(options?) {
  return useMutationWrapper('POST', OF_DEPLOY, options)
}

export function useSendFtpInfoMail(options?) {
  return useMutationWrapper('POST', OF_SEND_FTP_INFO_MAIL, options)
}

export function useExportExcel(options?) {
  return useMutationWrapper('POST', OF_EXCEL_EXPORT, options)
}
