import { Add } from '@mui/icons-material'
import { Box } from '@mui/material'
import LinkButton from 'components/atoms/LinkButton'
import { useAuth } from 'contexts/AuthContext'
import { EMAIL_MANAGEMENT_TEMPLATES_CREATE_PATH } from 'enums/paths'
import { isSupervisor } from 'helpers/role'
import React from 'react'

function Toolbar() {
  const { user } = useAuth()

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      {user.role && isSupervisor(user.role.key) && (
        <LinkButton
          to={EMAIL_MANAGEMENT_TEMPLATES_CREATE_PATH}
          variant="contained"
        >
          <Add />
        </LinkButton>
      )}
    </Box>
  )
}

export default Toolbar
