import { Box } from '@mui/material'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useUpdateCategoryMutation } from 'domains/categories/mutations'
import { useCategoryQuery } from 'domains/categories/queries'
import { CATEGORIES, CATEGORY } from 'domains/categories/templates'
import { CATEGORIES_PATH } from 'enums/paths'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import useCreateCategoryForm from '../../form'
import CategoriesForm from '../CategoryForm'

function EditCategoryForm({ id, handleClose }) {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const form = useCreateCategoryForm()
  const { data: category } = useCategoryQuery({ id })
  const updateCategory = useUpdateCategoryMutation()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(
    function loadFileData() {
      if (!isEmpty(category)) {
        form.reset(category)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category],
  )

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmit = async (data) => {
    await updateCategory.mutateAsync({
      id,
      data: {
        id,
        name: data.name,
        number: data.number,
      },
    })
    popSnackbar(t('category-edit-success'), SUCCESS)
    setIsDirty(false)
    queryClient.invalidateQueries({ queryKey: [CATEGORY] })
    queryClient.invalidateQueries({ queryKey: [CATEGORIES] })
    handleClose()
  }

  return (
    <Box>
      <Helmet>
        <meta
          name="titles"
          content={JSON.stringify([
            { label: t('admin') },
            {
              label: t('categories'),
              to: CATEGORIES_PATH,
              confirm: {
                title: t('back-to-list-confirmation'),
                message: t('changes-shall-disappear'),
                enable: form.formState.isDirty,
              },
            },
            { label: t('update-title') },
          ])}
        />
      </Helmet>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            bgcolor: 'common.white',
            borderRadius: 1,
            pt: 1,
          }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <CategoriesForm onSubmit={form.handleSubmit(onSubmit)} />
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default EditCategoryForm
