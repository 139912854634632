import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, InputAdornment } from '@mui/material'
import useOFSFilerModalForm from 'components/organisms/OFSManager/components/ToolbarFilter/filterForm'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { isEmpty } from 'lodash'
import React from 'react'
import { FormProvider, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function SearchBar({ setSearch }) {
  const { t } = useTranslation()
  const filterForm = useOFSFilerModalForm()
  const { control } = filterForm

  const labelControl = useWatch({
    control,
    name: 'search',
  })

  const onSubmit = (values) => {
    setSearch(values?.search)
  }

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <FormProvider {...filterForm}>
      <Box component="form" onSubmit={filterForm.handleSubmit(onSubmit)}>
        <ControlledTextField
          name="search"
          size="small"
          label={isEmpty(labelControl) ? t('search') : ''}
          sx={{
            bgcolor: 'primary.dark',
            borderRadius: 1,
            '& input': {
              color: 'primary.light',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  edge="end"
                  sx={{
                    '& button': {
                      color: 'primary.light',
                    },
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </FormProvider>
  )
}

export default SearchBar
