import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { AVAILABILITES } from 'domains/of/enums'
import { useCreateOFSMutation } from 'domains/of/mutations'
import { OF, OFS } from 'domains/of/templates'
import { OFS_PATH } from 'enums/paths'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import OFSMainForm from '../OFSForm'
import useCreateOFSForm from '../OFSForm/form'

function CreateOFSForm() {
  const isFormCreate = true
  const { form, setFieldsInError } = useCreateOFSForm(isFormCreate)
  const createOFS = useCreateOFSMutation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  const onSubmitSave = async (values) => {
    try {
      await createOFS.mutateAsync({
        data: {
          ...values,
          users: [...values.admins, ...values.readers],
          availability: AVAILABILITES.READY.value,
        },
      })
      popSnackbar(t('of-add-success'), SUCCESS)
      setIsDirty(false)
      queryClient.invalidateQueries({ queryKey: [OFS] })
      queryClient.invalidateQueries({ queryKey: [OF] })
      navigate(OFS_PATH)
    } catch (error) {
      setFieldsInError(Object.keys(error?.response?.data ?? []))
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Helmet>
        <meta
          name="titles"
          content={JSON.stringify([
            { label: t('admin') },
            {
              label: t('ofs-management'),
              to: OFS_PATH,
              confirm: {
                title: t('back-to-list-confirmation'),
                message: t('changes-shall-disappear'),
                enable: form.formState.isDirty,
              },
            },
            { label: t('create-title') },
          ])}
        />
      </Helmet>
      <OFSMainForm
        control={form.control}
        onSubmitSave={form.handleSubmit(onSubmitSave)}
        onSubmitDeploy={form.handleSubmit(onSubmitSave)}
        variant="create"
      />
    </FormProvider>
  )
}

export default CreateOFSForm
