import { SaveAsRounded } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useGlobalFormState } from 'contexts/GlobalFormStateContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useGetMailFusionFieldsQuery } from 'domains/mailFusionFields/queries'
import { useCreateMailTemplateMutation } from 'domains/mailTemplates/mutations'
import { MAIL_TEMPLATES } from 'domains/mailTemplates/templates'
import { EMAIL_MANAGEMENT_TEMPLATES_PATH } from 'enums/paths'
import { formatValuesWithEditor } from 'helpers/formatter'
import { forEach, isEmpty, set } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { Helmet } from 'react-helmet'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useCreateMailTemplateForm from './form'

export const CREATE = 'create'

function MailTemplateForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const form = useCreateMailTemplateForm()
  const createMailTemplateField = useCreateMailTemplateMutation()
  const { data: mailFusionFields } = useGetMailFusionFieldsQuery()
  const emailEditorRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { setIsDirty } = useGlobalFormState()

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  useEffect(
    function loadMailFusionFields() {
      if (loaded && !isEmpty(mailFusionFields)) {
        const fusionsFields = {}
        forEach(mailFusionFields, ({ description, key }) =>
          set(fusionsFields, key, { name: key, value: `{{${key}}}` }),
        )
        emailEditorRef.current.editor.setMergeTags(fusionsFields)
      }
    },
    [loaded, mailFusionFields],
  )

  const onSubmit = async (values) => {
    const editorExport = new Promise((resolve) => {
      emailEditorRef.current.editor.exportHtml((html) => {
        resolve(html)
      })
    })
    editorExport.then(({ design, html }: any) => {
      const valuesWithEditor = formatValuesWithEditor(values, design, html)
      return createMailTemplateField
        .mutateAsync(
          { data: valuesWithEditor },
          {
            onSuccess: () => {
              popSnackbar(t('mail-template-create-success'), SUCCESS)
              setIsDirty(false)
              queryClient.invalidateQueries(MAIL_TEMPLATES)
            },
          },
        )
        .then(() => navigate(EMAIL_MANAGEMENT_TEMPLATES_PATH))
    })
  }

  return (
    <Box>
      <Helmet>
        <meta
          name="titles"
          content={JSON.stringify([
            { label: t('admin') },
            {
              label: t('mail-management'),
              to: EMAIL_MANAGEMENT_TEMPLATES_PATH,
              confirm: {
                title: t('back-to-list-confirmation'),
                message: t('changes-shall-disappear'),
                enable: form.formState.isDirty,
              },
            },
            { label: t('create-title') },
          ])}
        />
      </Helmet>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Box
          sx={{
            p: 2,
            bgcolor: 'common.white',
            borderRadius: 1,
          }}
        >
          <Typography variant="h3" mb={2}>
            {t('add-mail-template')}
          </Typography>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <Grid
              container
              spacing={2}
              component="form"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="key"
                  label={t('key')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  name="name"
                  label={t('template-name')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  name="subject"
                  label={t('subject')}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <EmailEditor
                  style={{ width: '100%' }}
                  ref={emailEditorRef}
                  onLoad={() => setLoaded(true)}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveAsRounded />}
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default MailTemplateForm
