import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as SynchroBusy } from 'assets/images/synchro-busy.svg'
import { ReactComponent as SynchroError } from 'assets/images/synchro-error.svg'
import { ReactComponent as SynchroSuccess } from 'assets/images/synchro-success.svg'
import { ReactComponent as SynchroWarning } from 'assets/images/synchro-warning.svg'
import { getLabelFromEnum } from 'components/organisms/OFSManager/formatters'
import { SOURCE } from 'domains/of/enums'
import { OFCardsLiningProps } from 'enums/oFCardsLiningProps'
import { TRAINING_ORGANIZATION_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import urlcat from 'urlcat'
import {
  isAvailable,
  isBusy,
  isInError,
  isInWarning,
  isRefAvailable,
  isRefInError,
  isRefInWarning,
} from '../../helpers'
import ReferentielTag from '../ReferentielTag'
import SynchroTag from '../SynchroTag'
import { OfCardProps } from './type'

function OfCard({ of, isLonely = false }: OfCardProps) {
  const { t } = useTranslation()
  const showConnections =
    of?.connections?.filter((c) => c.application.isWSRest) ?? []

  return (
    <Grid
      item
      xs={isLonely ? 5 : 12 / OFCardsLiningProps.xs.cardsPerLine}
      sm={isLonely ? 5 : 12 / OFCardsLiningProps.sm.cardsPerLine}
      md={isLonely ? 5 : 12 / OFCardsLiningProps.md.cardsPerLine}
      xl={isLonely ? 5 : 12 / OFCardsLiningProps.xl.cardsPerLine}
    >
      <Link
        to={urlcat(TRAINING_ORGANIZATION_PATH, { id: of.id })}
        style={{ textDecoration: 'none', color: '#000' }}
      >
        <Box
          sx={{
            px: 1,
            pb: 0.5,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'primary.light',
            borderRadius: 1,
            cursor: 'pointer',
            alignItems: 'center',
            height: {
              xs: OFCardsLiningProps.xs.height,
              md: OFCardsLiningProps.md.height,
              xl: OFCardsLiningProps.xl.height,
            },
          }}
        >
          <Box display="flex" justifyContent="end" sx={{ width: 1, mt: 1 }}>
            <ReferentielTag
              name={t('referentiel')}
              isInError={isRefInError(of)}
              isAvailable={isRefAvailable(of)}
              isInWarning={isRefInWarning(of)}
            />
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              width: 1,
              maxHeight: (theme) => theme.spacing(8),
            }}
          >
            <Box sx={{ float: 'left', my: 1 }}>
              {isAvailable(of) && (
                <SynchroSuccess style={isLonely ? {} : { width: 50 }} />
              )}
              {isInWarning(of) && (
                <SynchroWarning style={isLonely ? {} : { width: 50 }} />
              )}
              {isInError(of) && (
                <SynchroError style={isLonely ? {} : { width: 50 }} />
              )}
              {isBusy(of) && (
                <SynchroBusy style={isLonely ? {} : { width: 50 }} />
              )}
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                textTransform: 'uppercase',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {of?.shortName || ''}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                textAlign: 'center',
                float: 'right',
                mb: 'auto',
              }}
            >
              <SynchroTag
                name={t(getLabelFromEnum(SOURCE, of?.source))}
                successSynchro={!of?.isSourceInError}
                hasAnyLogs={of?.hasAnySourceLogs}
                isBusy={isBusy(of)}
                isInWarning={isInWarning(of)}
                variant="vendor"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                textAlign: 'center',
                float: 'right',
                gap: 0.5,
              }}
            >
              {showConnections.map((connection) => (
                <SynchroTag
                  key={`${of.id}-${connection.application.id}`}
                  name={connection.application.name}
                  successSynchro={!connection.isInError}
                  hasAnyLogs={connection.hasAnyLogs}
                  isInWarning={connection.isInWarning}
                  variant="app"
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Link>
    </Grid>
  )
}

export default OfCard
